import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import { Button, Form, Input, message } from "antd";
import hu from "../../static/hu.png";
import de from "../../static/de.png";
import en from "../../static/en.png";
import i18n from "../../i18n";
import check from "../../static/check.png";
import lock from "../../static/lock_.png";
import Axios from "axios";

const mapDispatchToProps = (dispatch) => {
  return {};
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

class ConnectedGuestDetails extends Component {
  formRef = React.createRef();

  state = {
    code: "",
    token: "",
    guest: null,
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    hostfirstname: "",
    hostlastname: "",
    emailLogin: false
  };

  componentDidMount = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
    const queryParams = new URLSearchParams(this.props.location.search);
    this.setState({
      code: queryParams.get("code"),
      token: queryParams.get("token"),
      emailLogin: queryParams.get("email") === "true",
    });
    await axios
      .get("/1.0.0/reception/guest-by-code", {
        params: { code: queryParams.get("code") },
      })
      .then((response) => {
        this.setState({
          guest: response.data.guest,
          firstName:
            response.data.guest.firstName === " "
              ? ""
              : response.data.guest.firstName,
          lastName:
            response.data.guest.lastName === " "
              ? ""
              : response.data.guest.lastName,
          company:
            response.data.guest.representedCompany === " "
              ? ""
              : response.data.guest.representedCompany,
          email: response.data.guest.email,
          hostfirstname: response.data.guest.invite.host.firstName,
          hostlastname: response.data.guest.invite.host.lastName,
        });
        this.formRef.current.setFieldsValue({
          firstName:
            response.data.guest.firstName === " "
              ? ""
              : response.data.guest.firstName,
          lastName:
            response.data.guest.lastName === " "
              ? ""
              : response.data.guest.lastName,
          company:
            response.data.guest.representedCompany === " "
              ? ""
              : response.data.guest.representedCompany,
          email: response.data.guest.email,
          hostfirstname: response.data.guest.invite.host.firstName,
          hostlastname: response.data.guest.invite.host.lastName,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  submitForm = async () => {
    const toSave = {
      id: this.state.guest.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      company: this.state.company,
      email: this.state.email,
      hostfirstname: this.state.hostfirstname,
      hostlastname: this.state.hostlastname,
      code: this.state.guest.code,
      microsite: this.state.emailLogin
    };

    if ( !this.state.emailLogin ) {
      await Axios.get("/1.0.0/reception/checkin-guest", {
        params: {
          token:this.state.token,
          code: this.state.code,
        },
      })
          .then( async (response) => {
            await axios
                .post("/1.0.0/reception/modify-guest-code", toSave)
                .then(async (response) =>  {
                  this.setState({ success: true });
                })
                .catch((err) => {
                  message.error(this.props.t("save-fail"), 5);
                });
          })
          .catch((err) => {
            message.error(this.props.t("guest-checkin-code-fail"), 5);
          });
    } else {
      await axios
          .post("/1.0.0/reception/modify-guest-code", toSave)
          .then(async (response) =>  {
            this.setState({ success: true });
          })
          .catch((err) => {
            message.error(this.props.t("save-fail"), 5);
          });
    }

  };

  render() {
    return (
      <div>
        <div
          style={{
            padding: "20px",
            backgroundColor: "#00aea9",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={de}
              alt="de_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("de")}
              style={{
                opacity: i18n.language === "de" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "de" ? "3px solid white" : "",
              }}
            />
          </div>
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={hu}
              alt="hu_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("hu")}
              style={{
                opacity: i18n.language === "hu" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "hu" ? "3px solid white" : "",
              }}
            />
          </div>
          <div style={{ width: "20%", maxWidth: "75px" }}>
            <img
              src={en}
              alt="en_flag"
              width={"100%"}
              onClick={() => this.changeLanguage("en")}
              style={{
                opacity: i18n.language === "en" ? "1" : "0.7",
                paddingBottom: "10px",
                borderBottom: i18n.language === "en" ? "3px solid white" : "",
              }}
            />
          </div>
        </div>
        {!this.state.success && (
          <div>
            <div style={{ backgroundColor: "#00aea9" }}>
              <h1
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "2.2rem",
                }}
              >
                {this.props.t("guest-register-title")}
              </h1>
            </div>
            <div>
              <Form
                ref={this.formRef}
                {...formItemLayout}
                name="guest"
                onFinish={this.submitForm}
                scrollToFirstError
              >
                <Form.Item
                  style={{
                    padding: "1em",
                  }}
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-register-lastname-text"),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={this.props.t("guest-register-lastname-label")}
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    padding: "1em",
                  }}
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-register-firstname-text"),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={this.props.t("guest-register-firstname-label")}
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    padding: "1em",
                  }}
                  name="company"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-register-company-text"),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={this.props.t("guest-register-company-label")}
                    name="company"
                    value={this.state.company}
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  style={{
                    padding: "1em",
                  }}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("email-empty"),
                      whitespace: false,
                    },
                    {
                      type: "email",
                      message: this.props.t("email-wrong-format"),
                    },
                  ]}
                >
                  <Input
                    placeholder={this.props.t("email-label")}
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    padding: "1em",
                  }}
                  name="hostlastname"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-register-host-text"),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={`${this.props.t(
                      "guest-register-host-label"
                    )} ${this.props
                      .t("guest-register-lastname-label")
                      .toLowerCase()}`}
                    name="hostlastname"
                    value={this.state.hostlastname}
                    onChange={this.handleChange}
                    disabled={this.state.hostlastname.length > 0}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    padding: "1em",
                  }}
                  name="hostfirstname"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("guest-register-host-text"),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={`${this.props.t(
                      "guest-register-host-label"
                    )} ${this.props
                      .t("guest-register-firstname-label")
                      .toLowerCase()}`}
                    name="hostfirstname"
                    value={this.state.hostfirstname}
                    onChange={this.handleChange}
                    disabled={this.state.hostfirstname.length > 0}
                    style={{ borderRadius: "25px", fontSize: "1.5rem" }}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      fontSize: "2.2rem",
                      height: "5%",
                      borderRadius: "50px",
                      width: "60%",
                    }}
                  >
                    {this.props.t("save-button-label")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        {this.state.success && this.state.emailLogin && (
          <div style={{ textAlign: "center" }}>
            <div style={{ backgroundColor: "#00aea9" }}>
              <h1
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "2.2rem",
                }}
              >
                {this.props.t("guest-details-register-success")}
              </h1>
            </div>
            <div
              style={{
                textAlign: "center",
                margin: "40px 0 40px 0",
                fontSize: "1.5rem",
              }}
            >
              <img src={check} width={200} alt="check" />
            </div>
            <div
              style={{
                textAlign: "center",
                padding: "15px",
                fontSize: "1.5rem",
              }}
            >
              <p>
                {this.props.t("guest-details-register-dear")}{" "}
                {this.state.firstName}!
              </p>
              <p>{this.props.t("guest-details-register-text")}</p>
              <p>
                {this.props.t("guest-details-register-yours")}{" "}
                {this.state.hostfirstname && (
                  <>
                    {this.state.hostlastname} {this.state.hostfirstname}
                  </>
                )}
                {!this.state.hostfirstname && (
                  <>{this.state.guest.invite.company.name}</>
                )}
              </p>
            </div>
          </div>
        )}
        {this.state.success && !this.state.emailLogin && (
          <div style={{ textAlign: "center" }}>
            <div style={{ backgroundColor: "#00aea9" }}>
              <h1
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "2.2rem",
                }}
              >
                {this.props.t("guest-details-checkin-success")}
              </h1>
            </div>
            <div
              style={{
                textAlign: "center",
                margin: "40px 0 40px 0",
                fontSize: "1.5rem",
              }}
            >
              <img src={lock} width={200} alt="check" />
            </div>
            <div
              style={{
                textAlign: "center",
                padding: "15px",
                fontSize: "1.5rem",
              }}
            >
              <p>
                {this.props.t("guest-details-register-dear")}{" "}
                {this.state.firstName}!
              </p>
              <p>{this.props.t("guest-details-checkin-text")}</p>
              <p>
                {this.props.t("guest-details-register-yours")}{" "}
                {this.state.hostfirstname && (
                  <>
                    {this.state.hostlastname} {this.state.hostfirstname}
                  </>
                )}
                {!this.state.hostfirstname && (
                  <>{this.state.guest.invite.company.name}</>
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const GuestDetails = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedGuestDetails)
);
export default GuestDetails;
