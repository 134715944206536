import React, { Component } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Space,
  Table,
  Popconfirm,
  message,
  Select,
  Row,
  Col,
} from "antd";
import { connect } from "react-redux";
import {
  SaveFilled,
  DeleteOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  createEntrypoints,
  deleteEntrypoints,
  getAllEntrypoints,
  modifyEntrypoints,
  getAllEntrypointsByAddressId,
  getEntrypointById,
} from "../../actions/entrypoints";
import {
  getAllAddresses,
  getAddressById,
  getOneCompanyById,
  addAddressToRegapp,
} from "../../actions/company";
import Axios from "axios";
import "./entrypoints.scss";
import { Redirect } from "react-router";
import axios from "axios";
import { getUsersForRegapps } from "../../actions/users";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllEntrypoints: () => dispatch(getAllEntrypoints()),
    getEntrypointById: (id) => dispatch(getEntrypointById(id)),
    createEntrypoints: (params) => dispatch(createEntrypoints(params)),
    deleteEntrypoints: (id) => dispatch(deleteEntrypoints(id)),
    modifyEntrypoints: (params) => dispatch(modifyEntrypoints(params)),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    getAddressById: (addressId) => dispatch(getAddressById(addressId)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    getAllEntrypointsByAddressId: (addressId) =>
      dispatch(getAllEntrypointsByAddressId(addressId)),
    addAddressToRegapp: (regAppId, addressId) =>
      dispatch(addAddressToRegapp(regAppId, addressId)),
    getUsersForRegapps: (name, token, controllerAddress) =>
      dispatch(getUsersForRegapps(name, token, controllerAddress)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EntryPoint extends Component {
  formRef = React.createRef();

  state = {
    data: [],
    entryPoints: [],
    popupvisible: false,
    loading: false,
    visible: false,
    opened: false,
    regAppTokens: [],
    subscription: {},
    name: "",
    deviceId: "",
    controllerAddress: "",
    rfidString: "",
    addressId: null,
    title: "",
    buttontitle: "",
    addressName: "",
    modify: false,
    openSearchModal: false,
    tokenName: "",
    openTime: null,
    actuatorsState: null,
    desiredState: null,
  };

  componentDidMount = async () => {
    if (this.props.user.officeId) {
      await this.fetch();
      await this.props.getAllAddresses(null, this.props.user.officeId);
    } else if (this.props.user.company.smartLock === true) {
      await this.fetch();
      await this.props.getAllAddresses(this.props.user.companyId);
      this.props.socket.on("doorOpened", (data) => {
        setTimeout(() => {
          const newentrypoints = this.state.entryPoints.map((point) => {
            if (point.id === data.entryPointId) {
              point.opened = false;
            }
            return point;
          });
          this.setState({ entryPoints: newentrypoints });
        }, data.time * 1000);
        const newentrypoints = this.state.entryPoints.map((point) => {
          if (point.id === data.entryPointId) {
            point.opened = true;
          }
          return point;
        });
        this.setState({ entryPoints: newentrypoints });
      });
    }
  };

  successMessage = () => {
    message.success(this.props.t("entrypoints-modal-success-message"), 5);
  };

  popupConfirm = (record) => {
    this.setState({ popupvisible: false });
    this.handleDelete(record.id);
    message.success(this.props.t("entrypoints-popupconfirm-message"));
  };

  popupCancel = () => {
    this.setState({ popupvisible: false });
    this.handleCancel();
  };

  handlePopupVisibleChange = (popupvisible) => {
    if (!popupvisible) {
      this.setState({ popupvisible });
      return;
    } else {
      this.setState({ popupvisible });
    }
  };

  setUpdater = () => {
    this.setState({
      title: this.props.t("entrypoints-update-title"),
      buttontitle: this.props.t("entrypoints-update-title"),
    });
  };

  setCreator = () => {
    this.setState({
      title: this.props.t("entrypoints-create-title"),
      buttontitle: this.props.t("entrypoints-create-title"),
    });
  };

  showModal = (record) => {
    this.setState({
      selectedId: record ? record.id : null,
      visible: true,
      name: record ? record.name : "",
      addressId: record ? record.addressId : "",
      deviceId: record ? record.deviceId : "",
      controllerAddress: record ? record.controllerAddress : "",
      rfidString: record ? record.rfidString : "",
      openTime: record ? record.openTime : null,
      actuatorsState: record ? record.actuatorsState : null,
      desiredState: record ? record.desiredState : null,
    });
    if (record) {
      const address = this.props.addresses?.find(
        (a) => a.id === record.addressId
      );
      this.formRef.current.setFieldsValue({
        name: record.name,
        address: address.name,
        deviceId: record.deviceId,
        controllerAddress: record.controllerAddress,
        rfidString: record.rfidString,
        openTime: record.openTime,
        actuatorsState: record.actuatorsState,
        desiredState: record.desiredState,
      });
    }
  };

  createButtonHandler = () => {
    this.showModal();
    this.setCreator();
    this.formRef.current.resetFields();
  };

  handleCreateSubmit = async () => {
    const data = {
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      name: this.state.name,
      addressId: this.state.addressId,
      deviceId: this.state.deviceId,
      controllerAddress: this.state.controllerAddress,
      rfidString: this.state.rfidString,
      openTime: parseInt(this.state.openTime),
      actuatorsState: parseInt(this.state.actuatorsState),
      desiredState: parseInt(this.state.desiredState),
    };
    await this.props.createEntrypoints(data);
    if (this.props.saveStatus) {
      this.setState({
        name: "",
        addressId: "",
        deviceId: "",
        controllerAddress: "",
        rfidString: "",
        openTime: null,
        actuatorsState: null,
        desiredState: null,
      });
      this.formRef.current.resetFields();
      this.successMessage();
      this.fetch();
    } else {
      if (this.props.message && this.props.message.code === 5404) {
        message.error(this.props.t("entrypoints-save-duplicate-error"));
      } else {
        message.error(this.props.t("entrypoints-save-error"));
      }
    }
  };

  updaterButtonHandler = (record) => {
    this.setState({ modify: true });
    this.showModal(record);
    this.setUpdater();
  };

  handleUpdateSubmit = async (record) => {
    const data = {
      id: this.state.selectedId,
      name: this.state.name,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      addressId: this.state.addressId,
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      deviceId: this.state.deviceId,
      controllerAddress: this.state.controllerAddress,
      rfidString: this.state.rfidString,
      openTime: parseInt(this.state.openTime),
      actuatorsState: parseInt(this.state.actuatorsState),
      desiredState: parseInt(this.state.desiredState),
    };
    await this.props.modifyEntrypoints(data);
    if (this.props.saveStatus) {
      this.setState({
        name: "",
        addressId: "",
        deviceId: "",
        controllerAddress: "",
        rfidString: "",
        openTime: null,
        actuatorsState: null,
        desiredState: null,
      });
      this.formRef.current.resetFields();
      this.successMessage();
      this.fetch();
    } else {
      if (this.props.message && this.props.message.code === 5404) {
        message.error(this.props.t("entrypoints-save-duplicate-error"));
      } else {
        message.error(this.props.t("entrypoints-save-error"));
      }
    }
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false, modify: false, selectedId: null });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTableChange = () => {
    this.fetch();
  };

  fetch = async () => {
    this.setState({ loading: true });
    await this.props.getAllEntrypoints();
    if (this.props.status) {
      const entrypoints = this.props.entryPoints.map((point) => {
        return { ...point, opened: false };
      });
      this.setState({ entryPoints: entrypoints });
    }
    this.setState({
      loading: false,
    });
  };

  onClickEvent = async (record) => {
    if (this.state.modify === true) {
      await this.handleUpdateSubmit(record);
    } else {
      await this.handleCreateSubmit();
    }
    this.handleCancel();
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleDelete = async (id) => {
    await this.props.deleteEntrypoints(id);
    this.formRef.current.resetFields();
    this.fetch();
    this.handleCancel();
  };

  handleTestOpen = async (id) => {
    try {
      await Axios.get("/2.0.0/entrypoint/open/" + id);
    } catch (err) {
      message.error(this.props.t("entrypoints-door-open-error"));
    }
  };

  updateCompany = async (companyId) => {
    await this.props.getOneCompanyById(
      this.props.user.companyId ? this.props.user.companyId : companyId
    );
  };

  handleChangeAddress = async (event, regAppId) => {
    await this.props.addAddressToRegapp(regAppId, event);
    const decider = this.props.addresses.find((a) => a.id === event);
    this.setState({ addressId: event });
    if (this.props.status && decider.companyId !== null) {
      await this.updateCompany(decider.companyId);
    }
  };

  handleChangeTokenName = (event) => {
    this.setState({ tokenName: event.target.value });
  };
  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };
  handleChangeControllerAddress = (event) => {
    this.setState({ controllerAddress: event.target.value });
  };

  handleOpenModal = async () => {
    this.setState({
      openSearchModal: true,
    });
  };

  handleCloseModal = async () => {
    this.formRef.current.resetFields();
    this.setState({
      openSearchModal: false,
      users: [],
      tokenName: "",
    });
  };

  handleSubmit = async () => {
    // await this.props.getUsersForRegapps({name: "Ajtó", token: this.state.tokenName, controllerAddress: 1});
    await axios
      .get("/2.0.0/users/users-for-regapp", {
        params: {
          name: this.state.name,
          token: this.state.tokenName,
          controllerAddress: this.state.controllerAddress,
        },
      })
      .then((response) => {
        this.setState({
          users: response.data,
        });
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
        message.error(this.props.t("no-proxy-cardholder-for-today"));
        this.setState({ users: [] });
      });
  };
  render() {
    const { visible, title } = this.state;

    const colums = [
      {
        title: this.props.t("entrypoints-controller-name"),
        dataIndex: "name",
      },
      {
        title: this.props.t("entrypoints-isopened-status-title"),
        dataIndex: "opened",
        key: "opened",
        render: (text, record) => (
          <Space
            size="middle"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: "1.1vw",
                height: "1.1vw",
                borderRadius: "50%",
                backgroundColor: record.opened ? "green" : "gray",
              }}
            />
          </Space>
        ),
      },
    ];
    this.hasRight([userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN, userRoles.OFFICE_ADMIN]) &&
      colums.push({
        title: this.props.t("entrypoints-dooropener-button-label"),
        dataIndex: "open",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => this.handleTestOpen(record.id)}
              >
                {this.props.t("entrypoints-dooropener-button-label")}
              </Button>
            </Space>
          );
        },
      });
    this.hasRight([userRoles.OFFICE_ADMIN]) &&
      colums.push({
        title: this.props.t("entrypoints-address-title"),
        dataIndex: "name",
        key: "address",
        render: (text, record) => (
          <Space size="middle">{record?.address?.name}</Space>
        ),
      });
    const adminColums = [
      {
        title: this.props.t("entrypoints-update-title"),
        dataIndex: "update",
        render: (value, record) => (
          <>
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => this.updaterButtonHandler(record)}
              >
                {this.props.t("modify-button-label")}
              </Button>
            </Space>
          </>
        ),
        width: 50,
      },
      {
        title: this.props.t("delete-button-label"),
        dataIndex: "delete",
        render: (value, record) => (
          <>
            <Space size="middle">
              <Popconfirm
                title={this.props.t("entrypoints-popup-delete-question")}
                onVisibleChange={this.handlePopupVisibleChange}
                onConfirm={() => this.popupConfirm(record)}
                onCancel={this.popupCancel}
                okText={this.props.t("entrypoints-popup-button-ok-label")}
                cancelText={this.props.t(
                  "entrypoints-popup-button-cancle-label"
                )}
                placement="topLeft"
              >
                <Button
                  className="button"
                  type="primary"
                  htmlType="submit"
                  danger
                >
                  <DeleteOutlined />
                  {this.props.t("delete-button-label")}
                </Button>
              </Popconfirm>
            </Space>
          </>
        ),
        width: 50,
      },
    ];

    const userColumns = [
      {
        title: this.props.t("guest-register-lastname-label"),
        key: "lastName",
        dataIndex: "lastName",
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        key: "firstName",
        dataIndex: "firstName",
      },
    ];

    return (
      <div className="entrypoints">
        {!this.props.user.officeId &&
          this.props.user.company.smartLock === false && (
            <Redirect from="**" to="/tl/missing-right" />
          )}
        {this.hasRight([
          userRoles.COMPANY_ADMIN,
          userRoles.RECEPTION_ADMIN,
          userRoles.OFFICE_ADMIN,
        ]) && (
          <Row style={{ padding: "0.5em", position: "relative", right: "0em" }}>
            <Col span={24}>
              <Space size={"middle"}>
                <Button type="primary" onClick={this.handleOpenModal}>
                  <UnorderedListOutlined />
                  {this.props.t("proxy-cards")}
                </Button>
                {!this.props.user.company?.officeId || this.hasRight([6]) ? (
                  <Button
                    className="button"
                    type="primary"
                    htmlType="submit"
                    onClick={() => this.createButtonHandler()}
                  >
                    <PlusOutlined />
                    {this.props.t("entrypoints-create-title")}
                  </Button>
                ) : (
                  []
                )}
              </Space>
            </Col>
          </Row>
        )}

        <Row style={{ padding: "0.5em" }}>
          <Col span={24}>
            <Table
              bordered={false}
              columns={
                this.hasRight([userRoles.OFFICE_ADMIN]) || !this.props.user.company?.officeId
                  ? [...colums, ...adminColums]
                  : colums
              }
              dataSource={this.state.entryPoints}
              pagination={
                this.state.entryPoints.length > 10
                  ? {
                      position: ["bottomCenter"],
                      pageSize: 5,
                      showSizeChanger: false,
                    }
                  : false
              }
              rowKey="id"
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        {(this.hasRight([userRoles.OFFICE_ADMIN]) || !this.props.user.company?.officeId) && (
          <Modal
            visible={visible}
            title={title}
            onCancel={this.handleCancel}
            footer={null}
            forceRender
          >
            <Form
              className="modal-body"
              name="modifier"
              onFinish={this.onClickEvent}
              ref={this.formRef}
              {...formItemLayout}
              scrollToFirstError
            >
              <Form.Item
                label={this.props.t("entrypoints-modal-name-label")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                ]}
                hasFeedback
              >
                <Input
                  value={this.state.name}
                  name="name"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("reception-address-label")}
                name="address"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                ]}
              >
                <Select
                  name="address"
                  onChange={(event, record) =>
                    this.handleChangeAddress(event, record.id)
                  }
                  value={this.state.addressId}
                >
                  {this.props.addresses.map((address) => {
                    return (
                      <Select.Option key={address.id} value={address.id}>
                        {address.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={this.props.t("device-label")}
                name="deviceId"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                ]}
              >
                <Input
                  value={this.state.deviceId}
                  name="deviceId"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("controller-address-label")}
                name="controllerAddress"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                  {
                    pattern:/^\d+/g,
                    message: this.props.t("must-be-number-label"),
                  }
                ]}
              >
                <Input
                  value={this.state.controllerAddress}
                  name="controllerAddress"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("open-time-label")}
                name="openTime"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                  {
                    pattern:/^\d+/g,
                    message: this.props.t("must-be-number-label"),
                  }
                ]}
              >
                <Input
                  value={this.state.openTime}
                  name="openTime"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("actuator-state-label")}
                name="actuatorsState"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                  {
                    pattern:/^[0-4]*$/,
                    message: this.props.t("actuators-state-modal-wrong-format"),
                  }
                ]}
              >
                <Input
                  value={this.state.actuatorsState}
                  name="actuatorsState"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("desired-state-label")}
                name="desiredState"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                  {
                    pattern:/^\d+/g,
                    message: this.props.t("must-be-number-label"),
                  }
                ]}
              >
                <Input
                  value={this.state.desiredState}
                  name="desiredState"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label={this.props.t("rfid-string-label")}
                name="rfidString"
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                ]}
              >
                <Input
                  value={this.state.rfidString}
                  name="rfidString"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                {...tailFormItemLayout}
                style={{
                  textAlign: "right",
                  width: "100%",
                }}
              >
                <Button
                  key="cancel"
                  onClick={this.handleCancel}
                  style={{ marginRight: "1em" }}
                >
                  {this.props.t("cancel-button-label")}
                </Button>
                <Button type="primary" htmlType="submit">
                  <SaveFilled />
                  {this.state.buttontitle}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
        <Modal
          visible={this.state.openSearchModal}
          onCancel={this.handleCloseModal}
          title={this.props.t("active-porxy-card-modal-title")}
          maskClosable={false}
          centered
          footer={null}
          width={400}
          bodyStyle={{ overflowY: "auto", maxHeight: "800px" }}
        >
          <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label={this.props.t("entrypoints-modal-name-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="name" onChange={this.handleChangeName} />
            </Form.Item>
            <Form.Item
              name="token"
              label={this.props.t("token-name-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="token" onChange={this.handleChangeTokenName} />
            </Form.Item>
            {/* <Form.Item
              name="controllerAddress"
              label={this.props.t("controller-address-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="controllerAddress" onChange={this.handleChangeControllerAddress} />
            </Form.Item> */}
            <Button
              htmlType="submit"
              type="primary"
              style={{ position: "relative", left: "10em" }}
            >
              OK
            </Button>
          </Form>
          <Table
            columns={userColumns}
            rowKey="id"
            dataSource={this.state.users}
            locale={{ emptyText: this.props.t("empty-text") }}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: state.companyReducer.addresses,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  users: state.usersReducer.users,
  user: state.loginReducer.user,
  entryPoints: state.companyReducer.entryPoints,
  message: state.companyReducer.message,
  saveStatus: state.companyReducer.saveStatus,
});
const EntryPoints = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EntryPoint)
);
export default EntryPoints;
