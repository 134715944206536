import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Modal,
  Switch,
  DatePicker,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getMessages,
  saveMessage,
  modifyMessage,
  deleteMessage,
  activateMessage,
} from "../../actions/messages";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getAllCompanies } from "../../actions/company";
import parse from "html-react-parser";
import { withTranslation } from "react-i18next";
import {
  BarsOutlined,
  DeleteFilled,
  EditFilled,
  FilterOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import ImageUploader from "quill-image-uploader";
import ImageResize from "@apoiase/quill-image-resize";
import { message_types } from "../../constants/message-event-types";
import moment from "moment";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);

const mapDispatchToProps = (dispatch) => {
  return {
    getMessages: (companyId) => dispatch(getMessages(companyId)),
    saveMessage: (params) => dispatch(saveMessage(params)),
    modifyMessage: (params) => dispatch(modifyMessage(params)),
    deleteMessage: (params) => dispatch(deleteMessage(params)),
    activateMessage: (params) => dispatch(activateMessage(params)),
    getAllCompanies: () => dispatch(getAllCompanies()),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const { Option } = Select;

class ConnectedMessages extends Component {
  formRef = React.createRef();

  state = {
    content: "",
    title: "",
    selectedCompany: null,
    messages: [],
    modify: false,
    selectedId: null,
    selectedMessage: {},
    showDetails: false,
    text: "",
    active: true,
    date: null,
    mode: message_types.immediate,
    newMessageVisible: false,
    filteredInfo: {},
    filterKey: null,
  };

  toolbar = {
    imageUploader: {
      upload: (file) => {
        return new Promise(async (resolve, reject) => {
          if (
            file.type.includes("png") ||
            file.type.includes("jpeg") ||
            file.type.includes("jpg") ||
            file.type.includes("gif")
          ) {
            const image = new Image();
            image.onload = () => {
              if (
                image.width < 500 ||
                image.width > 2000 ||
                image.height < 500 ||
                image.height > 2000
              ) {
                message.error(this.props.t("save-fail"), 5);
                reject("Bad size!");
              }
            };
            image.src = URL.createObjectURL(file);
            const formData = new FormData();
            formData.append("file", file);
            let error = null;
            let result = null;
            await Axios.post("/2.0.0/message/picture", formData)
              .then((response) => {
                result = response.data;
              })
              .catch((err) => {
                error = err;
              });
            if (error) {
              reject(error);
            } else {
              resolve(result);
            }
          } else {
            message.error(this.props.t("save-fail"), 5);
            reject("Bad format");
          }
        });
      },
    },
    imageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      [{ color: [] }, { background: [] }],
      [
        {
          font: [],
        },
      ],
    ],
  };

  componentDidMount = async () => {
    await this.getMessages();
  };

  openNewMessage = () => {
    this.setState({ newMessageVisible: true });
  };

  handleCloseNewMessage = () => {
    this.setState({ newMessageVisible: false });
  };

  handleChangeDate = (value) => {
    if (value === null) {
      value = new Date();
      this.setState({ date: moment(value) });
    } else {
      this.setState({ date: moment(value) });
    }
  };

  getMessages = async () => {
    if (this.props.user.companyId) {
      await this.props.getMessages(this.props.user.companyId);
    } else {
      await this.props.getMessages(undefined);
      await this.props.getAllCompanies();
    }
    if (this.props.status) {
      this.setState({ messages: this.props.messages });
    }
  };

  handleChangeEditor = (event, delta, source, editor) => {
    this.setState({ content: event, text: editor.getText() });
  };

  handleActivateMessage = async (id) => {
    await this.props.activateMessage(id);
    await this.getMessages();
  };

  handleDeleteMessage = async (id) => {
    await this.props.deleteMessage(id);
    await this.getMessages();
  };

  submitForm = async () => {
    let toSave = {};
    if (this.state.modify) {
      if (this.props.user.companyId) {
        toSave = {
          id: this.state.selectedId,
          companyId: this.props.user.companyId,
          title: this.state.title,
          content: this.state.content,
          text: this.state.text,
          createdById: this.props.user.id,
          mode: this.state.mode,
          date: this.state.date,
          active: message_types.immediate ? true : false,
        };
      } else {
        toSave = {
          id: this.state.selectedId,
          companyId: this.state.selectedCompany,
          title: this.state.title,
          content: this.state.content,
          text: this.state.text,
          createdById: this.props.user.id,
          mode: this.state.mode,
          date: this.state.date,
          active: message_types.immediate ? true : false,
        };
      }
      await this.props.modifyMessage(toSave);
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);
        await this.getMessages();
        this.handleCancelModify();
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    } else {
      if (this.props.user.companyId) {
        toSave = {
          companyId: this.props.user.companyId,
          title: this.state.title,
          content: this.state.content,
          text: this.state.text,
          createdById: this.props.user.id,
          mode: this.state.mode,
          date: this.state.date,
          active: message_types.immediate ? true : false,
        };
      } else {
        toSave = {
          companyId: this.state.selectedCompany,
          officeId: this.props.user.officeId,
          title: this.state.title,
          content: this.state.content,
          text: this.state.text,
          createdById: this.props.user.id,
          mode: this.state.mode,
          date: this.state.date,
          active: message_types.immediate ? true : false,
        };
      }
      await this.props.saveMessage(toSave);
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);
        await this.getMessages();
        this.handleCancelModify();
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeCompany = (event) => {
    this.setState({ selectedCompany: event });
  };

  handleChangeSendOutMode = (event) => {
    this.setState({ mode: event });
  };

  handleModify = (record) => {
    this.setState({
      modify: true,
      title: record.title,
      content: record.content,
      text: record.text,
      selectedId: record.id,
      selectedCompany: record.companyId,
      newMessageVisible: true,
      date: new Date(record.date),
      mode: record.mode,
    });
    this.formRef.current.setFieldsValue({
      title: record.title,
      content: record.content,
      date: moment(new Date(record.date)),
      mode: record.mode,
    });
  };

  handleCancelModify = () => {
    this.setState({
      modify: false,
      title: "",
      content: "",
      text: "",
      selectedId: null,
      selectedCompany: null,
      newMessageVisible: false,
      mode: message_types.immediate,
    });
    this.formRef.current.resetFields();
  };

  handleShowDetails = (record) => {
    this.setState({ showDetails: true, selectedMessage: record });
  };

  handleCloseDetails = () => {
    this.setState({ showDetails: false, selectedMessage: {} });
  };

  handleChangeActive = async (event, record) => {
    await this.handleActivateMessage(record.id);
  };

  renderFilterMenu = () => {
    return (
      <Menu activeKey={this.state.filterKey}>
        <Menu.Item onClick={this.handleFilterActive} key="active">
          {this.props.t("active-filter-messages-button-label")}
        </Menu.Item>
        <Menu.Item onClick={this.handleFilterInactive} key="inactive">
          {this.props.t("inactive-filter-messages-button-label")}
        </Menu.Item>
        <Menu.Item onClick={this.handleFilterNeverActivated} key="never">
          {this.props.t("filter-nonused-messages-button-label")}
        </Menu.Item>
      </Menu>
    );
  };

  handleFilterNeverActivated = () => {
    const filteredMessages = this.props.messages.filter(
      (user) =>
        user.active === false &&
        new Date(user.created).getTime() === new Date(user.modified).getTime()
    );
    this.setState({ messages: filteredMessages, filterKey: "never" });
  };

  handleFilterInactive = () => {
    const filteredMessages = this.props.messages.filter(
      (user) => user.active === false
    );
    this.setState({ messages: filteredMessages, filterKey: "inactive" });
  };

  handleFilterActive = () => {
    const filteredMessages = this.props.messages.filter(
      (user) => user.active === true
    );
    this.setState({ messages: filteredMessages, filterKey: "active" });
  };

  handleFilterEverybody = () => {
    this.setState({ messages: this.props.messages, filterKey: "all" });
  };

  handleResetFilter = async () => {
    await this.getMessages();
    this.resetFilter();
  };

  resetFilter = () => {
    this.setState({
      filteredInfo: {},
      searchedColumn: null,
      searchText: "",
      filterKey: null,
    });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  render() {
    const columns = [
      {
        title: this.props.t("messages-table-header-title"),
        dataIndex: "title",
        key: "title",
      },
      !this.props.user.companyId
        ? {
            title: this.props.t("messages-table-header-company"),
            key: "company",
            render: (text, record) => {
              return (
                <Space size="middle">
                  {
                    record.company.companyTexts?.find(
                      (text) =>
                        text.textId === 3 &&
                        text.languageId === record.company.defaultLanguageId
                    )?.textValue
                  }
                </Space>
              );
            },
          }
        : {},
      {
        title: this.props.t("messages-table-header-createdby"),
        key: "createdBy",
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.createdBy.lastName + " " + record.createdBy.firstName}
            </Space>
          );
        },
      },
      {
        title: this.props.t("messages-table-header-created"),
        key: "created",
        render: (text, record) => {
          return (
            <Space size="middle">
              {new Date(record.created).toLocaleString()}
            </Space>
          );
        },
      },
      {
        title: this.props.t("details-button-label"),
        key: "details",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleShowDetails(record)}>
                <BarsOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("activate-button-label"),
        key: "activate",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Switch
                checked={record.active}
                onChange={(event) => this.handleChangeActive(event, record)}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleModify(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("message-delete-text")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteMessage(record.id)}
              >
                <Button danger type="primary">
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];

    const pickerDate = moment();
    pickerDate.set("minute", 0);
    pickerDate.set("second", 0);
    pickerDate.add(1, "hour");
    moment(pickerDate).format("YYYY-MM-DD HH:mm");

    return (
      <div style={{ height: "100%" }}>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("menu-messages-button-label")}</strong>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Button
              onClick={this.openNewMessage}
              type="primary"
              style={{ float: "right" }}
            >
              <PlusOutlined /> {this.props.t("messages-new-message-label")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Dropdown overlay={this.renderFilterMenu} placement="bottomLeft">
              <Button icon={<FilterOutlined />} style={{ margin: "1em" }}>
                {this.props.t("filters-button-label")}
              </Button>
            </Dropdown>
            <Button
              style={{ margin: "1em" }}
              onClick={this.handleResetFilter}
              disabled={
                this.state.filterKey === null &&
                Object.keys(this.state.filteredInfo).length === 0
              }
            >
              {this.props.t("reception-guests-resetfilters-button-label")}
            </Button>
            <div style={{ margin: "1em" }}>
              {this.props.t("never-activated-messages-ammount")}{" "}
              {
                this.props.messages.filter(
                  (user) =>
                    user.active === false &&
                    new Date(user.created).getTime() ===
                      new Date(user.modified).getTime()
                ).length
              }{" "}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={this.state.messages}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
              onChange={this.handleChangeTable}
              locale={{ emptyText: this.props.t("empty-text") }}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.newMessageVisible}
          onCancel={this.handleCloseNewMessage}
          footer={null}
          centered={true}
          forceRender
          maskClosable={false}
          closable
          title={
            this.state.modify
              ? this.props.t("messages-modify-message-label")
              : this.props.t("messages-new-message-label")
          }
          width={900}
        >
          <div
            style={{
              padding: "0.3em",
              backgroundColor: "#848484",
              marginBottom: "4px",
              marginLeft: "16.5%",
              color: "#ffffff",
            }}
          >
            {this.props.t("message-title-description")}
          </div>
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="company"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            {!this.props.user.companyId && (
              <Form.Item
                label={this.props.t("messages-table-header-company")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("messages-table-company-text"),
                  },
                ]}
              >
                <Select
                  value={this.state.selectedCompany}
                  onChange={this.handleChangeCompany}
                >
                  <Option value={this.state.selectedCompany} key={"all"}>
                    {this.props.t("messages-selectall")}
                  </Option>
                  {this.props.companies?.map((company) => {
                    return (
                      <Option value={company.id} key={company.id}>
                        {
                          company.companyTexts?.find(
                            (text) =>
                              text.languageId === company.defaultLanguageId &&
                              text.textId === 3
                          )?.textValue
                        }
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name="title"
              label={this.props.t("messages-title-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("messages-title-text"),
                },
              ]}
            >
              <Input name="title" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="content"
              label={this.props.t("messages-content-label")}
              initialValue=""
              rules={[
                {
                  required: true,
                  message: this.props.t("messages-content-text"),
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                value={this.state.content || ""}
                name="content"
                onChange={this.handleChangeEditor}
                style={{ height: "250px", marginBottom: "6vh" }}
                modules={this.toolbar}
              />
            </Form.Item>
            <Form.Item
              style={{ paddingTop: "1em" }}
              label={this.props.t("messages-message-mode-label")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                name="mode"
                value={this.state.mode}
                onChange={this.handleChangeSendOutMode}
              >
                <Option
                  value={message_types.immediate}
                  key={message_types.immediate}
                >
                  {this.props.t("messages-message-mode-immediate")}
                </Option>
                <Option value={message_types.time} key={message_types.time}>
                  {this.props.t("messages-message-mode-time")}
                </Option>
                <Option
                  value={message_types.deactive}
                  key={message_types.deactive}
                >
                  {this.props.t("messages-message-mode-deactivate")}
                </Option>
              </Select>
            </Form.Item>
            {this.state.mode === message_types.time && (
              <Form.Item
                label={this.props.t("reception-events-table-header-time")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("messages-table-company-text"),
                  },
                ]}
              >
                <DatePicker
                  name="date"
                  mode="date"
                  showTime
                  showSecond={false}
                  showNow={false}
                  minuteStep={5}
                  locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
                  onChange={this.handleChangeDate}
                  format="YYYY-MM-DD HH:mm"
                  defaultPickerValue={pickerDate}
                  value={
                    this.state.date === null
                      ? pickerDate
                      : moment(this.state.date)
                  }
                />
              </Form.Item>
            )}
            <Form.Item
              style={{
                textAlign: "right",
                width: "100%",
              }}
            >
              <Button
                onClick={this.handleCancelModify}
                style={{ margin: "1em" }}
              >
                {this.props.t("cancel-button-label")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "1em" }}
              >
                <SaveOutlined /> {this.props.t("save-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={this.state.showDetails}
          onCancel={this.handleCloseDetails}
          footer={null}
          centered={true}
          maskClosable={false}
          title={this.props.t("details-button-label")}
          width={900}
        >
          <div style={{ display: "flex" }}>
            <div>
              <strong>
                {this.props.t("companyadmin-address-table-header-address")}:
              </strong>
              &nbsp;
            </div>
            <div>{this.state.selectedMessage.title}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <strong>
                {this.props.t("messages-table-header-createdby")}:
              </strong>
              &nbsp;
            </div>
            <div>{`${this.state.selectedMessage.createdBy?.lastName} ${this.state.selectedMessage.createdBy?.firstName}`}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <strong>{this.props.t("messages-table-header-created")}:</strong>
              &nbsp;
            </div>
            <div>{`${new Date(
              this.state.selectedMessage.created
            ).toLocaleString()}`}</div>
          </div>
          <div>
            <div>
              <strong>{this.props.t("messages-content-label")}:</strong>
            </div>
            <div>{parse(this.state.selectedMessage.content || "")}</div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  messages: state.messageReducer.messages,
  saveStatus: state.messageReducer.saveStatus,
  status: state.messageReducer.status,
  companies: state.companyReducer.companies,
});
const Messages = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedMessages)
);
export default Messages;
