import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  Form,
  Select,
  message,
  Popconfirm,
  InputNumber,
  Switch,
  Collapse,
} from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAllAddresses } from "../../actions/company";
import { getAllLanguages } from "../../actions/language";
import {
  getAllOffices,
  saveOffice,
  modifyOffice,
  deleteOffice,
} from "../../actions/office";

const mapDispatchToProps = (dispatch) => {
  return {
    //ez itt át kell variálni, hogy office-id-re is kihozza
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    getAllOffices: () => dispatch(getAllOffices()),
    getAllLanguages: () => dispatch(getAllLanguages()),
    saveOffice: (params) => dispatch(saveOffice(params)),
    modifyOffice: (params) => dispatch(modifyOffice(params)),
    deleteOffice: (id) => dispatch(deleteOffice(id)),
  };
};
const { Panel } = Collapse;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedOffice extends Component {
  formRef = React.createRef();
  state = {
    officeName: "",
    officeAdminEmail: "",
    addressName: "",
    country: "",
    zip: "",
    city: "",
    address: "",
    defaultLanguage: null,
    modify: false,
    officeId: null,
    officeAnonymizationTime: 30,
    officeWorklogModuleOn: false,
  };

  componentDidMount = async () => {
    await this.props.getAllOffices();
    await this.props.getAllLanguages();
  };

  modifyOfficeAddress = async (id) => {
    const office = this.props.offices.find((o) => o.id === id);
    if (office === undefined) {
      return;
    }
    await this.props.getAllAddresses(null, office.id);
    if (this.props.addresses === undefined) {
      return;
    }

    const language = this.props.languages.find(
      (l) => l.id === office.defaultLanguageId
    );
    if (language === undefined) {
      return;
    }

    this.setState({
      modify: true,
      officeId: id,
      officeName: office.name,
      officeAdminEmail: office.officeAdminEmail,
      defaultLanguage: language.code,
      officeAnonymizationTime: office.anonymizationTime,
      officeWorklogModuleOn: office.worklogModuleOn,
      country: this.props.addresses[0].country,
      zip: this.props.addresses[0].zip,
      city: this.props.addresses[0].city,
      address: this.props.addresses[0].address,
      addressName: !this.props.addresses[0].name
        ? office.name
        : this.props.addresses[0].name,
    });
    this.formRef.current.setFieldsValue({
      officeName: office.name,
      officeAdminEmail: office.officeAdminEmail,
      lang: language.code,
      country: this.props.addresses[0].country,
      zip: this.props.addresses[0].zip,
      city: this.props.addresses[0].city,
      address: this.props.addresses[0].address,
      addressName: !this.props.addresses[0].name
        ? office.name
        : this.props.addresses[0].name,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeLanguage = (event) => {
    this.setState({ defaultLanguage: event });
  };

  handleDeleteOffice = async (id) => {
    await this.props.deleteOffice(id);
    if (this.props.deleteStatus) {
      await this.props.getAllOffices();
    }
  };

  submitForm = async () => {
    if (this.state.modify === true) {
      await this.modifyOffice();
    } else if (this.state.modify === false) {
      await this.createOffice();
    }
  };

  createOffice = async () => {
    const toSave = {
      name: this.state.officeName,
      officeAdminEmail: this.state.officeAdminEmail,
      language: this.state.defaultLanguage,
      anonimizationTime:
        this.state.officeAnonymizationTime === null ||
        this.state.officeAnonymizationTime === undefined
          ? 30
          : this.state.officeAnonymizationTime,
      worklogModuleOn: this.state.officeWorklogModuleOn,
      addressName: this.state.addressName,
      country: this.state.country,
      zip: this.state.zip,
      city: this.state.city,
      address: this.state.address,
    };

    await this.props.saveOffice(toSave);

    if (this.props.saveStatus) {
      message.success(this.props.t("save-success"), 5);
      this.setState({
        officeName: "",
        officeAdminEmail: "",
        addressName: "",
        name: "",
        language: "",
        country: "",
        zip: "",
        city: "",
        address: "",
        officeId: null,
        modify: false,
        worklogModuleOn: false,
      });
      this.formRef.current.setFieldsValue({
        officeName: "",
        addressName: "",
        officeAdminEmail: "",
        name: "",
        language: "",
        country: "",
        zip: "",
        city: "",
        address: "",
        officeId: null,
        modify: false,
        worklogModuleOn: false,
      });
      await this.props.getAllOffices();
    } else {
      message.error(this.props.t("save-fail"), 5);
      await this.props.getAllOffices();
    }
  };

  modifyOffice = async () => {
    const toSave = {
      id: this.state.officeId,
      officeId: this.state.officeId,
      name: this.state.officeName,
      officeAdminEmail: this.state.officeAdminEmail,
      language: this.state.defaultLanguage,
      anonimizationTime:
        this.state.officeAnonymizationTime === null ||
        this.state.officeAnonymizationTime === undefined
          ? 30
          : parseInt(this.state.officeAnonymizationTime),
      worklogModuleOn: this.state.officeWorklogModuleOn,
      addressName: this.state.addressName,
      country: this.state.country,
      zip: this.state.zip,
      city: this.state.city,
      address: this.state.address,
    };

    await this.props.modifyOffice(toSave);

    if (this.props.saveStatus) {
      message.success(this.props.t("save-success"), 5);
      await this.props.getAllOffices();
      this.setState({
        officeName: "",
        officeAdminEmail: "",
        addressName: "",
        name: "",
        language: "",
        country: "",
        zip: "",
        city: "",
        address: "",
        officeId: null,
        modify: false,
        worklogModuleOn: false,
      });
      this.formRef.current.setFieldsValue({
        officeName: "",
        officeAdminEmail: "",
        addressName: "",
        name: "",
        language: "",
        country: "",
        zip: "",
        city: "",
        address: "",
        officeId: null,
        modify: false,
        worklogModuleOn: false,
      });
    } else {
      message.error(this.props.t("save-fail"), 5);
      await this.props.getAllOffices();
    }
  };

  onAnonymizationTimeChange = (value) => {
    this.setState({
      officeAnonymizationTime: value,
    });
  };

  handleChangeWorklogModule = (event) => {
    this.setState({ officeWorklogModuleOn: event });
  };

  handleCancelModify = () => {
    this.setState({
      officeName: "",
      officeAdminEmail: "",
      worklogModuleOn: false,
      addressName: "",
      name: "",
      language: "",
      country: "",
      zip: "",
      city: "",
      address: "",
      officeId: null,
      modify: false,
    });
    this.formRef.current.setFieldsValue({
      officeName: "",
      officeAdminEmail: "",
      worklogModuleOn: false,
      addressName: "",
      name: "",
      language: "",
      country: "",
      zip: "",
      city: "",
      address: "",
      officeId: null,
      modify: false,
    });
  };

  render() {
    const columns = [
      {
        title: this.props.t("offices-office-name-label-table"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this.props.t("offices-office-admin-email-label"),
        dataIndex: "officeAdminEmail",
        key: "officeAdminEmail",
      },
      {
        title: this.props.t("companyadmin-anonymizationTime"),
        dataIndex: "anonymizationTime",
        key: "anonymizationTime",
      },
      {
        align: "center",
        title: this.props.t("modify-button-label"),
        key: true,
        render: (string, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.modifyOfficeAddress(record.id)}>
                {this.props.t("modify-button-label")}
              </Button>
            </Space>
          );
        },
        width: 128,
      },
      {
        align: "center",
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (string, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("offices-office-delete-confirm")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteOffice(record.id)}
              >
                <Button danger>{this.props.t("delete-button-label")}</Button>
              </Popconfirm>
            </Space>
          );
        },
        width: 128,
      },
    ];

    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00a49a" }}>
              <strong>{this.props.t("menu-offices-button-label")}</strong>
            </h1>
          </Col>
        </Row>

        <Collapse defaultActiveKey={["1"]}>
          <Panel
            key="1"
            header={
              this.state.modify === true
                ? this.props.t("offices-modify-office-label")
                : this.props.t("offices-new-office-label")
            }
          >
            <Row>
              <Col span={24} style={{ padding: "1em" }}>
                <Form
                  ref={this.formRef}
                  {...formItemLayout}
                  name="company"
                  onFinish={this.submitForm}
                  scrollToFirstError
                  layout="vertical"
                  wrapperCol={{ style: { padding: "0", margin: "0 0 0 0" } }}
                >
                  <Row>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        name="officeName"
                        label={
                          <strong>
                            {this.props.t("offices-office-name-label")}
                          </strong>
                        }
                        rules={[
                          {
                            required: true,
                            message: this.props.t("email-empty"),
                            whitespace: false,
                          },
                        ]}
                      >
                        <Input
                          name="officeName"
                          value={this.state.officeName}
                          onChange={this.handleChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        name="officeAdminEmail"
                        label={
                          <strong>
                            {this.props.t("offices-office-admin-email-label")}
                          </strong>
                        }
                        rules={[
                          {
                            required: true,
                            message: this.props.t("email-empty"),
                            whitespace: false,
                          },
                          {
                            type: "email",
                            message: this.props.t("email-wrong-format"),
                          },
                        ]}
                      >
                        <Input
                          name="officeAdminEmail"
                          value={this.state.officeAdminEmail}
                          onChange={this.handleChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        name="lang"
                        label={
                          <strong>
                            {this.props.t(
                              "companies-companyadmin-language-label"
                            )}
                          </strong>
                        }
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "companies-companyadmin-language-text"
                            ),
                          },
                        ]}
                      >
                        <Select
                          onChange={this.changeLanguage}
                          value={this.state.defaultLanguage}
                        >
                          {this.props.languages.map((language) => {
                            return (
                              <Select.Option
                                key={language.code}
                                value={language.code}
                              >{`${language.name} - ${language.code}`}</Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        label={
                          <strong>
                            {this.props.t("companyadmin-address-name-label")}:
                          </strong>
                        }
                        name="addressName"
                        initialValue={this.state.addressName}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: this.props.t(
                        //       "companyadmin-address-name-text"
                        //     ),
                        //   },
                        // ]}
                      >
                        <Input
                          name="addressName"
                          onChange={this.handleChange}
                          value={this.state.addressName}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        label={
                          <strong>
                            {this.props.t("companyadmin-address-country-label")}
                          </strong>
                        }
                        name="country"
                        initialValue={this.state.country}
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "companyadmin-address-country-text"
                            ),
                          },
                        ]}
                      >
                        <Input
                          name="country"
                          onChange={this.handleChange}
                          value={this.state.country}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        label={
                          <strong>
                            {this.props.t("companyadmin-address-zip-label")}
                          </strong>
                        }
                        name="zip"
                        initialValue={this.state.zip}
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "companyadmin-address-zip-text"
                            ),
                          },
                        ]}
                      >
                        <Input
                          name="zip"
                          onChange={this.handleChange}
                          value={this.state.zip}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        label={
                          <strong>
                            {this.props.t("companyadmin-address-city-label")}
                          </strong>
                        }
                        name="city"
                        initialValue={this.state.city}
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "companyadmin-address-city-text"
                            ),
                          },
                        ]}
                      >
                        <Input
                          name="city"
                          onChange={this.handleChange}
                          value={this.state.city}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        label={
                          <strong>
                            {this.props.t("companyadmin-address-address-label")}
                          </strong>
                        }
                        name="address"
                        initialValue={this.state.address}
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "companyadmin-address-address-text"
                            ),
                          },
                        ]}
                      >
                        <Input
                          name="address"
                          onChange={this.handleChange}
                          value={this.state.address}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        label={
                          <strong>
                            {this.props.t("companyadmin-anonymizationTime")}
                          </strong>
                        }
                      >
                        <InputNumber
                          min={1}
                          value={this.state.officeAnonymizationTime}
                          onChange={this.onAnonymizationTimeChange}
                          placeholder="30"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: "0.5em" }}>
                      <Form.Item
                        label={
                          <strong>
                            {this.props.t(
                              "companyadmin-worklogmoduleSwitch-label"
                            )}
                          </strong>
                        }
                      >
                        <Switch
                          checked={this.state.officeWorklogModuleOn}
                          onChange={this.handleChangeWorklogModule}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item style={{ textAlign: "right" }}>
                    {this.state.modify && (
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={this.handleCancelModify}
                      >
                        {this.props.t("cancel-button-label")}
                      </Button>
                    )}
                    <Button type="primary" htmlType="submit">
                      <SaveOutlined /> {this.props.t("save-button-label")}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Panel>
          <Panel key="2" header={this.props.t("offices")}>
            <Row>
              <Col span={24} style={{ padding: "1em" }}>
                <Table
                  bordered={false}
                  columns={columns}
                  dataSource={this.props.offices}
                  locale={{ emptyText: "Nincs adat" }}
                  pagination={
                    this.props.offices.length > 10
                      ? {
                          position: ["bottomCenter"],
                          pageSize: 5,
                          showSizeChanger: false,
                        }
                      : false
                  }
                  rowKey="id"
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  offices: state.officeReducer.offices,
  status: state.officeReducer.status,
  deleteStatus: state.officeReducer.deleteStatus,
  saveStatus: state.officeReducer.saveStatus,
  languages: state.languageReducer.languages,
  addresses: state.companyReducer.addresses,
});
const Office = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedOffice)
);
export default Office;
