import Users from "../components/Users/users";
import Companies from "../components/Companies/companies";
import Emergency from "../components/Emergency/emergency";
import Subscription from "../components/Subscription/subscription";
import Workers from "../components/Users/workers";
import Profile from "../components/Users/profile";
import ReceptionMain from "../components/Reception/receptionMain";
import UnderConstruction from "../components/UnderConstruction/underconstruction";
import Messages from "../components/Messages/messages";
import Help from "../components/Help/help";
import RegAppTokens from "../components/RegAppTokens/regAppTokens";
import CompanyDesign from "../components/CompanyAdmin/companydesign";
import CompanyAddresses from "../components/CompanyAdmin/companyAddresses";
import CompanyAgreements from "../components/CompanyAdmin/companyagreements";
import CompanyHrColleagues from "../components/CompanyAdmin/companyHrColleagues";
import CompanyReceptionColleagues from "../components/CompanyAdmin/companyReceptionColleagues";
import { subscriptionIdExceptions } from "./subscriptionIdExceptions";
import EntryPoints from "../components/EntryPoints/entrypoints-admin";
import WorkingUsers from "../components/Users/working-users";
import Office from "../components/Office/office";
import UserProximityCards from "../components/ProximityCard/proximitycard";
import Parking from "../components/Parking/parking";
import ParkingGroups from "../components/Parking/groups";
import ParkingGates from "../components/Parking/gates";
import Park from "../components/Parking/park";
import ManualEnter from "../components/Parking/manualEnter";
import OfficeAddresses from "../components/Office/officeAddresses";
//import CompanyLanguages from "../components/CompanyAdmin/companylanguages";
import { userRoles } from "./roles-types";

export class USER_GROUP {
  static SYSTEM_ADMIN = 1;
  static COMPANY_ADMIN = 2;
  static RECEPTION_ADMIN = 3;
  static HR_ADMIN = 4;
  static USER = 5;
  static OFFICE_ADMIN = 6;
}

export const routes = [
  {
    path: "/tl/system-admins",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN],
    groupsToLoad: [USER_GROUP.SYSTEM_ADMIN],
  },
  {
    path: "/tl/office-admins",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
    groupsToLoad: [USER_GROUP.OFFICE_ADMIN],
  },
  {
    path: "/tl/company-admins",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN],
    groupsToLoad: [USER_GROUP.COMPANY_ADMIN],
  },
  {
    path: "/tl/users",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN],
    groupsToLoad: [
      USER_GROUP.RECEPTION_ADMIN,
      USER_GROUP.HR_ADMIN,
      USER_GROUP.USER,
    ],
  },
  {
    path: "/tl/companies",
    component: Companies,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/administration",
    component: UnderConstruction,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/emergency",
    component: Emergency,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
    notAllowedSubscriptions: subscriptionIdExceptions.workExceptionIds,
  },
  {
    path: "/tl/subscriptions",
    component: Subscription,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/workers",
    component: Workers,
    rights: [userRoles.COMPANY_ADMIN, userRoles.HR_ADMIN],
  },
  {
    path: "/tl/reg-app-tokens",
    component: RegAppTokens,
    rights: [userRoles.COMPANY_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/design",
    component: CompanyDesign,
    rights: [userRoles.COMPANY_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/addresses",
    component: CompanyAddresses,
    rights: [userRoles.COMPANY_ADMIN],
  },
  {
    path: "/tl/agreements",
    component: CompanyAgreements,
    rights: [userRoles.COMPANY_ADMIN, userRoles.OFFICE_ADMIN],
    notAllowedSubscriptions: subscriptionIdExceptions.inviteExceptionIds,
  },
  {
    path: "/tl/hr-admin",
    component: CompanyHrColleagues,
    rights: [userRoles.COMPANY_ADMIN],
  },
  {
    path: "/tl/reception-admin",
    component: CompanyReceptionColleagues,
    rights: [userRoles.COMPANY_ADMIN],
    notAllowedSubscriptions: subscriptionIdExceptions.inviteExceptionIds,
  },
  {
    path: "/tl/profile",
    component: Profile,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.USER,
      userRoles.OFFICE_ADMIN,
    ],
    groupsToLoad: [
      USER_GROUP.RECEPTION_ADMIN,
      USER_GROUP.HR_ADMIN,
      USER_GROUP.USER,
      USER_GROUP.SYSTEM_ADMIN,
      USER_GROUP.COMPANY_ADMIN,
      USER_GROUP.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/reception",
    component: ReceptionMain,
    rights: [
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
    notAllowedSubscriptions: subscriptionIdExceptions.inviteExceptionIds,
  },
  {
    path: "/tl/company-admins",
    component: UnderConstruction,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/users",
    component: UnderConstruction,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/messages",
    component: Messages,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
    notAllowedSubscriptions: subscriptionIdExceptions.workExceptionIds,
  },
  {
    path: "/tl/entrypoints",
    component: EntryPoints,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/office-addresses",
    component: OfficeAddresses,
    rights: [userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/help",
    component: Help,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.USER,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/working-users",
    component: WorkingUsers,
    rights: [userRoles.COMPANY_ADMIN, userRoles.HR_ADMIN],
  },
  {
    path: "/tl/offices",
    component: Office,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
    groupsToLoad: [USER_GROUP.SYSTEM_ADMIN, USER_GROUP.OFFICE_ADMIN],
  },
  {
    path: "/tl/proximity-card",
    component: UserProximityCards,
    rights: [userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN],
  },
  {
    path: "/tl/parking",
    component: Parking,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/parking-groups",
    component: ParkingGroups,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/parking-gates",
    component: ParkingGates,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/parking-park",
    component: Park,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/manual-enter",
    component: ManualEnter,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
];
