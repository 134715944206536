//TODO KELL EGY EMERGENCY KAPUNYITÓ GOMB

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Popconfirm,
  Modal,
  Form,
  Switch,
  Transfer,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
  SaveOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  createEntryGate,
  deleteEntryGate,
  getEntryGates,
  getEntryGate,
  updateEntryGate,
  getParkingGroups,
  addParkingGroupToEntryGate,
} from "../../actions/parking";
import { getOneCompanyById } from "../../actions/company";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getEntryGates: (officeId) => dispatch(getEntryGates(officeId)),
    createEntryGate: (params) => dispatch(createEntryGate(params)),
    updateEntryGate: (id, params) => dispatch(updateEntryGate(id, params)),
    deleteEntryGate: (id) => dispatch(deleteEntryGate(id)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    getEntryGate: (id) => dispatch(getEntryGate(id)),
    getParkingGroups: (companyId, officeId) =>
      dispatch(getParkingGroups(companyId, officeId)),
    addParkingGroupToEntryGate: (id, params) =>
      dispatch(addParkingGroupToEntryGate(id, params)),
  };
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
class ConnectedParkingGates extends Component {
  state = {
    loaded: false,
    parking: [],
    entryGates: [],
    filteredInfo: {},
    openModal: false,
    modify: false,
    name: "",
    active: false,
    searchText: "",
    selectedGateId: null,
    targetKeys: [],
    selectedKeys: [],
    groupToGateVisible: false,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    await this.getAllGates();
    await this.getParkingGroups();
    this.setState({ loaded: true });
  };

  getAllGates = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getEntryGates(this.props.user.officeId);
    } else {
      if (this.hasRight([userRoles.COMPANY_ADMIN, userRoles.HR_ADMIN])) {
        await this.props.getOneCompanyById(this.props.user.companyId);
        await this.props.getEntryGates(this.props.company[0].officeId);
      }
    }
    if (this.props.status) {
      this.setState({
        entryGates: this.props.entryGates,
      });
    }
    this.setState({ loaded: true });
  };

  getParkingGroups = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getParkingGroups(null, this.props.user.officeId);
      await this.props.getEntryGates(this.props.user.officeId);
    } else {
      await this.props.getParkingGroups(this.props.user.companyId);
    }
    if (this.props.status) {
      this.setState({
        companyId: this.props.user.companyId,
        parkingGroups: this.props.parkingGroup,
      });
    }
    this.setState({ loaded: true });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleOpenNewEntryGateModal = () => {
    this.setState({
      openModal: true,
      active: false,
      name: "",
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleChangeActivate = async () => {
    if (this.state.active === false) {
      this.setState({
        active: true,
      });
    } else {
      this.setState({
        active: false,
      });
    }
  };

  handleOpenModifyModal = async (record) => {
    this.setState({
      openModal: true,
      modify: true,
      name: record.name,
      active: record.active,
      deviceId: record.deviceId,
      selectedGateId: record.id,
    });
    this.formRef.current.setFieldsValue({
      name: record.name,
      active: record.active,
      deviceId: record.deviceId,
    });
  };

  handleCloseModifyModal = async () => {
    this.setState({
      openModal: false,
      name: "",
      active: false,
      deviceId: null,
      modify: false,
    });
    this.formRef.current.resetFields();
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleModifyEntryGate = async () => {
    const toSend = {
      name: this.state.name,
      active: this.state.active,
      officeId: this.props.user.officeId,
      deviceId: this.state.deviceId,
      //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< IDE KELL MAJD A TYPE! >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // type: 1,
    };
    if (this.state.modify) {
      this.setState({ loaded: false });
      await this.props.updateEntryGate(this.state.selectedGateId, toSend);
    } else {
      this.setState({ loaded: false });
      await this.props.createEntryGate(toSend);
    }
    if (this.props.status) {
      this.setState({
        entryGates: this.props.entryGates,
      });
    }
    await this.getAllGates();
    this.setState({ loaded: true });
    this.handleCloseModifyModal();
  };

  handleDeleteEntryGate = async (record) => {
    await this.props.deleteEntryGate(record.id);
    await this.getAllGates();
  };

  addParkingGroupToEntryGate = async (record) => {
    await this.props.getEntryGate(record.id);
    if (this.props.status) {
      const target = this.props.entryGates.parkingGroups.map(
        (group) => group.id
      );
      this.setState({ targetKeys: target });
    }
    this.setState({ groupToGateVisible: true });
  };

  closeEntryGateAddToParkingGroup = () => {
    this.setState({
      groupToGateVisible: false,
      targetKeys: [],
      selectedKeys: [],
    });
  };

  handleSaveSelectedEntryGates = async () => {
    const params = {
      parkingGroupIds: this.state.targetKeys,
    };
    await this.props.addParkingGroupToEntryGate(
      this.props.entryGates.id,
      params
    );
    if (this.props.saveStatus) {
      this.closeEntryGateAddToParkingGroup();
    }
  };

  handleChangeTarget = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  render() {
    const columns = [
      {
        title: this.props.t("reception-events-table-header-name"),
        dataIndex: "name",
        key: "name",
        filteredValue: this.state.filteredInfo.name || null,
        ...this.getColumnSearchProps(
          "name",
          this.props.t("reception-events-table-header-name")
        ),
      },
      {
        title: this.props.t("entrypoints-isopened-status-title"),
        key: "active",
        render: (string, record) => {
          return (
            <Space
              size="middle"
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "1.1vw",
                  height: "1.1vw",
                  borderRadius: "50%",
                  backgroundColor:
                    record.active === null
                      ? "gray"
                      : record.active === true
                      ? "green"
                      : "gray",
                }}
              />
            </Space>
          );
        },
      },
    ];
    const officeAdminColumns = [
      {
        title: this.props.t("parking-group-management-label"),
        key: "parkingGroup",
        render: (text, record) => {
          return (
            <Space>
              <Button onClick={() => this.addParkingGroupToEntryGate(record)}>
                <IdcardOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleOpenModifyModal(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("delete-parking-entry-gate-label")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteEntryGate(record)}
              >
                <Button danger type="primary">
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("menu-gates-button-label")}</strong>
            </h1>
          </Col>
        </Row>
        {this.hasRight([userRoles.OFFICE_ADMIN]) && (
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col style={{ padding: "1em" }}>
              <Button onClick={this.handleOpenNewEntryGateModal} type="primary">
                <PlusOutlined />
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Table
              columns={
                this.hasRight([userRoles.OFFICE_ADMIN])
                  ? [...columns, ...officeAdminColumns]
                  : columns
              }
              rowKey="id"
              dataSource={this.state.entryGates}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.usersStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.openModal}
          onCancel={this.handleCloseModifyModal}
          title={
            this.state.modify
              ? this.props.t("modify-parking-entry-gate-label")
              : this.props.t("new-parking-entry-gate-label")
          }
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Space>
              <Button key="cancel" onClick={this.handleCloseModifyModal}>
                {this.props.t("cancel-button-label")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleModifyEntryGate}
              >
                {this.state.modify
                  ? this.props.t("modify-button-label")
                  : this.props.t("entrypoints-create-title")}
              </Button>
            </Space>,
          ]}
        >
          <Form
            onFinish={this.handleModifyEntryGate}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label={this.props.t("reception-events-table-header-name")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="name" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="deviceId"
              label={this.props.t("decive")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="deviceId" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item name="active" label={this.props.t("active-input-label")}>
              <Switch
                checked={this.state.active === true}
                onChange={this.handleChangeActivate}
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          maskClosable={false}
          closable={true}
          footer={[
            <Button onClick={this.closeEntryGateAddToParkingGroup}>
              {this.props.t("cancel-button-label")}
            </Button>,
            <Button onClick={this.handleSaveSelectedEntryGates} type="primary">
              <SaveOutlined /> {this.props.t("save-button-label")}
            </Button>,
          ]}
          visible={this.state.groupToGateVisible}
          centered
          bodyStyle={{
            textAlign: "center",
            margin: "0 auto",
          }}
          title={
            <h3 style={{ padding: 0, margin: 0 }}>
              <strong>{this.props.t("add-group-to-entrygate-label")}</strong>
            </h3>
          }
          onCancel={this.closeEntryGateAddToParkingGroup}
          destroyOnClose
          width={800}
        >
          <div>
            <Transfer
              style={{ textAlign: "left" }}
              dataSource={this.props.parkingGroup}
              render={(item) => `${item.name}`}
              targetKeys={this.state.targetKeys}
              selectedKeys={this.state.selectedKeys}
              onChange={this.handleChangeTarget}
              onSelectChange={this.handleSelectChange}
              rowKey={(record) => record.id}
              selectAllLabels={[<div></div>, <div></div>]}
              filterOption={() => false}
              locale={{
                notFoundContent: this.props.t("empty-text"),
                selectAll: this.props.t("select-all-label"),
                selectInvert: this.props.t("select-invert-label"),
              }}
              listStyle={{
                width: 350,
                height: 300,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  status: state.parkingReducer.status,
  company: state.companyReducer.company,
  entryGates: state.parkingReducer.entryGates,
  parkingGroup: state.parkingReducer.parkingGroup,
  saveStatus: state.parkingReducer.saveStatus,
});
const ParkingGates = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedParkingGates)
);
export default ParkingGates;
