import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from "../reducers/login";
import usersReducer from "../reducers/users";
import companyReducer from "../reducers/company";
import emergencyReducer from "../reducers/emergency";
import receptionReducer from "../reducers/reception";
import languageReducer from "../reducers/language";
import messageReducer from "../reducers/message";
import tagReducer from "../reducers/tag";
import officeReducer from "../reducers/office";
import cardReducer from "../reducers/proximitycard";
import parkingReducer from "../reducers/parking";
import { LOGIN_ACTIONS } from "../constants/action-types";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "usersReducer",
    "companyReducer",
    "emergencyReducer",
    "receptionReducer",
    "languageReducer",
    "messageReducer",
    "tagReducer",
    "officeReducer",
    "cardReducer",
    "parkingReducer"
  ],
};

const rootReducer = (state, action) => {
  if (action.type === LOGIN_ACTIONS.LOGOUT) {
    state.usersReducer = undefined;
    state.companyReducer = undefined;
    state.receptionReducer = undefined;
    state.emergencyReducer = undefined;
    state.languageReducer = undefined;
    state.messageReducer = undefined;
    state.tagReducer = undefined;
    state.officeReducer = undefined;
    state.cardReducer = undefined;
    state.parkingReducer = undefined
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  loginReducer,
  usersReducer,
  companyReducer,
  emergencyReducer,
  receptionReducer,
  languageReducer,
  messageReducer,
  tagReducer,
  officeReducer,
  cardReducer,
  parkingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
