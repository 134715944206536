import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Form,
  Modal,
  Select,
  message,
} from "antd";
import Highlighter from "react-highlight-words";
import { EditFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  getAllManualCreatedGuestsWithCompanyOrOfficeId,
  modifyPostCreatedGuestWithVehicle,
} from "../../actions/reception";
import { createVehicleWithGuest } from "../../actions/parking";
import { getAllCompanies } from "../../actions/company";
import moment from "moment";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    createVehicleWithGuest: (params) =>
      dispatch(createVehicleWithGuest(params)),
    getAllManualCreatedGuestsWithCompanyOrOfficeId: (officeId, companyId) =>
      dispatch(
        getAllManualCreatedGuestsWithCompanyOrOfficeId(officeId, companyId)
      ),
    getAllCompanies: () => dispatch(getAllCompanies()),
    modifyPostCreatedGuestWithVehicle: (params) =>
      dispatch(modifyPostCreatedGuestWithVehicle(params)),
  };
};
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
class ConnectedManualEnter extends Component {
  state = {
    loaded: false,
    filteredInfo: {},
    sortedInfo: {},
    guests: [],
    vehicles: [],
    newGuestRegisterModal: false,
    modify: false,
    guestId: null,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    await this.getAllPostCreatedGuests();
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getAllCompanies();
    }
    this.setState({ loaded: true });
  };

  getAllPostCreatedGuests = async () => {
    if (this.props.user.groups.some((group) => group.id === 6)) {
      await this.props.getAllManualCreatedGuestsWithCompanyOrOfficeId(
        this.props.user.officeId,
        null
      );
    } else {
      await this.props.getAllManualCreatedGuestsWithCompanyOrOfficeId(
        null,
        this.props.user.companyId
      );
    }
    const vehicles = this.props.guests.map((g) => g.vehicles);
    const lnum = vehicles.map((v) => (v.length !== 0 ? v[0] : ""));
    this.setState({
      guests: this.props.guests,
      vehicles: lnum,
    });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleOpenNewGuestRegisterModal = () => {
    this.setState({
      newGuestRegisterModal: true,
    });
  };

  closeNewGuestRegisterModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      newGuestRegisterModal: false,
      firstName: "",
      lastName: "",
      email: "post-created-guest-email@invalidEmail.invalid",
      representedCompany: "",
      phoneNumber: "",
      licensePlateNumber: "",
      modify: false,
    });
  };

  changeCompany = (event) => {
    this.setState({ companyId: event });
  };

  handleOpenModifyGuest = (record) => {
    const licencePlateNumber = this.state.vehicles.filter((v) =>
      record.id === v.guestId ? v.licencePlateNumber : null
    );
    const companyName = this.props.companies.filter((c) =>
      c.id === record.companyId ? c.name : null
    );
    this.setState({
      guestId: record.id,
      newGuestRegisterModal: true,
      modify: true,
      firstName: record.firstName,
      lastName: record.lastName,
      representedCompany: record.representedCompany,
      companyId: this.hasRight([userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN, userRoles.HR_ADMIN])
        ? this.props.user.companyId
        : record.companyId,
      phoneNumber: record.comment,
      licensePlateNumber: licencePlateNumber[0].licencePlateNumber,
    });
    this.formRef.current.setFieldsValue({
      lastName: record.lastName,
      firstName: record.firstName,
      representedCompany: record.representedCompany,
      phoneNumber: record.comment,
      licensePlateNumber: licencePlateNumber[0].licencePlateNumber,
      company: this.hasRight([userRoles.OFFICE_ADMIN]) ? companyName[0].name : "",
    });
  };

  handleSave = async () => {
    const toSend = {
      guestId: this.state.guestId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: "post-created-guest-email@invalidEmail.invalid",
      representedCompany: this.state.representedCompany,
      companyId: this.hasRight([userRoles.COMPANY_ADMIN, userRoles.RECEPTION_ADMIN, userRoles.HR_ADMIN])
        ? this.props.user.companyId
        : this.state.companyId,
      officeId: this.hasRight([userRoles.OFFICE_ADMIN]) ? this.props.user.officeId : null,
      phoneNumber: this.state.phoneNumber,
      isManualPostCreatedGuest: true,
      licensePlateNumber: this.state.licensePlateNumber,
    };
    if (this.state.modify) {
      await this.props.modifyPostCreatedGuestWithVehicle(toSend);
      if (this.props.guestSaveStatus) {
        this.closeNewGuestRegisterModal();
        this.getAllPostCreatedGuests();
      } else {
        message.error("save-error-label");
      }
    } else {
      await this.props.createVehicleWithGuest(toSend);
      if (this.props.saveStatus) {
        this.closeNewGuestRegisterModal();
        this.getAllPostCreatedGuests();
      } else {
        message.error("save-error-label");
      }
    }
  };

  render() {
    const columns = [
      {
        title: this.props.t("guest-register-lastname-label"),
        dataIndex: "lastName",
        key: "lastName",
        filteredValue: this.state.filteredInfo.lastName || null,
        ...this.getColumnSearchProps(
          "lastName",
          this.props.t("guest-register-lastname-label")
        ),
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        dataIndex: "firstName",
        key: "firstName",
        filteredValue: this.state.filteredInfo.firstName || null,
        ...this.getColumnSearchProps(
          "firstName",
          this.props.t("guest-register-firstname-label")
        ),
      },
      {
        title: this.props.t("licensePlateNumber"),
        key: "licensePlateNumber",
        render: (text, record) => {
          return this.state.vehicles?.map((v) =>
            v.guestId === record.id ? v.licencePlateNumber : ""
          );
        },
      },
      {
        title: this.props.t("profile-phone-label"),
        key: "comment",
        dataIndex: "comment",
      },
    ];

    this.hasRight([userRoles.OFFICE_ADMIN])
      ? columns.push(
          {
            title: this.props.t("companies-label-companyname"),
            key: "company",
            render: (text, record) => {
              return record.companyId
                ? this.props.companies.map((c) =>
                    c.id === record.companyId ? c.name : ""
                  )
                : "";
            },
          },
          {
            title: this.props.t("reception-guests-table-header-arrivetime"),
            key: "arriving-time",
            dataIndex: "created",
            render: (text, record) => {
              return moment(record.created).format("YYYY.MM.DD. HH:mm");
            },
          },
          {
            title: this.props.t("modify-button-label"),
            key: "modify",
            render: (text, record) => {
              return (
                <Space size="middle">
                  <Button onClick={() => this.handleOpenModifyGuest(record)}>
                    <EditFilled />
                  </Button>
                </Space>
              );
            },
          }
        )
      : columns.push(
          {
            title: this.props.t("reception-guests-table-header-arrivetime"),
            key: "arriving-time",
            dataIndex: "created",
            render: (text, record) => {
              return moment(record.created).format("YYYY.MM.DD. HH:mm");
            },
          },
          {
            title: this.props.t("modify-button-label"),
            key: "modify",
            render: (text, record) => {
              return (
                <Space size="middle">
                  <Button onClick={() => this.handleOpenModifyGuest(record)}>
                    <EditFilled />
                  </Button>
                </Space>
              );
            },
          }
        );

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("manual-enter-button-label")}</strong>
            </h1>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col style={{ padding: "1em" }}>
            <Button
              type="primary"
              onClick={this.handleOpenNewGuestRegisterModal}
            >
              <PlusOutlined />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.guests}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.usersStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.newGuestRegisterModal}
          onCancel={this.closeNewGuestRegisterModal}
          title={
            this.state.modify
              ? this.props.t("modify-button-label")
              : this.props.t("guest-main-register-button-label")
          }
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Button key="invite" type="primary" onClick={this.handleSave}>
              {this.state.modify
                ? this.props.t("modify-button-label")
                : this.props.t("save-button-label")}
            </Button>,
            <Button key="cancel" onClick={this.closeNewGuestRegisterModal}>
              {this.props.t("cancel-button-label")}
            </Button>,
          ]}
        >
          <Form
            onFinish={this.handleSave}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="lastName"
              label={this.props.t("guest-register-lastname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="lastName" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={this.props.t("guest-register-firstname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="firstName" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="representedCompany"
              label={this.props.t("guest-register-company-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="representedCompany" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={this.props.t("profile-phone-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
                {
                  pattern:
                    // eslint-disable-next-line no-useless-escape
                    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                  message: this.props.t("wrong-format"),
                },
                {
                  max: 15,
                  message: this.props.t("phone-number-max-digit"),
                },
              ]}
            >
              <Input name="phoneNumber" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="licensePlateNumber"
              label={this.props.t("licensePlateNumber")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="licensePlateNumber" onChange={this.handleChange} />
            </Form.Item>
            {this.hasRight([userRoles.OFFICE_ADMIN]) ? (
              <Form.Item
                name="company"
                label={this.props.t("messages-table-header-company")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("entrypoints-modal-must-fill"),
                  },
                ]}
              >
                <Select
                  value={this.props.company}
                  onChange={this.changeCompany}
                >
                  {this.props.companies.map((company) => {
                    return (
                      <Option key={company.id} value={company.id}>
                        {company.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            ) : (
              <></>
            )}

            <Form.Item hidden>
              <Button htmlType="submit" type="primary" hidden>
                {this.props.t("add-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  guests: state.receptionReducer.guests,
  companies: state.companyReducer.companies,
  saveStatus: state.parkingReducer.saveStatus,
  guestSaveStatus: state.receptionReducer.guestSaveStatus,
});
const ManualEnter = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedManualEnter)
);
export default ManualEnter;
