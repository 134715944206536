import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Popconfirm,
  Modal,
  Switch,
  Form,
  message,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  DeleteFilled,
  EditFilled,
  SearchOutlined,
  BarsOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  deleteVehicle,
  getUsersWithVehicles,
  getVehicles,
  updateVehicle,
} from "../../actions/parking";
import { modifyUser } from "../../actions/users";
import { modifyGuest, modifyPostCreatedGuest } from "../../actions/reception";
import { getAllCompanies } from "../../actions/company";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllVehicle: (userId, guestId) => dispatch(getVehicles(userId, guestId)),
    getUsersWithVehicles: (companyId, officeId) =>
      dispatch(getUsersWithVehicles(companyId, officeId)),
    deleteVehicle: (id) => dispatch(deleteVehicle(id)),
    updateVehicle: (id, params) => dispatch(updateVehicle(id, params)),
    modifyUser: (user) => dispatch(modifyUser(user)),
    modifyGuest: (params) => dispatch(modifyGuest(params)),
    modifyPostCreatedGuest: (params) =>
      dispatch(modifyPostCreatedGuest(params)),
    getAllCompanies: () => dispatch(getAllCompanies()),
  };
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
class ConnectedParking extends Component {
  state = {
    loaded: false,
    parking: [],
    parkingGroups: [],
    users: [],
    vehicles: [],
    filteredInfo: {},
    detailsModalVisible: false,
    selectedUser: [],
    name: "",
    licencePlateNumber: "",
    active: true,
    modify: false,
    modifyUserVisible: false,
    selectedId: null,
    email: "",
    lastName: "",
    position: "",
    firstName: "",
    phoneNumber: "",
    modifyGuestVisible: false,
    representedCompany: null,
    postCreatedModify: false,
    selectedVehicleId: null,
    isGuest: false,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getAllCompanies();
    }
    await this.props.getAllVehicle();
    await this.getUsers();
    this.setState({ loaded: true });
  };

  getUsers = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.SYSTEM_ADMIN, userRoles.COMPANY_ADMIN, userRoles.HR_ADMIN])) {
      await this.props.getUsersWithVehicles(this.props.user.companyId);
      if (this.props.usersStatus) {
        this.setState({ users: this.props.users });
      }
    } else {
      await this.props.getUsersWithVehicles(
        undefined,
        this.props.user.officeId
      );
      if (this.props.usersStatus) {
        this.setState({ users: this.props.users });
      }
    }
    this.setState({ loaded: true });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handleChangeLicencePlateNumber = (event) => {
    this.setState({ licencePlateNumber: event.target.value });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleOpenDetails = async (record) => {
    this.setState({ detailsModalVisible: true });
    await this.props.getAllVehicle(record.id, null);
    this.setState({
      selectedVehicleId: record.id,
      vehicles: this.props.vehicle,
    });
  };

  handleOpenGuestDetails = async (record) => {
    this.setState({ detailsModalVisible: true });
    await this.props.getAllVehicle(null, record.id);
    this.setState({
      selectedVehicleId: record.id,
      isGuest: true,
      vehicles: this.props.vehicle,
    });
  };

  handleCloseDetails = async () => {
    this.setState({ detailsModalVisible: false });
    this.cancelModify();
  };

  deleteVehicle = async (record) => {
    await this.props.deleteVehicle(record.id);
  };

  modifyVehicle = async (record) => {
    const toSend = {
      // name: this.state.name,
      licensePlateNumber: this.state.licencePlateNumber,
      active: this.state.active,
    };
    await this.props.updateVehicle(record.id, toSend);
    this.state.isGuest
      ? await this.props.getAllVehicle(null, this.state.selectedVehicleId)
      : await this.props.getAllVehicle(this.state.selectedVehicleId, null);

    this.setState({
      vehicles: this.props.vehicle,
      modify: false,
    });
  };

  setModify = async () => {
    this.setState({
      modify: true,
    });
  };
  cancelModify = async () => {
    this.setState({
      modify: false,
      name: "",
      active: true,
      licencePlateNumber: "",
    });
  };
  handleActivateVehicles = async () => {
    if (this.state.active === false) {
      this.setState({
        active: true,
      });
    } else {
      this.setState({
        active: false,
      });
    }
  };

  openModifyUser = (record) => {
    console.log(record.inviteId);
    if (record.inviteId || record.isManualPostCreatedGuest) {
      this.setState({
        modifyGuestVisible: true,
        representedCompany: record.representedCompany,
      });
      if (record.isManualPostCreatedGuest) {
        this.setState({
          postCreatedModify: true,
        });
      }
    }
    this.setState({
      modifyUserVisible: true,
      email: record.email,
      firstName: record.firstName,
      lastName: record.lastName,
      phoneNumber: record.phoneNumber,
      selectedId: record.id,
    });
    this.formRef.current.setFieldsValue({
      email: record.email,
      firstName: record.firstName,
      lastName: record.lastName,
      position: record.position,
      phoneNumber: record.phoneNumber,
      active: record.active,
      representedCompany: record.representedCompany,
    });
  };

  closeModifyUser = () => {
    this.formRef.current.resetFields();
    this.setState({
      modifyUserVisible: false,
      modifyGuestVisible: false,
      postCreatedModify: false,
      email: "",
      firstName: "",
      lastName: "",
      position: "",
      phoneNumber: "",
      selectedId: null,
      active: true,
    });
  };

  handleClickModify = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      await this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    const user = {
      id: this.state.selectedId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      company: this.state.representedCompany,
      phoneNumber: this.state.phoneNumber,
    };
    if (this.state.modifyGuestVisible) {
      this.state.postCreatedModify
        ? await this.props.modifyPostCreatedGuest(user)
        : await this.props.modifyGuest(user);
    } else {
      await this.props.modifyUser(user);
    }
    if (this.props.saveStatus || this.props.guestSaveStatus) {
      message.success(this.props.t("save-success"), 5);
      await this.getUsers();
      this.closeModifyUser();
    } else {
      message.error(this.props.t("save-fail"), 5);
    }
  };

  render() {
    const columns = [
      {
        title: this.props.t("guest-register-lastname-label"),
        dataIndex: "lastName",
        key: "lastName",
        filteredValue: this.state.filteredInfo.lastName || null,
        ...this.getColumnSearchProps(
          "lastName",
          this.props.t("guest-register-lastname-label")
        ),
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        dataIndex: "firstName",
        key: "firstName",
        filteredValue: this.state.filteredInfo.firstName || null,
        ...this.getColumnSearchProps(
          "firstName",
          this.props.t("guest-register-firstname-label")
        ),
      },
      {
        title: this.props.t("email-label"),
        dataIndex: "email",
        key: "email",
        filteredValue: this.state.filteredInfo.email || null,
        ...this.getColumnSearchProps("email", this.props.t("email-label")),
        render: (text, record) => {
          return record.isManualPostCreatedGuest ? "" : record.email;
        },
      },
      {
        title: this.props.t("profile-phone-label"),
        key: "phoneNumber",
        dataIndex: "phoneNumber",
        render: (text, record) => {
          return record.phoneNumber ? record.phoneNumber : record.comment;
        },
      },
      {
        title: this.props.t("companies-label-companyname"),
        key: "company",
        render: (text, record) => {
          return this.hasRight([userRoles.OFFICE_ADMIN])
            ? record.companyId
              ? this.props.companies.map((c) =>
                  c.id === record.companyId ? c.name : ""
                )
              : ""
            : record.representedCompany
            ? record.representedCompany
            : this.props.user.company.name;
        },
      },
      {
        title: this.props.t("car-details"),
        key: "vehicleDetails",
        render: (text, record) => {
          return record.inviteId === undefined ? (
            <Space size="middle">
              <Button onClick={() => this.handleOpenDetails(record)}>
                <BarsOutlined />
              </Button>
            </Space>
          ) : (
            <Space size="middle">
              <Button onClick={() => this.handleOpenGuestDetails(record)}>
                <BarsOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t(""),
        key: "isGuest",
        render: (text, record) => {
          return record.inviteId !== undefined ? (
            <h1 style={{ fontSize: "1.5rem" }}>
              <strong>G</strong>
            </h1>
          ) : (
            <h1 style={{ fontSize: "1.5rem" }}>
              <strong>U</strong>
            </h1>
          );
        },
      },
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.openModifyUser(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      },
    ];
    const vehicleColumns = [
      // {
      //   title: this.props.t("reception-events-table-header-name"),
      //   key: "name",
      //   dataIndex: "name",
      //   render: (string, record) => {
      //     return this.state.modify === false ? (
      //       record.name
      //     ) : (
      //       <Input
      //         style={{ width: "9em" }}
      //         placeholder={record.name}
      //         value={this.state.name}
      //         onChange={this.handleChangeName}
      //       />
      //     );
      //   },
      // },
      {
        title: this.props.t("licensePlateNumber"),
        key: "licencePlateNumber",
        dataIndex: "licencePlateNumber",
        render: (string, record) => {
          return this.state.modify === false ? (
            record.licencePlateNumber
          ) : (
            <Input
              style={{ width: "9em" }}
              placeholder={record.licencePlateNumber}
              value={this.state.licencePlateNumber}
              onChange={this.handleChangeLicencePlateNumber}
            />
          );
        },
      },
      {
        title: this.props.t(""),
        key: "active",
        render: (string, record) => {
          return this.state.modify === false ? (
            <Space
              size="middle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "1.1vw",
                  height: "1.1vw",
                  borderRadius: "50%",
                  backgroundColor:
                    record.active === null
                      ? "gray"
                      : record.active === true
                      ? "green"
                      : "gray",
                }}
              />
            </Space>
          ) : (
            <Switch
              checked={record.active && this.state.active === true}
              onChange={this.handleActivateVehicles}
            />
          );
        },
      },
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return this.state.modify === false ? (
            <Space size="middle">
              <Button onClick={() => this.setModify()}>
                <EditFilled />
              </Button>
            </Space>
          ) : (
            <Space size="middle">
              <Button onClick={() => this.cancelModify()}>
                <CloseOutlined />
              </Button>
              <Button onClick={() => this.modifyVehicle(record)}>
                <CheckOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("vehicle-delete-popup-message")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.deleteVehicle(record)}
              >
                <Button danger type="primary">
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("menu-parking-button-label")}</strong>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.users}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.usersStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.detailsModalVisible}
          onCancel={this.handleCloseDetails}
          title={this.props.t("car-details")}
          maskClosable={false}
          footer={false}
          forceRender
          centered
          width={700}
        >
          <Table
            columns={vehicleColumns}
            rowKey="id"
            dataSource={this.state.vehicles}
            locale={{ emptyText: this.props.t("empty-text") }}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
              showSizeChanger: false,
            }}
            onChange={this.handleChangeTable}
          />
        </Modal>
        <Modal
          visible={this.state.modifyUserVisible}
          onCancel={this.closeModifyUser}
          title={this.props.t("modify-button-label")}
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Button
              key="invite"
              type="primary"
              onClick={this.handleClickModify}
            >
              {this.props.t("modify-button-label")}
            </Button>,
            <Button key="cancel" onClick={this.closeModifyUser}>
              {this.props.t("cancel-button-label")}
            </Button>,
          ]}
        >
          <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="lastName"
              label={this.props.t("guest-register-lastname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-lastname-text"),
                },
              ]}
            >
              <Input name="lastName" onChange={this.handleChange} />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={this.props.t("guest-register-firstname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-firstname-text"),
                },
              ]}
            >
              <Input name="firstName" onChange={this.handleChange} />
            </Form.Item>
            {this.state.email !==
            "post-created-guest-email@invalidEmail.invalid" ? (
              <Form.Item
                name="email"
                label={this.props.t("email-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("email-empty"),
                  },
                  {
                    type: "email",
                    message: this.props.t("email-wrong-format"),
                  },
                ]}
              >
                <Input name="email" onChange={this.handleChange} />
              </Form.Item>
            ) : (
              []
            )}

            {this.state.modifyGuestVisible || this.state.postCreatedModify ? (
              <Form.Item
                name="representedCompany"
                label={this.props.t("companyadmin-languages-companyname-label")}
              >
                <Input name="representedCompany" onChange={this.handleChange} />
              </Form.Item>
            ) : (
              <Form.Item
                name="phoneNumber"
                label={this.props.t("profile-phone-label")}
                rules={[
                  {
                    pattern:
                      // eslint-disable-next-line no-useless-escape
                      /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                    message: this.props.t("wrong-format"),
                  },
                  {
                    max: 15,
                    message: this.props.t("phone-number-max-digit")
                  },
                ]}
              >
                <Input name="phoneNumber" onChange={this.handleChange} />
              </Form.Item>
            )}

            <Form.Item hidden>
              <Button htmlType="submit" type="primary" hidden>
                {this.props.t("add-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  usersStatus: state.usersReducer.status,
  saveStatus: state.usersReducer.saveStatus,
  guestSaveStatus: state.receptionReducer.guestSaveStatus,
  vehicle: state.parkingReducer.vehicle,
  companies: state.companyReducer.companies,
});
const Parking = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedParking)
);
export default Parking;
