import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Popconfirm,
  Modal,
  Switch,
  Select,
  Form,
  DatePicker,
  ConfigProvider,
  Menu,
  Dropdown,
  message,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  DeleteFilled,
  EditFilled,
  SearchOutlined,
  BarsOutlined,
  PlusOutlined,
  BookOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  changeParkingPlace,
  createParking,
  createParkingGroup,
  deleteParking,
  deleteReservation,
  getParkingGroups,
  getParkingStatuses,
  getReservationForParking,
  getUsersWithVehicles,
  getVehicle,
  updateParking,
} from "../../actions/parking";
import { getAllCompanies } from "../../actions/company";
import moment from "moment";
import localehu from "antd/es/locale/hu_HU";
import { userRoles } from "../../constants/roles-types";

const mapDispatchToProps = (dispatch) => {
  return {
    getParkingGroups: (companyId, officeId) =>
      dispatch(getParkingGroups(companyId, officeId)),
    createGroup: (params) => dispatch(createParkingGroup(params)),
    getAllCompanies: () => dispatch(getAllCompanies()),
    createParking: (params) => dispatch(createParking(params)),
    updateParking: (id, params) => dispatch(updateParking(id, params)),
    deleteParking: (id) => dispatch(deleteParking(id)),
    getVehicle: (id) => dispatch(getVehicle(id)),
    getParkingStatuses: (companyId, officeId) =>
      dispatch(getParkingStatuses(companyId, officeId)),
    getUsersWithVehicles: (companyId, officeId) =>
      dispatch(getUsersWithVehicles(companyId, officeId)),
    reservationForParking: (id) => dispatch(getReservationForParking(id)),
    deleteReservation: (id) => dispatch(deleteReservation(id)),
    changeParkingPlace: (params) => dispatch(changeParkingPlace(params)),
  };
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
class ConnectedPark extends Component {
  state = {
    name: "",
    searchText: "",
    parkingGroupId: "",
    show: false,
    loaded: false,
    active: false,
    modify: false,
    openVehicleModal: false,
    parkingModalVisible: false,
    reservationModalVisible: false,
    users: [],
    parking: [],
    vehicle: [],
    companies: [],
    parkingGroups: [],
    filteredInfo: {},
    groupId: null,
    companyId: null,
    targetParkingId: null,
    selectedParkingId: null,
    parkingGroupName: "",
    reservations: [],
    selectedReservationId: null,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    await this.getParkingGroups();
    await this.getAllCompanies();
    await this.getParkingStatuses();
    this.setState({ loaded: true });
  };

  getParkingStatuses = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getParkingStatuses(null, this.props.user.officeId);
    } else {
      await this.props.getParkingStatuses(this.props.user.companyId, null);
    }
    if (this.props.status) {
      this.setState({
        parking: this.props.parking,
      });
    }
    this.setState({
      loaded: true,
    });
  };

  getParkingGroups = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getParkingGroups(null, this.props.user.officeId);
    } else {
      await this.props.getParkingGroups(this.props.user.companyId, null);
    }
    if (this.props.status) {
      this.setState({
        parkingGroups: this.props.parkingGroup,
      });
    }
    this.setState({ loaded: true });
  };

  getAllCompanies = async () => {
    this.setState({ loaded: false });
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getAllCompanies();
      if (this.props.companyStatus) {
        this.setState({ companies: this.props.companies });
      }
    }
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  closeVehicleDetailsModal = () => {
    this.setState({
      openVehicleModal: false,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleChangeActivate = async () => {
    if (this.state.active === false) {
      this.setState({
        active: true,
      });
    } else {
      this.setState({
        active: false,
      });
    }
  };

  handleChangeGroup = async (event) => {
    this.setState({
      parkingGroupId: event,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleCancel = () => {
    this.setState({
      parkingModalVisible: false,
      name: "",
      active: false,
      parkingGroupId: null,
      modify: false,
    });
    this.formRef.current.resetFields();
  };

  handleOpenModify = (record) => {
    this.setState({
      modify: true,
      parkingModalVisible: true,
      name: record.name,
      isUsed: record.isUsed,
      active: record.active,
      parkingGroupId: record.parkingGroupId,
      selectedParkingId: record.id,
    });
    this.formRef.current.setFieldsValue({
      name: record.name,
      isUsed: record.isUsed,
      active: record.active,
      parkingGroupName: record.parkingGroup.name,
    });
  };

  modifyParking = async () => {
    if (this.state.modify) {
      const toSend = {
        name: this.state.name,
        active: this.state.active,
        parkingGroupId: this.state.parkingGroupId,
      };
      await this.props.updateParking(this.state.selectedParkingId, toSend);
      this.getParkingStatuses();
      this.handleCancel();
      this.setState({
        loaded: true,
      });
    } else {
      const toSend = {
        name: this.state.name,
        active: this.state.active,
        parkingGroupId: this.state.parkingGroupId,
      };
      await this.props.createParking(toSend);
      if (this.props.saveStatus) {
        await this.getParkingStatuses();
        this.handleCancel();
      } else {
        message.error(this.props.t("create-sample-error"));
      }
    }
  };

  handleDeleteParking = async (record) => {
    await this.props.deleteParking(record.id);
    await this.getParkingStatuses();
  };

  getUserData = async (record) => {
    if (
      this.hasRight([
        userRoles.SYSTEM_ADMIN,
        userRoles.COMPANY_ADMIN,
        userRoles.HR_ADMIN,
      ])
    ) {
      await this.props.getUsersWithVehicles(this.props.user.companyId);
      if (this.props.usersStatus) {
        this.setState({ users: this.props.users });
      }
    } else {
      await this.props.getUsersWithVehicles(
        undefined,
        this.props.user.officeId
      );
      if (this.props.usersStatus) {
        this.setState({ users: this.props.users });
      }
    }

    const usersWithVehicle = this.props.users.filter((u) => {
      if (record.vehicle !== null) {
        if (record.vehicle.userId) {
          return record.vehicle.userId === u.id ? u : "";
        } else {
          return record.vehicle.guestId === u.id && u.inviteId !== undefined
            ? u
            : "";
        }
      }
      return null;
    });
    this.setState({
      users: usersWithVehicle,
      openVehicleModal: true,
      // vehicle: this.props.vehicle,
    });
  };

  getReservation = async (record) => {
    await this.props.reservationForParking(record.id);
    if (this.props.status) {
      this.setState({
        selectedReservationId: record.id,
        reservationModalVisible: true,
        reservations: this.props.reservations,
      });
    }
  };

  closeGetReservation = async () => {
    this.setState({
      reservationModalVisible: false,
    });
  };

  createParkingModal = () => {
    this.setState({
      parkingModalVisible: true,
    });
  };

  handleChangeCompany = async (event) => {
    this.setState({
      companyId: event,
      parkingGroupId: null,
    });
    this.setState({ loaded: false });
    await this.props.getParkingGroups(event);
    if (this.props.status) {
      this.setState({
        parking: [],
        parkingGroups: this.props.parkingGroup,
      });
    }
    this.setState({ loaded: true });
  };

  handleDeleteReservation = async (record) => {
    await this.props.deleteReservation(record.id);
    await this.props.reservationForParking(this.state.selectedReservationId);
    if (this.props.status) {
      this.setState({
        reservations: this.props.reservations,
      });
    }
  };

  handleChangeParkingPlace = async (event, record) => {
    const toSend = {
      vehicleId: record.vehicleId,
      targetParkingId: event,
    };
    await this.props.changeParkingPlace(toSend);
    await this.getParkingStatuses();
    this.setState({
      loaded: true,
    });
  };

  getColumnDateSearchProps = (dataIndex, searchLabel, type = "text") => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let from = selectedKeys[0];
      if (selectedKeys[0] === undefined || selectedKeys[0] === null) {
        from = new Date();
      }
      return (
        <div style={{ padding: 8 }}>
          {dataIndex === "from" ? (
            <div>
              <ConfigProvider locale={localehu} name="from">
                <DatePicker
                  ref={(node) => {
                    this.searchInput = node;
                  }}
                  placeholder={`${searchLabel}`}
                  value={moment(from, "YYYY-MM-DD")}
                  format={["YYYY-MM-DD", "DD-MM-YYYY"]}
                  locale={{ dateTimeFormat: "YYYY-MM-DD" }}
                  onChange={(e, text) => {
                    setSelectedKeys(
                      text ? [new Date(text).toLocaleDateString()] : []
                    );
                  }}
                  onPressEnter={() =>
                    this.handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              </ConfigProvider>
            </div>
          ) : (
            <div>
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={`${searchLabel}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  this.handleSearch(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}

          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {this.props.t("search-button-label")}
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 100 }}
            >
              {this.props.t("reset-button-label")}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (type === "text") {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return new Date(record[dataIndex])
          .toLocaleString()
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput?.select?.());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={
              type === "text"
                ? text.toString()
                : new Date(text).toLocaleDateString()
            }
          />
        </Space>
      ) : type === "date" ? (
        <Space align="center">{new Date(text).toLocaleDateString()}</Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  renderFilterMenu = () => {
    return (
      <Menu activeKey={this.state.filterKey}>
        <Menu.Item onClick={this.handleFilterActive} key="active">
          {this.props.t("active-reservations-label")}
        </Menu.Item>
        <Menu.Item onClick={this.handleFilterInactive} key="inactive">
          {this.props.t("inactive-reservations-label")}
        </Menu.Item>
        <Menu.Item onClick={this.handleResetFilter} key="never">
          {this.props.t("reception-guests-resetfilters-button-label")}
        </Menu.Item>
      </Menu>
    );
  };

  handleFilterInactive = () => {
    const filteredReservations = this.props.reservations.filter(
      (r) => r.active === false
    );
    this.setState({
      reservations: filteredReservations,
      filterKey: "inactive",
    });
  };

  handleFilterActive = () => {
    const filteredReservations = this.props.reservations.filter(
      (r) => r.active === true
    );
    this.setState({ reservations: filteredReservations, filterKey: "active" });
  };

  resetFilter = () => {
    this.setState({
      filteredInfo: {},
      searchedColumn: null,
      searchText: "",
      filterKey: null,
    });
  };

  handleResetFilter = async () => {
    await this.props.reservationForParking(this.state.selectedReservationId);
    this.setState({
      reservations: this.props.reservations,
    });
    this.resetFilter();
  };

  render() {
    const updateColumns = [
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleOpenModify(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("delete-parking-popup")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteParking(record)}
              >
                <Button danger type="primary" disabled={record.isUsed === true}>
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];

    const parkingColumns = [
      {
        title: this.props.t("reception-events-table-header-name"),
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps(
          "name",
          this.props.t("reception-events-table-header-name")
        ),
      },

      {
        title: this.props.t("is-used-parking"),
        key: "isUsed",
        render: (string, record) => {
          return (
            <Space
              size="middle"
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "1.1vw",
                  height: "1.1vw",
                  borderRadius: "50%",
                  backgroundColor:
                    record.isUsed === null
                      ? "gray"
                      : record.isUsed === true
                      ? "green"
                      : "gray",
                }}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("licensePlateNumber"),
        dataIndex: "licencePlateNumber",
        key: "licencePlateNumber",
        // filteredValue: this.state.filteredInfo.licencePlateNumber || null,
        // ...this.getColumnSearchProps(
        //   "licencePlateNumber",
        //   this.props.t("licensePlateNumber")
        // ),
        render: (string, record) => {
          const vehicle = this.props.parking?.map((v) =>
            record.vehicleId !== null && v.vehicle !== null
              ? record.vehicleId === v.vehicle.id
                ? v.vehicle.licencePlateNumber
                : console.log(v)
              : ""
          );
          return vehicle;
        },
      },
      {
        title: this.props.t("move-vehicle"),
        key: "movingVehicle",
        render: (string, record) => {
          const filteredParking = this.props.parking.filter(
            (p) => p.active && !p.isUsed
          );
          return (
            record.vehicle !== null && (
              <Select
                style={{ width: "8vw" }}
                value={this.state.targetParkingId}
                onChange={(event) =>
                  this.handleChangeParkingPlace(event, record)
                }
              >
                {filteredParking?.map((parking) => {
                  return (
                    <Select.Option key={parking.id} value={parking.id}>
                      {parking.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )
          );
        },
      },
      {
        title: this.props.t("vehicle-owner-data"),
        dataIndex: "user",
        key: "user",
        render: (string, record) => {
          return (
            record.vehicle !== null && (
              <Space
                size="middle"
                style={{
                  display: "flex",
                }}
              >
                <Button onClick={() => this.getUserData(record)}>
                  <BarsOutlined />
                </Button>
              </Space>
            )
          );
        },
      },
      {
        title: this.props.t("entrypoints-isopened-status-title"),
        key: "active",
        render: (string, record) => {
          return this.state.modify === true ? (
            <Switch
              checked={this.state.active === true}
              onChange={this.handleChangeActivate}
            />
          ) : (
            <Space
              size="middle"
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "1.1vw",
                  height: "1.1vw",
                  borderRadius: "50%",
                  backgroundColor:
                    record.active === null
                      ? "gray"
                      : record.active === true
                      ? "green"
                      : "gray",
                }}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("reservation-data"),
        dataIndex: "reservation",
        key: "reservation",
        render: (string, record) => {
          return (
            <Space
              size="middle"
              style={{
                display: "flex",
              }}
            >
              <Button onClick={() => this.getReservation(record)}>
                <BookOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];

    this.hasRight([userRoles.OFFICE_ADMIN]) &&
      parkingColumns.push({
        title: this.props.t("companies-label-companyname"),
        dataIndex: "company",
        key: "company",
        render: (text, record) => {
          const companyName = this.props.companies?.map((c) =>
            c.id === record.parkingGroup.companyId ? c.name : ""
          );
          return companyName;
        },
      });

    const userDetailColumns = [
      {
        title: this.props.t("guest-register-lastname-label"),
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: this.props.t("email-label"),
        dataIndex: "email",
        key: "email",
      },
      {
        title: this.props.t("profile-phone-label"),
        key: "phoneNumber",
        dataIndex: "phoneNumber",
      },
      {
        title: this.props.t("companies-label-companyname"),
        key: "representedCompany",
        render: (text, record) => {
          return record.companyId !== undefined
            ? this.props.companies?.map((c) =>
                c.id === record.companyId ? c.name : ""
              )
            : record.representedCompany !== undefined
            ? record.representedCompany
            : [];
        },
      },
      {
        title: this.props.t(""),
        key: "isGuest",
        render: (text, record) => {
          return record.inviteId !== undefined ? (
            <h1 style={{ fontSize: "1.5rem" }}>
              <strong>G</strong>
            </h1>
          ) : (
            <h1 style={{ fontSize: "1.5rem" }}>
              <strong>U</strong>
            </h1>
          );
        },
      },
    ];

    const reservtionColumns = [
      {
        title: this.props.t("reservation-from-label"),
        dataIndex: "from",
        key: "from",
        filteredValue: this.state.filteredInfo.from || null,
        ...this.getColumnDateSearchProps(
          "from",
          this.props.t("reception-guests-date-title-label"),
          "from"
        ),
        render: (text, record) => {
          return moment(record.from).format("YYYY.MM.DD");
        },
      },
      {
        title: this.props.t("reservation-to-label"),
        dataIndex: "to",
        key: "to",
        render: (text, record) => {
          return moment(record.to).format("YYYY.MM.DD");
        },
      },
      {
        title: this.props.t("reception-events-table-header-name"),
        key: "name",
        render: (text, record) => {
          const user = this.props.reservations?.map((r) =>
            record.userId !== undefined && record.userId === r.user?.id
              ? r.user.lastName + " " + r.user.firstName
              : record.guestId === r.guest?.id
              ? r.guest.lastName + " " + r.guest.firstName
              : ""
          );
          return user;
        },
      },
      {
        title: this.props.t(""),
        dataIndex: "active",
        key: "active",
        render: (string, record) => {
          return (
            <Space
              size="middle"
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "1.1vw",
                  height: "1.1vw",
                  borderRadius: "50%",
                  backgroundColor:
                    record.active === null
                      ? "gray"
                      : record.active === true
                      ? "green"
                      : "gray",
                }}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={this.props.t("delete-reservation-popup")}
                okText={this.props.t("yes-button-label")}
                cancelText={this.props.t("no-button-label")}
                onConfirm={() => this.handleDeleteReservation(record)}
              >
                <Button danger type="primary">
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("park-label")}</strong>
            </h1>
          </Col>
        </Row>
        {this.hasRight([userRoles.OFFICE_ADMIN]) && (
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col style={{ padding: "1em" }}>
              <Button type="primary" onClick={() => this.createParkingModal()}>
                <PlusOutlined />
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Table
              columns={
                this.hasRight([userRoles.OFFICE_ADMIN])
                  ? [...parkingColumns, ...updateColumns]
                  : parkingColumns
              }
              rowKey="id"
              dataSource={this.state.parking}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.usersStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
        <Modal
          visible={this.state.openVehicleModal}
          onCancel={this.closeVehicleDetailsModal}
          title={this.props.t("vehicle-owner-data")}
          maskClosable={false}
          forceRender
          centered
          footer={false}
          width={1000}
        >
          <Table
            columns={userDetailColumns}
            rowKey="id"
            dataSource={this.state.users}
            locale={{ emptyText: this.props.t("empty-text") }}
            loading={!this.props.usersStatus && !this.state.loaded}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
              showSizeChanger: false,
            }}
            onChange={this.handleChangeTable}
          />
        </Modal>
        <Modal
          visible={this.state.parkingModalVisible}
          onCancel={this.handleCancel}
          title={
            this.state.modify
              ? this.props.t("modify-parking")
              : this.props.t("create-parking")
          }
          maskClosable={false}
          forceRender
          centered
          footer={
            <Space>
              <Button key="cancel" onClick={this.handleCancel}>
                {this.props.t("cancel-button-label")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.modifyParking}
              >
                {this.props.t("modify-button-label")}
              </Button>
            </Space>
          }
        >
          <Form
            onFinish={this.modifyParking}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label={this.props.t("reception-events-table-header-name")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input name="name" onChange={this.handleChange} />
            </Form.Item>

            <Form.Item
              name="parkingGroupName"
              label={this.props.t("parking-group-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Select
                // style={{ width: "8vw" }}
                value={this.state.parkingGroupId}
                onChange={(event) => this.handleChangeGroup(event)}
              >
                {this.props.parkingGroup?.map((group) => {
                  return (
                    <Select.Option key={group.id} value={group.id}>
                      {group.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="active"
              label={this.props.t("active-input-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Switch
                checked={this.state.active === true}
                onChange={this.handleChangeActivate}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={this.state.reservationModalVisible}
          onCancel={this.closeGetReservation}
          title={this.props.t("reservation-data")}
          maskClosable={false}
          forceRender
          centered
          footer={false}
          width={1000}
        >
          <Dropdown overlay={this.renderFilterMenu} placement="bottomLeft">
            <Button icon={<FilterOutlined />} style={{ margin: "1em" }}>
              {this.props.t("filters-button-label")}
            </Button>
          </Dropdown>
          <Table
            columns={reservtionColumns}
            rowKey="id"
            dataSource={this.state.reservations}
            locale={{ emptyText: this.props.t("empty-text") }}
            loading={!this.props.usersStatus && !this.state.loaded}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
              showSizeChanger: false,
            }}
            onChange={this.handleChangeTable}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  parking: state.parkingReducer.parking,
  status: state.parkingReducer.status,
  parkingGroup: state.parkingReducer.parkingGroup,
  saveStatus: state.parkingReducer.saveStatus,
  companies: state.companyReducer.companies,
  vehicle: state.parkingReducer.vehicle,
  invites: state.receptionReducer.invites,
  users: state.usersReducer.users,
  reservations: state.parkingReducer.reservations,
  companyStatus: state.companyReducer.status,
});
const Park = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedPark)
);
export default Park;
