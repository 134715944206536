import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, message, Button, Table, Checkbox } from "antd";
import { getAllUsers, addGroupToUser, removeGroupFromUser } from "../../actions/users";
import { finishWizard } from "../../actions/company";
import { getApiKey, refreshUser } from "../../actions/login";
import Modal from "antd/lib/modal/Modal";
import kivalo from "../../static/kivalo.png";
import hatradolhetsz from "../../static/hatradolhetsz.png";
import { QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import Axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { saveFirstMessage } from "../../actions/messages";
import apple from "../../static/appledownload.png";
import google from "../../static/googledownload.png";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUser: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    addGroupToUser: (userId, groupId, callback) =>
      dispatch(addGroupToUser(userId, groupId, callback)),
    removeGroupFromUser: (userId, groupId, callback) =>
      dispatch(removeGroupFromUser(userId, groupId, callback)),
    finishWizard: (userId, companyId, reception, hradmin) =>
      dispatch(finishWizard(userId, companyId, reception, hradmin)),
    refreshUser: (userId) => dispatch(refreshUser(userId)),
    saveFirstMessage: (params) => dispatch(saveFirstMessage(params)),
    getApiKey: (token) => dispatch(getApiKey(token)),
  };
};

class ConnectedWizardRoles extends Component {
  state = {
    reception: [],
    hradmin: [],
    users: [],
    rolesVisible: true,
    endingVisible: false,
    sendMessageVisible: false,
    popup: false,
  };

  componentDidMount = async () => {
    await this.props.getAllUser([2, 3, 4, 5], this.props.user.companyId);
    if (this.props.usersStatus) {
      this.setState({ users: this.props.users });
    }
    await this.getAllReception();
    await this.getAllHRAdmin();
    await this.getDefaultMessage();
  };

  getDefaultMessage = async () => {
    const htmlFile = await Axios.get(
      `/1.0.0/assets/messages/message_${this.props.user.company.defaultLanguage.code.toLowerCase()}.html`
    );
    this.setState({ htmlFile: htmlFile.data });
  };

  getAllHRAdmin = async () => {
    await this.props.getAllUser([4], this.props.user.companyId);
    if (this.props.usersStatus) {
      const hrKeys = this.props.users.map((user) => {
        return String(user.id);
      });
      this.setState({ hradmin: hrKeys });
    }
  };

  getAllReception = async () => {
    await this.props.getAllUser([3], this.props.user.companyId);
    if (this.props.usersStatus) {
      const receptionKeys = this.props.users.map((user) => {
        return String(user.id);
      });
      this.setState({ reception: receptionKeys });
    }
  };

  handleChangeReception = (event) => {
    let reception = this.state.reception;
    reception = [...reception, event];

    if (
      (this.props.user.company.subscription.maxReception === null
        ? Number.MAX_SAFE_INTEGER
        : this.props.user.company.subscription.maxReception) >=
      this.state.reception.length + 1
    ) {
      this.setState({ reception: reception });
    } else {
      message.error(this.props.t("companyadmin-reception-max-error"), 5);
    }
  };

  handleDeselectReception = (event) => {
    const reception = this.state.reception.filter((id) => id !== event);
    this.setState({ reception: reception });
  };

  handleChangeHr = (event) => {
    let hr = this.state.hradmin;
    hr = [...hr, event];

    if (
      (this.props.user.company.subscription.maxHRadmin === null
        ? Number.MAX_SAFE_INTEGER
        : this.props.user.company.subscription.maxHRadmin) >=
      this.state.hradmin.length + 1
    ) {
      this.setState({ hradmin: hr });
    } else {
      message.error(this.props.t("companyadmin-hr-max-error"), 5);
    }
  };

  handleDeselectHr = (event) => {
    const hr = this.state.hradmin.filter((id) => id !== event);
    this.setState({ hradmin: hr });
  };

  handleFinish = async () => {
    if (this.props.user.completeWizard) {
      await this.props.refreshUser(this.props.user.id);
      this.props.history.push("/tl/reception");
    } else {
      await this.props.finishWizard(
        this.props.user.id,
        this.props.user.companyId,
        this.state.reception,
        this.state.hradmin
      );
      if (this.props.saveStatus) {
        await this.props.refreshUser(this.props.user.id);
        await this.props.getApiKey(this.props.token);
        this.props.history.push("/tl/reception");
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    }
  };

  openRoles = () => {
    this.setState({ rolesVisible: true, popup: true });
  };

  closeRoles = () => {
    this.setState({ rolesVisible: false, popup: false });
  };

  openEnding = () => {
    this.setState({ endingVisible: true, sendMessageVisible: false });
  };

  closeSendMessage = () => {
    this.setState({ sendMessageVisible: false });
  };

  openEndingWithSave = async () => {
    const toSave = {
      companyId: this.props.user.companyId,
      title: this.props.t("send-message-default-title"),
      content: this.state.htmlFile,
      text: this.state.text,
      createdById: this.props.user.id,
    };
    await this.props.saveFirstMessage(toSave);
    await this.props.refreshUser(this.props.user.id);
    this.setState({ endingVisible: true, sendMessageVisible: false });
  };

  closeEnding = () => {
    this.setState({ endingVisible: false });
  };

  handleChangeHrCheckbox = (event, record) => {
    if (event.target.checked === true) {
      this.handleChangeHr(record.id);
    } else {
      this.handleDeselectHr(record.id);
    }
  };

  handleChangeReceptionCheckbox = (event, record) => {
    if (event.target.checked === true) {
      this.handleChangeReception(record.id);
    } else {
      this.handleDeselectReception(record.id);
    }
  };

  openSendMessage = () => {
    if (this.props.user.company.isFirstMessageSent) {
      this.setState({ endingVisible: true });
    } else {
      this.setState({ sendMessageVisible: true });
    }
  };

  handleChangeMessage = (event, delta, source, editor) => {
    this.setState({ htmlFile: event, text: editor.getText() });
  };

  handleCloseEnding = () => {
    this.setState({ endingVisible: false });
  };

  render() {
    const columns = [
      {
        title: (
          <h3>
            <strong>{this.props.t("guest-register-lastname-label")}</strong>
          </h3>
        ),
        key: "lastName",
        dataIndex: "lastName",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("guest-register-firstname-label")}</strong>
          </h3>
        ),
        key: "firstName",
        dataIndex: "firstName",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("email-label")}</strong>
          </h3>
        ),
        key: "email",
        dataIndex: "email",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("profile-position-label")}</strong>
          </h3>
        ),
        key: "position",
        dataIndex: "position",
      },
      {
        title: (
          <h3>
            <strong>{this.props.t("profile-phone-label")}</strong>
          </h3>
        ),
        key: "phoneNumber",
        dataIndex: "phoneNumber",
      },
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }}>
              <h3>
                <strong>{this.props.t("workers-group-reception")}</strong>
              </h3>
            </div>
            <div style={{ textAlign: "center" }}>
              <h3 style={{ color: "#00C0B7" }}>
                <strong>{`${this.state.reception.length}${
                  this.props.user.company.subscription.maxReception === null
                    ? ""
                    : "/" + this.props.user.company.subscription.maxReception
                }`}</strong>
              </h3>
            </div>
          </div>
        ), 
        key: "reception",
        render: (text, record) => {
          return (
            <div
              style={{ margin: "0 auto", width: "90%", textAlign: "center" }}
            >
              <Checkbox
                checked={this.state.reception.includes(record.id)}
                onChange={(event) =>
                  this.handleChangeReceptionCheckbox(event, record)
                }
              />
            </div>
          );
        },
      },
      {
        title: (
          <div>
            <div style={{ textAlign: "center" }}>
              <h3>
                <strong>{this.props.t("workers-group-hr")}</strong>
              </h3>
            </div>
            <div style={{ textAlign: "center" }}>
              <h3 style={{ color: "#00C0B7" }}>
                <strong>{`${this.state.hradmin.length}${
                  this.props.user.company.subscription.maxHRadmin === null
                    ? ""
                    : "/" + this.props.user.company.subscription.maxHRadmin
                }`}</strong>
              </h3>
            </div>
          </div>
        ), 
        key: "hradmin",
        render: (text, record) => {
          return (
            <div
              style={{ margin: "0 auto", width: "90%", textAlign: "center" }}
            >
              <Checkbox
                checked={this.state.hradmin.includes(record.id)}
                onChange={(event) => this.handleChangeHrCheckbox(event, record)}
              />
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Row style={{ padding: "1em" }}>
          <Col span={24}>
            <Table
              dataSource={this.state.users}
              columns={columns}
              rowKey="id"
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
              scroll={{ x: 1200 }}
            />
          </Col>
        </Row>
        <Row style={{ margin: "10px 0 10px 0", padding: "1em" }}>
          <Col
            span={24}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Button type="dashed" onClick={this.openRoles}>
                  {this.props.t("wizard-roles-button")}{" "}
                  <QuestionCircleOutlined />
                </Button>
              </div>
              <div>
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => this.props.prev()}
                >
                  {this.props.t("back-button-label")}
                </Button>
                <Button
                  type="primary"
                  onClick={this.openSendMessage}
                  disabled={
                    this.state.hradmin.length === 0 ||
                    this.state.reception.length === 0
                  }
                >
                  {this.props.t("ready-button-label")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          visible={this.state.rolesVisible}
          maskClosable={false}
          closable={false}
          footer={null}
          centered
          bodyStyle={{
            backgroundImage: `url("${kivalo}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            height: "600px",
            paddingTop: "12px",
          }}
          width={900}
        >
          <Row>
            <Col span={12}>
              <div style={{ textAlign: "left" }}>
                <h1
                  style={{
                    color: "#00AEA9",
                    marginTop: "0",
                    fontSize: "2.5rem",
                    paddingLeft: "0.62em",
                  }}
                >
                  <strong>
                    {this.props.t("wizard-roles-excellent-popup-title")}
                  </strong>
                </h1>
                <p
                  style={{
                    paddingLeft: "1.5em",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {this.props.t("wizard-roles-excellent-popup-setrole")}
                </p>
                <p
                  style={{
                    paddingLeft: "1.5em",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {parse(this.props.t("wizard-roles-excellent-popup-levels"))}
                </p>
                <p
                  style={{
                    paddingLeft: "1.5em",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {parse(
                    this.props.t(
                      "wizard-roles-excellent-popup-levels-description"
                    )
                  )}
                </p>
                {this.state.popup ? (
                  <div style={{ padding: "0em 2em 2em 2em" }}>
                    <Button
                      onClick={this.closeRoles}
                      size="large"
                      style={{ marginRight: "15px" }}
                    >
                      {this.props.t("back-button-label")}
                    </Button>
                  </div>
                ) : (
                  <div style={{ padding: "0em 2em 2em 2em" }}>
                    <Button
                      onClick={() => this.props.prev()}
                      size="large"
                      style={{ marginRight: "15px" }}
                    >
                      {this.props.t("back-button-label")}
                    </Button>
                    <Button
                      onClick={this.closeRoles}
                      type="primary"
                      size="large"
                    >
                      {this.props.t("next-button-label")}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  textAlign: "center",
                  paddingLeft: "36px",
                  paddingTop: "20px",
                  color: "#F9F9F9",
                  fontSize: "1.2rem",
                }}
              >
                <div>
                  <strong>
                    {this.props.t("wizard-roles-button").toLowerCase()}
                  </strong>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          visible={this.state.sendMessageVisible}
          maskClosable={false}
          closable={false}
          footer={null}
          centered
        >
          <Row>
            <Col span={24}>
              <div>
                <h1>{this.props.t("send-message-title")}</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ReactQuill
                value={this.state.htmlFile}
                onChange={this.handleChangeMessage}
                style={{ maxHeight: "400px", overflowY: "auto" }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "right", marginTop: "15px" }}>
              <Button onClick={this.openEnding} type="link">
                <u>{this.props.t("skip-button-label")}</u>
              </Button>
              <Button
                onClick={this.closeSendMessage}
                style={{ marginRight: "15px" }}
              >
                {this.props.t("back-button-label")}
              </Button>
              <Button onClick={this.openEndingWithSave} type="primary">
                {this.props.t("send-button-label")}
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          visible={this.state.endingVisible}
          maskClosable={false}
          closable={false}
          footer={null}
          centered
          bodyStyle={{
            backgroundImage: `url("${hatradolhetsz}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            height: "600px",
          }}
          width={900}
        >
          <Row>
            <Col span={12}>
              <div style={{ textAlign: "left" }}>
                <h1
                  style={{
                    color: "#00AEA9",
                    marginTop: "5px",
                    fontSize: "2.5rem",
                    paddingLeft: "0.62em",
                    marginBottom: "5px",
                  }}
                >
                  <strong>
                    {parse(this.props.t("wizard-roles-ready-title"))}
                  </strong>
                </h1>
                <p
                  style={{
                    paddingLeft: "1.5em",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {parse(this.props.t("wizard-roles-ready-everythingset"))}
                </p>
                <p
                  style={{
                    paddingLeft: "1.5em",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {parse(this.props.t("wizard-roles-ready-settings"))}
                </p>
                <div
                  style={{
                    paddingBottom: "5px",
                    textAlign: "center",
                  }}
                >
                  <strong>TabLog Work</strong> -{" "}
                  {this.props.t("tablog-roles-modal-worker-app")}
                </div>
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "5px",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "6px", paddingTop: "1px" }}>
                    <a href="https://apps.apple.com/app/id1535194786">
                      <img alt="apple-work" src={apple} height={38} />
                    </a>
                  </div>
                  <div style={{ marginLeft: "6px" }}>
                    <a href="https://play.google.com/store/apps/details?id=hu.appentum.tablogworker">
                      <img alt="google-work" src={google} height={40} />
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "5px",
                    textAlign: "center",
                  }}
                >
                  <strong>TabLog Kiosk</strong> -{" "}
                  {this.props.t("tablog-roles-modal-tablet-app")}
                </div>
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "5px",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "6px", paddingTop: "1px" }}>
                    <a href="https://apps.apple.com/hu/app/tablog-kiosk/id1535194995">
                      <img alt="apple-kiosk" src={apple} height={38} />
                    </a>
                  </div>
                  <div style={{ marginLeft: "6px" }}>
                    <a href="https://play.google.com/store/apps/details?id=hu.appentum.tablogreg">
                      <img alt="google-kiosk" src={google} height={40} />
                    </a>
                  </div>
                </div>
                <div style={{ padding: "2em", display: "flex" }}>
                  <Button onClick={this.handleCloseEnding} size="large">
                    {this.props.t("back-button-label")}
                  </Button>
                  <Button
                    onClick={this.handleFinish}
                    type="primary"
                    size="large"
                    style={{ marginLeft: "1em" }}
                  >
                    {this.props.t("wizard-roles-ready-tomytablog")}{" "}
                    <RightOutlined />
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  textAlign: "center",
                  paddingLeft: "36px",
                  paddingTop: "20px",
                  color: "#F9F9F9",
                  fontSize: "1.2rem",
                }}
              >
                <div>
                  <strong>
                    {this.props.t("wizard-roles-ready-readytext")}
                  </strong>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  users: state.usersReducer.users,
  usersStatus: state.usersReducer.status,
  saveStatus: state.companyReducer.saveStatus,
  token: state.loginReducer.token,
});
const WizardRoles = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedWizardRoles)
);
export default WizardRoles;
