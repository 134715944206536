import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllGuests,
  getAllGuestsByOffice,
  saveInvitiation,
  modifyInvitiation,
} from "../../actions/reception";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Form,
  Select,
  message,
  DatePicker,
  ConfigProvider,
  Tag,
  Popconfirm,
  Checkbox,
  Tooltip,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  EditFilled,
  SaveOutlined,
  ScheduleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import copy from "copy-to-clipboard";
import Modal from "antd/lib/modal/Modal";
import { getAllUsers, getAllHosts } from "../../actions/users";
import { getAllLanguages } from "../../actions/language";
import localehu from "antd/es/locale/hu_HU";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { getAllAddresses, getOneCompanyById, getAllCompaniesByOfficeId } from "../../actions/company";
import { getOneOfficeById } from "../../actions/office";
import Axios from "axios";
import "./reception.scss";
import {
  createReservationForGuest,
  createVehicleForGuest,
  deleteVehicle,
  getVehicles,
  updateVehicle,
  cancelReservation,
  getFreePlacesForGuest,
  reserveParking,
  getReservationForGuest,
} from "../../actions/parking";
import axios from "axios";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllGuests: (companyId) => dispatch(getAllGuests(companyId)),
    getAllGuestsByOffice: (officeId) =>
      dispatch(getAllGuestsByOffice(officeId)),
    getAllUsers: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    saveInvitiation: (params) => dispatch(saveInvitiation(params)),
    modifyInvitiation: (params) => dispatch(modifyInvitiation(params)),
    getAllLanguages: () => dispatch(getAllLanguages()),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
    getAllHosts: (companyId) => dispatch(getAllHosts(companyId)),
    getOneOfficeById: (officeId) => dispatch(getOneOfficeById(officeId)),
    getVehicles: () => dispatch(getVehicles()),
    createVehicleForGuest: (params) => dispatch(createVehicleForGuest(params)),
    updateVehicle: (id, params) => dispatch(updateVehicle(id, params)),
    deleteVehicle: (id) => dispatch(deleteVehicle(id)),
    createReservationForGuest: (params) =>
      dispatch(createReservationForGuest(params)),
    cancelReservation: (id, from) => dispatch(cancelReservation(id, from)),
    getFreePlacesForGuest: (date, companyId) =>
      dispatch(getFreePlacesForGuest(date, companyId)),
    parkingReservationFinalizer: (params) => reserveParking(params),
    getReservationForGuest: (inviteId) => getReservationForGuest(inviteId),
    getAllCompaniesByOfficeId: (officeId) => dispatch(getAllCompaniesByOfficeId(officeId))
  };
};

const { Option } = Select;

class ConnectedReceptionGuests extends Component {
  formRef = React.createRef();

  _modalLoaded = false;

  state = {
    newInviteVisible: false,
    searchText: new Date().toLocaleDateString(),
    searchedColumn: "date",
    date: null,
    endDate: null,
    message: "",
    host: "",
    detailsVisible: false,
    editVisible: false,
    selectedInvite: {},
    filteredInfo: { date: [new Date().toLocaleDateString()] },
    modify: false,
    colleagues: [],
    guestCounter: 0,
    title: "",
    shouldUpdate: true,
    address: null,
    exportVisible: false,
    start: new Date().toLocaleDateString(),
    end: new Date().toLocaleDateString(),
    selectedHostsForExport: [],
    selectedColleaguesForExport: [],
    selectedAddresses: [],
    guestFirstNameExport: "",
    guestLastNameExport: "",
    anonymizationTime: null,
    disabled: false,
    openReservationModal: false,
    licencePlateNumber: "",
    modifyLicencePlateNumberCheck: false,
    selectedId: null,
    companyId: null,
    modifyVehicle: false,
    freePlace: "",
    multipleFreePlaces: [],
    reservations: [],
    done: false,
    dates: [],
    companies: [],
  };

  componentDidMount = async () => {
    if (this.props.user.groups.some((group) => group.id === 6)) {
      await this.props.getAllGuestsByOffice(this.props.user.officeId);
    } else {
      await this.props.getAllGuests(this.props.user.companyId);
    }
    await this.props.getVehicles();
    await this.props.getAllLanguages();
    if (this.props.user.officeId) {
      await this.props.getAllCompaniesByOfficeId(this.props.user.officeId);
    } else {
      await this.props.getAllAddresses(this.props.user.companyId, null);
    }
    this.getCompanyOrOfficeAnonymizationTime();
    this.props.socket.on("updateReception", async () => {
      if (this.state.shouldUpdate) {
        await this.props.getAllGuests(this.props.user.companyId);
        if (this.props.status) {
          const filtered = this.props.invites.find(
            (invite) => invite.id === this.state.selectedInvite.id
          );
          this.setState({ selectedInvite: filtered ? filtered : {} });
        }
      }
    });
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    this.setState({ end: endDate.toLocaleDateString() });
  };

  getCompanyOrOfficeAnonymizationTime = async () => {
    if (this.props.user.companyId) {
      await this.props.getOneCompanyById(this.props.user.companyId);
      this.setState({
        anonymizationTime: this.props.user.company.anonymizationTime,
      });
    } else {
      await this.props.getOneOfficeById(this.props.user?.officeId);
      this.setState({
        anonymizationTime: this.props.office?.anonymizationTime,
      });
    }
  };

  resetFilter = () => {
    this.setState({ filteredInfo: {}, searchedColumn: null, searchText: "" });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel, type = "text") => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let date = selectedKeys[0];
      if (selectedKeys[0] === undefined || selectedKeys[0] === null) {
        date = new Date();
      }
      return (
        <div style={{ padding: 8 }}>
          {dataIndex === "date" ? (
            <div>
              <ConfigProvider locale={localehu} name="date">
                <DatePicker
                  ref={(node) => {
                    this.searchInput = node;
                  }}
                  placeholder={`${searchLabel}`}
                  value={moment(date, "YYYY-MM-DD")}
                  format={["YYYY-MM-DD", "DD-MM-YYYY"]}
                  locale={{ dateTimeFormat: "YYYY-MM-DD" }}
                  onChange={(e, text) => {
                    setSelectedKeys(
                      text ? [new Date(text).toLocaleDateString()] : []
                    );
                  }}
                  onPressEnter={() =>
                    this.handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
              </ConfigProvider>
            </div>
          ) : (
            <div>
              <Input
                ref={(node) => {
                  this.searchInput = node;
                }}
                placeholder={`${searchLabel}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  this.handleSearch(selectedKeys, confirm, dataIndex)
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          )}

          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {this.props.t("search-button-label")}
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 100 }}
            >
              {this.props.t("reset-button-label")}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (type === "text") {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return new Date(record[dataIndex])
          .toLocaleString()
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput?.select?.());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={
              type === "text"
                ? text.toString()
                : new Date(text).toLocaleDateString()
            }
          />
        </Space>
      ) : type === "date" ? (
        <Space align="center">{new Date(text).toLocaleDateString()}</Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  copyEmailsToClipboard = (record) => {
    const emails = record.guests.map((guest) => {
      return guest.email + " ";
    });
    copy(emails);
    message.success(this.props.t("reception-guests-emails-copied"), 5);
  };

  openNewInvite = async () => {
    if (!this.props.user.officeId) {
      await this.props.getAllHosts();
    }
    const date = new Date();
    if (date.getMinutes() !== 0) {
      if (date.getMinutes() > 30) {
        date.setMinutes(0);
        date.setSeconds(0);
        date.setHours(date.getHours() + 1);
      } else {
        date.setSeconds(0);
        date.setMinutes(30);
      }
    }
    const enddate = new Date();
    enddate.setHours(enddate.getHours() + 1);
    if (enddate.getMinutes() !== 0) {
      if (enddate.getMinutes() > 30) {
        enddate.setMinutes(0);
        enddate.setSeconds(0);
        enddate.setHours(enddate.getHours() + 1);
      } else {
        enddate.setSeconds(0);
        enddate.setMinutes(30);
      }
    }
    this.formRef.current.setFieldsValue({
      host: "",
      invitees: [],
      date: moment(new Date(date)),
      endDate: moment(new Date(enddate)),
    });
    this.setState({
      newInviteVisible: true,
      host: "",
      date: date,
      endDate: enddate,
      disabled: false,
      guestCounter: 0,
    });
    this._modalLoaded = true;
  };

  closeNewInvite = () => {
    this.setState(
      {
        newInviteVisible: false,
        modify: false,
        selectedInvite: {},
        disabled: false,
        colleagues: [],
        companyId: null,
        address: null,
        host: ""
      },
      () => {
        this.formRef.current.resetFields();
      }
    );
    this._modalLoaded = false;
  };

  checkDuplicateEmails = (invitees) => {
    const duplicates = invitees.map((invitee) => {
      return invitees.filter((invitee2) => invitee2.email === invitee.email)
        .length;
    });
    if (duplicates.filter((duplicate) => duplicate > 1).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  delaySubmit = async () => {
    this.setState({
      disabled: true,
    });
    setTimeout(() => this.setState({ disabled: false }), 3000);
  };

  saveInvite = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      const invitees = this.formRef.current.getFieldsValue().invitees;
      if (
        (!invitees || invitees.length === 0) &&
        this.state.colleagues.length === 0
      ) {
        message.error(this.props.t("reception-guests-guestlist-empty"), 5);
        return;
      }
      let companyIdToSave = null;
      if (this.state.companyId) {
        companyIdToSave = parseInt(this.state.companyId)
      } else {
        companyIdToSave = this.props.user.companyId
          ? this.props.user.companyId
          : this.state.companyId;
      }
      if (!this.checkDuplicateEmails(invitees)) {
        this.setState({ shouldUpdate: false });
        const inviteToSave = {
          title: this.state.title,
          invitees: invitees,
          date: this.state.date,
          end: this.state.endDate,
          host: this.state.host,
          companyId: companyIdToSave,
          message: this.state.message,
          colleagues: this.state.colleagues,
          addressId: this.state.address,
        };
        if (this.state.modify) {
          inviteToSave.inviteId = this.state.selectedInvite.id;
          await this.props.modifyInvitiation(inviteToSave);
          this.delaySubmit();
        } else {
          await this.props.saveInvitiation(inviteToSave);
        }
        if (this.props.saveStatus) {
          if (this.props.user.officeId) {
            await this.props.getAllGuestsByOffice(this.props.user.officeId)
          } else {
            await this.props.getAllGuests(this.props.user.companyId);
          }
          message.success(this.props.t("reception-guests-invite-created"), 5);
          this.closeNewInvite();
          this.delaySubmit();
          this.setState({ shouldUpdate: true, disabled: true });
        } else {
          message.error(this.props.t("reception-guests-invite-fail"), 5);
          this.setState({ disabled: false });
        }
      } else {
        message.error(
          this.props.t("reception-guests-invite-duplicateemail-fail"),
          2
        );
        this.setState({ disabled: false });
      }
    }
  };

  changeHost = (event) => {
    this.setState({ host: event });
  };

  changeCompany = async (event) => {
    this.setState({ companyId: event, address: null, host: "" });
    this.formRef.current.setFieldsValue({
      host: "",
      address: null
    });
    await this.props.getAllAddresses(event);
    await this.props.getAllHosts(event);
  };

  changeAddress = (event) => {
    this.setState({ address: event });
  };

  handleChangeDate = (event, string) => {
    const date = moment(event).toDate();
    if (date > this.state.endDate) {
      const newDate = moment(date).add(1, "hours");
      this.setState({ endDate: newDate });
      this.formRef.current.setFieldsValue({
        endDate: newDate,
      });
      this.setState({ date: date });
    } else {
      this.setState({ date: date });
    }
  };

  handleChangeEnd = (event, string) => {
    const date = moment(event).toDate();
    if (date < this.state.date) {
      const newDate = moment(this.state.date).add(1, "hours");
      this.setState({ endDate: newDate });
      this.formRef.current.setFieldsValue({
        endDate: newDate,
      });
    } else {
      this.setState({ endDate: date });
    }
  };

  handleChangeStartDate = (event, string) => {
    if (event === null) {
      this.setState({ start: new Date() });
    } else {
      const date = moment(event).toDate();
      if (date > this.state.end) {
        const newDate = moment(date).add(1, "days");
        this.setState({ end: newDate });
        this.formRef.current.setFieldsValue({
          start: newDate,
        });
        this.setState({ start: date });
      } else {
        this.setState({ start: date });
      }
    }
  };

  handleChangeEndDate = (event, string) => {
    if (event === null) {
      this.setState({ end: new Date() });
    } else {
      const date = moment(event).toDate();
      this.setState({ end: date });
      if (date < this.state.start) {
        const newDate = moment(this.state.start).add(1, "days");
        this.setState({ end: newDate });
        this.formRef.current.setFieldsValue({
          end: newDate,
        });
      } else {
        this.setState({ end: date });
      }
    }
  };

  handleChangeSelectedHostForExport = (event) => {
    this.setState({ selectedHostsForExport: event });
  };

  handleChangeSelectedColleaguesForExport = (event) => {
    this.setState({ selectedColleaguesForExport: event });
  };

  showDetails = (record) => {
    this.setState({ detailsVisible: true, selectedInvite: record });
  };

  closeDetails = () => {
    this.setState({ detailsVisible: false, selectedInvite: {} });
  };

  changeColleagues = (event) => {
    this.setState({ colleagues: event });
  };

  showEditModal = async (record) => {
    await this.props.getAllHosts();
    const colleagueIds = record.colleagues.map((colleague) => {
      return String(colleague.id);
    });
    this.setState({
      selectedInvite: record,
      modify: true,
      newInviteVisible: true,
      disabled: false,
      host: record.host ? String(record.host?.id) : null,
      date: new Date(record.date),
      endDate: new Date(record.end),
      message: record.message,
      colleagues: colleagueIds,
      guestCounter: record.guests.length,
      title: record.title,
      address: record.addressId,
    });
    this.formRef.current.setFieldsValue({
      host: record.host ? String(record.host?.id) : null,
      date: moment(new Date(record.date)),
      endDate: moment(new Date(record.end)),
      message: record.message,
      colleagues: colleagueIds,
      invitees: record.guests.map((guest) => {
        return {
          first: guest.firstName,
          last: guest.lastName,
          email: guest.email,
          company: guest.representedCompany,
          language: this.props.languages.map((l) =>
            l.id === guest.languageId ? l.code : null
          ),
        };
      }),
      title: record.title,
      address: record.addressId,
    });
    this._modalLoaded = true;
  };

  closeEdit = () => {
    this.setState({
      selectedInvite: {},
      modify: false,
      newInviteVisible: false,
      disabled: false,
    });
  };

  handleExport = async () => {
    await this.props.getAllHosts();
    this.setState({ exportVisible: true });
  };

  handleCloseExport = () => {
    const start = new Date();
    start.setMinutes(0);
    const end = new Date();
    end.setHours(end.getHours() + 1);
    end.setMinutes(0);
    this.setState({
      exportVisible: false,
      start: start,
      end: end,
      selectedHostsForExport: [],
      selectedColleaguesForExport: [],
      selectedAddresses: [],
      guestLastNameExport: "",
      guestFirstNameExport: "",
    });
  };

  changeAddresses = (event) => {
    this.setState({ selectedAddresses: event });
  };

  handleDownloadExport = async () => {
    const startDate = new Date(this.state.start);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(this.state.end);
    endDate.setHours(0, 0, 0, 0);
    await Axios.post(
      "/2.0.0/reception/export",
      {
        start: startDate.toLocaleDateString(),
        end: endDate.toLocaleDateString(),
        hosts: this.state.selectedHostsForExport,
        colleagues: this.state.selectedColleaguesForExport,
        addresses: this.state.selectedAddresses,
        guestFirstName: this.state.guestFirstNameExport,
        guestLastName: this.state.guestLastNameExport,
      },
      { responseType: "blob" }
    )
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `export_${new Date().toISOString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCloseDateTag = () => {
    this.setState({ filteredInfo: { date: null } });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  hasRightBySubscription = (notAllowedSubscriptions) => {
    if (
      !this.props.user.groups.some((group) => group.id === 6) &&
      notAllowedSubscriptions.includes(this.props.user.company.subscriptionId)
    ) {
      return false;
    }
    return true;
  };

  showReservations = async (record) => {
    await axios
      .get(`/2.0.0/parking/parking-reservations/invite/${record.id}/guests`)
      .then((response) => {
        this.setState({
          reservations: response.data,
          done: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.state.done) {
      this.setState({
        openReservationModal: true,
        selectedInvite: record,
      });

      if (
        moment(record.date).startOf("day") < moment(record.end).startOf("day")
      ) {
        const currDate = moment(record.date).startOf("day");
        const lastDate = moment(record.end).startOf("day");
        currDate.subtract(1, "days");
        while (currDate.add(1, "days").diff(lastDate) < 86400000) {
          await this.props.getFreePlacesForGuest(
            currDate.format("YYYY.MM.DD"),
            this.props.user.companyId
          );
          this.setState({
            multipleFreePlaces: [
              this.props.places,
              ...this.state.multipleFreePlaces,
            ],
            dates: [currDate.format("YYYY.MM.DD"), ...this.state.dates],
          });
        }
      } else {
        await this.props.getFreePlacesForGuest(
          record.date,
          this.props.user.companyId
        );
        this.setState({
          freePlace: this.props.places,
        });
      }
    }
  };

  closeReservations = () => {
    this.setState({
      openReservationModal: false,
      selectedInvite: {},
      freePlace: "",
      multipleFreePlaces: [],
      done: false,
      dates: [],
    });
  };

  createLincencePlateNumber = async (record) => {
    if (this.state.modifyVehicle === true) {
      let modifyVehicle = this.props.vehicle?.filter((v) =>
        v.guestId === record.id ? v.id : ""
      );
      const toSend = {
        licensePlateNumber: this.state.licencePlateNumber,
        active: true,
      };
      this.setState({
        modifyVehicle: false,
      });
      await this.props.updateVehicle(modifyVehicle[0].id, toSend);
    } else {
      const toSend = {
        guestId: record.id,
        licensePlateNumber: this.state.licencePlateNumber,
      };
      await this.props.createVehicleForGuest(toSend);
    }
    await this.props.getVehicles();
    this.setState({
      modifyLicencePlateNumberCheck: false,
    });
  };

  openCreate = async (record) => {
    this.setState({
      modifyVehicle: false,
      selectedId: record.id,
      licencePlateNumber: "",
      modifyLicencePlateNumberCheck: true,
    });
  };

  openModify = async (record) => {
    this.setState({
      selectedId: record.id,
      modifyVehicle: true,
      modifyLicencePlateNumberCheck: true,
    });
  };

  cancelCreateLicencePlateNumber = async () => {
    this.setState({
      modifyLicencePlateNumberCheck: false,
      licencePlateNumber: "",
      modifyVehicle: false,
    });
  };

  deleteVehicle = async (record) => {
    let deleteVehicle = this.props.vehicle?.filter((v) =>
      v.guestId === record.id ? v.id : ""
    );
    await this.props.deleteVehicle(deleteVehicle[0].id);
    await this.props.getVehicles();
  };

  saveReservations = async () => {
    const toSend = {
      inviteId: this.state.selectedInvite.id,
    };
    axios
      .post("/2.0.0/parking/reserve-parking/guests/finalize-parking", toSend)
      .then(this.closeReservations)
      .catch((err) => {
        message.error(this.props.t("reservation-error-message"));
        console.log(err);
      });
  };

  reserveParking = async (event, record) => {
    if (
      moment(this.state.selectedInvite.date).startOf("day") <
      moment(this.state.selectedInvite.end).startOf("day")
    ) {
      this.setState({ multipleFreePlaces: [] });
      if (event.target.checked) {
        const toSend = {
          guestId: parseInt(event.currentTarget.id),
          date: moment(event.currentTarget.value),
        };

        await this.props.createReservationForGuest(toSend);
        const currDate = moment(this.state.selectedInvite.date).startOf("day");
        const lastDate = moment(this.state.selectedInvite.end).startOf("day");
        currDate.subtract(1, "days");
        while (currDate.add(1, "days").diff(lastDate) < 86400000) {
          await this.props.getFreePlacesForGuest(
            currDate.format("YYYY.MM.DD"),
            this.props.user.companyId
          );
          this.setState({
            multipleFreePlaces: [
              this.props.places,
              ...this.state.multipleFreePlaces,
            ],
          });
        }
      } else {
        await this.props.cancelReservation(
          event.currentTarget.id,
          moment(event.currentTarget.value).format("YYYY.MM.DD")
        );
        const currDate = moment(this.state.selectedInvite.date).startOf("day");
        const lastDate = moment(this.state.selectedInvite.end).startOf("day");
        currDate.subtract(1, "days");
        while (currDate.add(1, "days").diff(lastDate) < 86400000) {
          await this.props.getFreePlacesForGuest(
            currDate.format("YYYY.MM.DD"),
            this.props.user.companyId
          );
          this.setState({
            multipleFreePlaces: [
              this.props.places,
              ...this.state.multipleFreePlaces,
            ],
          });
        }
      }
    } else if (event.target.checked) {
      const toSend = {
        guestId: record.id,
        date: moment(this.state.selectedInvite.date).format("YYYY.MM.DD"),
      };
      await this.props.createReservationForGuest(toSend);
      await this.props.getFreePlacesForGuest(
        moment(this.state.selectedInvite.date).format("YYYY.MM.DD"),
        this.props.user.companyId
      );
    } else {
      const from = this.state.selectedInvite.date;
      await this.props.cancelReservation(
        record.id,
        moment(from).format("YYYY.MM.DD")
      );
      await this.props.getFreePlacesForGuest(
        this.state.selectedInvite.date,
        this.props.user.companyId
      );
    }

    this.setState({
      freePlace: this.props.places,
    });

    await axios
      .get(
        `/2.0.0/parking/parking-reservations/invite/${this.state.selectedInvite.id}/guests`
      )
      .then((response) => {
        this.setState({
          reservations: response.data,
          done: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    moment.updateLocale("en", {
      week: {
        dow: 8,
      },
      weekdaysMin: [
        this.props.t("Sun"),
        this.props.t("Mon"),
        this.props.t("Tue"),
        this.props.t("Wed"),
        this.props.t("Thu"),
        this.props.t("Fri"),
        this.props.t("Sat"),
      ],
      monthsShort: [
        this.props.t("Jan"),
        this.props.t("Feb"),
        this.props.t("Mar"),
        this.props.t("Apr"),
        this.props.t("May"),
        this.props.t("Jun"),
        this.props.t("Jul"),
        this.props.t("Aug"),
        this.props.t("Sep"),
        this.props.t("Oct"),
        this.props.t("Nov"),
        this.props.t("Dec"),
      ],
    });

    const guestColumns = [
      {
        title: this.props.t("reception-events-table-header-name"),
        key: "name",
        render: (text, record) =>
          this.state.anonymizationTime > 0 &&
            this.state.selectedInvite.date <
            moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">{`${record.lastName} ${record.firstName}`}</Space>
          ),
      },
      {
        title: this.props.t("reception-guests-email-title-label"),
        key: "email",
        dataIndex: "email",
        render: (text, record) =>
          this.state.anonymizationTime > 0 &&
            this.state.selectedInvite.date <
            moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">{`${record.email}`}</Space>
          ),
      },
      {
        title: this.props.t("messages-table-header-company"),
        key: "company",
        dataIndex: "representedCompany",
        render: (text, record) =>
          this.state.anonymizationTime > 0 &&
            this.state.selectedInvite.date <
            moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">{`${record.representedCompany}`}</Space>
          ),
      },
      {
        title: this.props.t("reception-guests-table-header-arrived"),
        key: "arrived",
        render: (text, record) => (
          <Space size="middle">
            {record.arrived ? <CheckCircleFilled /> : <CloseCircleFilled />}
          </Space>
        ),
      },
      {
        title: this.props.t("reception-guests-table-header-arrivetime"),
        key: "arriveTime",
        render: (text, record) => (
          <Space size="middle">
            {record.arrived
              ? new Date(parseInt(record.arriveTime)).toLocaleString()
              : this.props.t("hasnt-arrived-yet")}
          </Space>
        ),
      },
    ];

    const colleagueColumns = [
      {
        title: this.props.t("reception-events-table-header-name"),
        key: "name",
        render: (text, record) => (
          <Space size="middle">{`${record.lastName} ${record.firstName}`}</Space>
        ),
      },
      {
        title: this.props.t("reception-guests-email-title-label"),
        key: "email",
        dataIndex: "email",
      },
    ];

    const columns = [
      {
        title: this.props.t("reception-guests-date-title-label"),
        key: "date",
        dataIndex: "date",
        filteredValue: this.state.filteredInfo.date || null,
        render: (text, record) => (
          <Space size="middle">{new Date(record.date)}</Space>
        ),
        ...this.getColumnSearchProps(
          "date",
          this.props.t("reception-guests-date-title-label"),
          "date"
        ),
      },
      {
        title: this.props.t("reception-events-table-header-time"),
        key: "time",
        dataIndex: "time",
        render: (text, record) => {
          const date = new Date(record.date);
          return (
            <Space size="middle">{`${date.getHours()}:${date.getMinutes() < 10 ? "0" : ""
              }${date.getMinutes()}`}</Space>
          );
        },
      },
      {
        title: this.props.t("reception-guests-table-header-host"),
        key: "host",
        dataIndex: "host",
        render: (text, record) => (
          <Space size="middle">
            {record.host
              ? record.host.lastName + " " + record.host.firstName
              : this.props.t("reception-guests-table-header-nohost")}
          </Space>
        ),
      },
      {
        title: this.props.t("reception-guests-table-header-colleagues"),
        key: "colleague",
        dataIntex: "colleague",
        render: (text, record) => (
          <Space size="middle">
            {record.colleagues.map((colleague, index) => {
              let name = "";
              if (colleague.firstName) {
                name += colleague.lastName + " " + colleague.firstName;
              }
              return (
                <div key={colleague.id}>
                  {name}
                  {index !== record.colleagues.length - 1 ? ", " : ""}
                </div>
              );
            })}
          </Space>
        ),
      },
      {
        title: this.props.t("reception-guests-table-header-guest"),
        key: "invitee",
        dataIntex: "invitee",
        render: (text, record) =>
          this.state.anonymizationTime > 0 &&
            record.date < moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">
              {record.guests.length < 2
                ? record.guests.map((guest, index) => {
                  let name = "";
                  if (guest.firstName) {
                    name += guest.lastName + " " + guest.firstName;
                  }
                  if (guest.representedCompany) {
                    name += " - " + guest.representedCompany;
                  }
                  return (
                    <div key={guest.id}>
                      {name}
                      {guest.arrived && (
                        <>
                          {" "}
                          <CheckCircleFilled />
                        </>
                      )}
                      {index !== record.guests.length - 1 ? ", " : ""}
                    </div>
                  );
                })
                : record.guests[0].lastName +
                " " +
                record.guests[0].firstName +
                " - " +
                record.guests[0].representedCompany +
                ", " +
                record.guests[1].lastName +
                " " +
                record.guests[1].firstName +
                " - " +
                record.guests[1].representedCompany +
                ", " +
                "..."}
            </Space>
          ),
      },
      {
        title: this.props.t("details-button-label"),
        key: "details",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.showDetails(record)}>
                {this.props.t("details-button-label")}
              </Button>
            </Space>
          );
        },
      },
      {
        title: this.props.t(""),
        key: "reservation",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.showReservations(record)}>
                <ScheduleOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];
    const reservationColumns = [
      {
        title: this.props.t("reception-events-table-header-name"),
        key: "name",
        render: (text, record) =>
          this.state.anonymizationTime > 0 &&
            this.state.selectedInvite.date <
            moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">{`${record.lastName} ${record.firstName} (${record.representedCompany})`}</Space>
          ),
      },
      {
        title: this.props.t("reception-guests-email-title-label"),
        key: "email",
        dataIndex: "email",
        render: (text, record) =>
          this.state.anonymizationTime > 0 &&
            this.state.selectedInvite.date <
            moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">{`${record.email}`}</Space>
          ),
      },
      {
        title: this.props.t("licensePlateNumber"),
        key: "licencePlateNumber",
        render: (text, record) => {
          let licencePlateNumber = this.props.vehicle?.map((v) =>
            v.guestId === record.id ? v.licencePlateNumber : "a"
          );
          const filter = licencePlateNumber.filter((r) => r !== "a");
          return this.state.modifyLicencePlateNumberCheck === false ? (
            <Space>
              {filter.length > 0 ? (
                <Space>
                  {filter}
                  <Button onClick={() => this.openModify(record)}>
                    <EditFilled />
                  </Button>
                  <Popconfirm
                    title={this.props.t("vehicle-delete-popup-message")}
                    okText={this.props.t("yes-button-label")}
                    cancelText={this.props.t("no-button-label")}
                    onConfirm={() => this.deleteVehicle(record)}
                  >
                    <Button danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Space>
              ) : (
                <Space>
                  <Button onClick={() => this.openCreate(record)}>
                    <EditFilled />
                  </Button>
                </Space>
              )}
            </Space>
          ) : record.id === this.state.selectedId ? (
            <Space>
              <Input
                style={{
                  width: "6em",
                }}
                value={this.state.licencePlateNumber}
                name="licencePlateNumber"
                onChange={this.handleChange}
              />
              <Button
                onClick={() => this.cancelCreateLicencePlateNumber(record)}
              >
                {this.props.t("cancel-button-label")}
              </Button>
              <Button onClick={() => this.createLincencePlateNumber(record)}>
                {this.props.t("save-button-label")}
              </Button>
            </Space>
          ) : (
            <Space>
              {filter.length > 0 ? (
                <Space>
                  {filter}
                  <Button onClick={() => this.openModify(record)}>
                    <EditFilled />
                  </Button>
                  <Popconfirm
                    title={this.props.t("vehicle-delete-popup-message")}
                    okText={this.props.t("yes-button-label")}
                    cancelText={this.props.t("no-button-label")}
                    onConfirm={() => this.deleteVehicle(record)}
                  >
                    <Button danger>
                      {this.props.t("delete-button-label")}
                    </Button>
                  </Popconfirm>
                </Space>
              ) : (
                <Space>
                  <Button onClick={() => this.openCreate(record)}>
                    <EditFilled />
                  </Button>
                </Space>
              )}
            </Space>
          );
        },
      },
    ];

    if (
      moment(this.state.selectedInvite.date).startOf("day") <
      moment(this.state.selectedInvite.end).startOf("day")
    ) {
      this.state.dates.forEach((date, i) => {
        reservationColumns.push({
          title:
            moment(date).weekday() === 0
              ? moment(date).format("YYYY.MM.DD") +
              " (" +
              this.props.t("Mon") +
              ")"
              : moment(date).weekday() === 1
                ? moment(date).format("YYYY.MM.DD") +
                " (" +
                this.props.t("Tue") +
                ")"
                : moment(date).weekday() === 2
                  ? moment(date).format("YYYY.MM.DD") +
                  " (" +
                  this.props.t("Wed") +
                  ")"
                  : moment(date).weekday() === 3
                    ? moment(date).format("YYYY.MM.DD") +
                    " (" +
                    this.props.t("Thu") +
                    ")"
                    : moment(date).weekday() === 4
                      ? moment(date).format("YYYY.MM.DD") +
                      " (" +
                      this.props.t("Fri") +
                      ")"
                      : moment(date).weekday() === 5
                        ? moment(date).format("YYYY.MM.DD") +
                        " (" +
                        this.props.t("Sat") +
                        ")"
                        : moment(date).format("YYYY.MM.DD") +
                        " (" +
                        this.props.t("Sun") +
                        ")",
          dataIndex: "day",
          key: date,
          render: (text, record) => {
            let decider = [];
            const reservations = this.state.reservations.filter(
              (reservation) => reservation
            );
            const parkingReservationArray = reservations.map(
              (pr) => pr.parkingReservations
            );
            if (parkingReservationArray.length > 0) {
              const parkingReservations = parkingReservationArray[0].map(
                (pr) => pr
              );
              const eldonto = parkingReservations.map((r) =>
                r.guestId === record.id
                  ? moment(r.from).startOf("day").format("YYYY.MM.DD") === date
                    ? true
                    : false
                  : false
              );
              decider = [eldonto, ...decider];
            }
            return (
              <Space size="middle">
                <input
                  type="checkbox"
                  checked={
                    decider[0] !== undefined && decider[0].length !== 0
                      ? decider[0].includes(true)
                      : false
                  }
                  disabled={
                    this.props.places.freeParkingCount === 0 ||
                    moment(date).endOf("day").diff(moment().startOf("day")) < 0
                  }
                  onChange={(event) => this.reserveParking(event, record)}
                  id={record.id}
                  value={date}
                />
              </Space>
            );
          },
        });
      });
    } else {
      const form = moment(this.state.selectedInvite.date).format("YYYY.MM.DD");
      const currDate = moment(this.state.selectedInvite.date).weekday();
      reservationColumns.push({
        title:
          currDate === 0
            ? form + " (" + this.props.t("Mon") + ")"
            : currDate === 1
              ? form + " (" + this.props.t("Tue") + ")"
              : currDate === 2
                ? form + " (" + this.props.t("Wed") + ")"
                : currDate === 3
                  ? form + " (" + this.props.t("Thu") + ")"
                  : currDate === 4
                    ? form + " (" + this.props.t("Fri") + ")"
                    : currDate === 5
                      ? form + " (" + this.props.t("Sat") + ")"
                      : form + " (" + this.props.t("Sun") + ")",
        key: "day",
        render: (text, record) => {
          let decider = false;
          const reservations = this.state.reservations.filter(
            (reservation) => reservation
          );
          const parkingReservationArray = reservations.map(
            (pr) => pr.parkingReservations
          );
          if (parkingReservationArray.length > 0) {
            const parkingReservations = parkingReservationArray[0].map(
              (pr) => pr
            );
            decider = parkingReservations?.map(
              (reservation) =>
                reservation.guestId === record.id &&
                moment(reservation.from).startOf("day").format("YYYY.MM.DD") ===
                moment(this.state.selectedInvite.date)
                  .startOf("day")
                  .format("YYYY.MM.DD")
            );
          }

          return (
            <Space size="middle">
              <Checkbox
                checked={decider[0]}
                disabled={
                  this.props.places.freeParkingCount === 0 ||
                  moment(this.state.selectedInvite.date)
                    .endOf("day")
                    .diff(moment().startOf("day")) < 0
                }
                onChange={(event) => this.reserveParking(event, record)}
              />
            </Space>
          );
        },
      });
    }

    const freePlacesColumns = [
      { title: "", dataIndex: "", key: "" },
      { title: "", dataIndex: "", key: "" },
      {
        title: "",
        dataIndex: "freePlaces",
        key: "freePlaces",
        render: () => {
          return this.props.t("free-places-counter-label");
        },
      },
    ];

    if (
      moment(this.state.selectedInvite.date).startOf("day") <
      moment(this.state.selectedInvite.end).startOf("day")
    ) {
      this.state.multipleFreePlaces.forEach((multipleFreePlaces, i) => {
        freePlacesColumns.push({
          title: "",
          key: multipleFreePlaces,
          render: (row) => {
            return multipleFreePlaces.freeParkingCount;
          },
        });
      });
    } else {
      freePlacesColumns.push({
        dataIndex: "freeParkingCount",
        key: "freePlaces",
      });
    }

    this.hasRightBySubscription([1, 4, 7]) &&
      columns.push({
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => {
          return this.state.anonymizationTime > 0 &&
            record.date <
            moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">
              <Button onClick={() => this.showEditModal(record)}>
                <EditFilled />
              </Button>
            </Space>
          );
        },
      });

    this.hasRightBySubscription([1, 4, 7]) &&
      columns.push({
        title: this.props.t("reception-guests-table-header-emailaddresses"),
        key: "emails",
        render: (text, record) => {
          return this.state.anonymizationTime > 0 &&
            record.date <
            moment().add(-this.state.anonymizationTime, "days") ? (
            []
          ) : (
            <Space size="middle">
              <Button onClick={() => this.copyEmailsToClipboard(record)}>
                {this.props.t("copy-button-label")}
              </Button>
            </Space>
          );
        },
      });

    return (
      <div>
        {this.hasRightBySubscription([1, 4, 7]) && (
          <Row>
            <Col
              span={24}
              style={{
                padding: "0.5em",
              }}
            >
              <div style={{ float: "right" }}>
                <Button onClick={this.handleExport}>Export</Button>
                  <Button
                    type="primary"
                    onClick={this.openNewInvite}
                    style={{ marginLeft: "1em" }}
                  >
                    <PlusOutlined />
                    {this.props.t("reception-guests-newinvite-button-label")}
                  </Button>
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <div style={{ padding: "0.5em" }}>
              <Button onClick={this.resetFilter}>
                {this.props.t("reception-guests-resetfilters-button-label")}
              </Button>
            </div>
            <div style={{ padding: "0.5em" }}>
              {this.state.filteredInfo.date !== null &&
                this.state.filteredInfo.date !== undefined && (
                  <Tag
                    closable
                    onClose={this.handleCloseDateTag}
                    className="reception-search-tag"
                  >
                    {this.state.filteredInfo.date[0]}
                  </Tag>
                )}
            </div>
            <div style={{ padding: "0.5em" }}>
              <Table
                columns={columns}
                rowKey={"id"}
                dataSource={this.props.invites}
                locale={{
                  emptyText:
                    this.props.invites.length === 0
                      ? this.props.t("empty-text")
                      : this.props.t("reception-guests-nofiltereddata"),
                }}
                pagination={{
                  position: ["bottomCenter"],
                  pageSize: 10,
                  showSizeChanger: false,
                }}
                onChange={this.handleChangeTable}
                size="small"
              />
            </div>
          </Col>
        </Row>
        <Modal
          visible={this.state.newInviteVisible}
          onCancel={this.closeNewInvite}
          title={this.props.t("reception-guests-newinvite-button-label")}
          maskClosable={false}
          centered={true}
          width={900}
          onOk={this.saveInvite}
          okText={
            <>
              <SaveOutlined /> {this.props.t("save-button-label")}
            </>
          }
          okButtonProps={{ disabled: this.state.disabled }}
          forceRender={true}
          bodyStyle={{ overflowY: "auto", maxHeight: "700px" }}
          cancelText={this.props.t("cancel-button-label")}
        >
          <div>
            <Form
              ref={(ref) => {
                this.formRef.current = ref;
                this._modalLoaded = false;
              }}
            >
              <Form.Item
                name={"title"}
                fieldKey={"title"}
                label={this.props.t("reception-guests-invite-title-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("reception-guests-invite-title-text"),
                  },
                ]}
              >
                <Input
                  style={{ width: "50em", float: "right", marginRight: "3em" }}
                  value={this.state.title}
                  name="title"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <ConfigProvider locale={localehu} name="date">
                <Form.Item
                  name={"date"}
                  fieldKey={"date"}
                  label={this.props.t("reception-guests-date-title-label")}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("reception-guests-date-title-text"),
                    },
                  ]}
                >
                  <DatePicker
                    mode="date"
                    showTime
                    onChange={this.handleChangeDate}
                    name="date"
                    locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
                    minuteStep={5}
                    showNow={false}
                    showSecond={false}
                    format="YYYY-MM-DD HH:mm"
                    defaultPickerValue={() => {
                      const date = new Date();
                      if (date.getMinutes() !== 0) {
                        if (date.getMinutes() > 30) {
                          date.setMinutes(0);
                          date.setSeconds(0);
                          date.setHours(date.getHours() + 1);
                        } else {
                          date.setMinutes(30);
                          date.setSeconds(0);
                        }
                      }
                      return moment(date);
                    }}
                    value={moment(this.state.date).format("YYYY-MM-DD HH:mm")}
                    style={{
                      width: "50em",
                      float: "right",
                      marginRight: "3em",
                    }}
                  />
                </Form.Item>
              </ConfigProvider>
              <ConfigProvider locale={localehu} name="endDate">
                <Form.Item
                  name={"endDate"}
                  fieldKey={"endDate"}
                  label={this.props.t("reception-guests-dateEnd-title-label")}
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "reception-guests-dateEnd-title-text"
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    mode="date"
                    showTime
                    onChange={this.handleChangeEnd}
                    locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
                    name="endDate"
                    minuteStep={5}
                    showNow={false}
                    showSecond={false}
                    format="YYYY-MM-DD HH:mm"
                    defaultPickerValue={() => {
                      const date = new Date();
                      date.setHours(date.getHours() + 1);
                      if (date.getMinutes() !== 0) {
                        if (date.getMinutes() > 30) {
                          date.setMinutes(0);
                          date.setSeconds(0);
                          date.setHours(date.getHours() + 1);
                        } else {
                          date.setMinutes(30);
                          date.setSeconds(0);
                        }
                      }
                      return moment(date);
                    }}
                    value={moment(this.state.endDate).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                    style={{
                      width: "50em",
                      float: "right",
                      marginRight: "3em",
                    }}
                  />
                </Form.Item>
              </ConfigProvider>
              {this.props.user.officeId && (
                <div>
                  <Form.Item name={"companyId"}
                    fieldKey={"companyId"}
                    label={this.props.t("guest-register-company-label")}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("guest-register-company-text"),
                      },
                    ]}>
                    <Select
                      value={this.props.companyId}
                      onChange={this.changeCompany}
                      style={{
                        width: "50em",
                        float: "right",
                        marginRight: "3em",
                      }}
                    >
                      {this.props.companies.map((company) => {
                        return (
                          <Option key={company.id}>
                            {company.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={"address"}
                    fieldKey={"address"}
                    label={this.props.t("reception-address-label")}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-address-text"
                        ),
                      },
                    ]}
                  >
                    <Select
                      value={this.state.address}
                      disabled={this.state.companyId === null}
                      onChange={this.changeAddress}
                      style={{
                        width: "50em",
                        float: "right",
                        marginRight: "3em",
                      }}
                    >
                      {this.props.addresses
                        .filter((address) => address.isAbleToInvite === true)
                        .map((address) => {
                          return (
                            <Option key={address.id} value={address.id}>
                              {address.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={"host"}
                    fieldKey={"host"}
                    label={this.props.t("reception-guests-host-title-label")}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("reception-guests-host-title-text"),
                      },
                    ]}
                  >
                    <Select
                      value={this.state.host}
                      disabled={this.state.companyId === null}
                      onChange={this.changeHost}
                      style={{ width: "50em", float: "right", marginRight: "3em" }}
                    >
                      {this.props.hosts.map((host) => {
                        return (
                          <Option key={host.id}>
                            {host.lastName + " " + host.firstName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {!this.props.user.officeId && (
                <div>
                  <Form.Item
                    name={"host"}
                    fieldKey={"host"}
                    label={this.props.t("reception-guests-host-title-label")}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("reception-guests-host-title-text"),
                      },
                    ]}
                  >
                    <Select
                      value={this.state.host}
                      onChange={this.changeHost}
                      style={{ width: "50em", float: "right", marginRight: "3em" }}
                    >
                      {this.props.hosts.map((host) => {
                        return (
                          <Option key={host.id}>
                            {host.lastName + " " + host.firstName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"address"}
                    fieldKey={"address"}
                    label={this.props.t("reception-address-label")}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-address-text"
                        ),
                      },
                    ]}
                  >
                    <Select
                      value={this.state.address}
                      onChange={this.changeAddress}
                      style={{
                        width: "50em",
                        float: "right",
                        marginRight: "3em",
                      }}
                    >
                      {this.props.addresses
                        .filter((address) => address.isAbleToInvite === true)
                        .map((address) => {
                          return (
                            <Option key={address.id} value={address.id}>
                              {address.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              )}

              <Form.Item
                name={"message"}
                fieldKey={"message"}
                label={this.props.t("messages-content-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("messages-content-text"),
                  },
                ]}
              >
                <Input.TextArea
                  style={{ width: "50em", float: "right", marginRight: "3em" }}
                  rows={8}
                  value={this.state.message}
                  name="message"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name={"colleagues"}
                fieldKey={"colleagues"}
                label={this.props.t("reception-guests-colleagues-title-label")}
                rules={[
                  {
                    required: this.state.guestCounter === 0 ? true : false,
                    message: this.props.t(
                      "reception-guests-colleagues-title-text"
                    ),
                  },
                ]}
              >
                <Select
                  value={this.state.colleagues}
                  onChange={this.changeColleagues}
                  mode="multiple"
                  style={{ width: "50em", float: "right", marginRight: "3em" }}
                >
                  {this.props.users
                    .filter((user) => String(user.id) !== this.state.host)
                    .map((user) => {
                      return (
                        <Option key={user.id}>
                          {user.lastName + " " + user.firstName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="invitees"
                style={{ width: "50em", position: "relative", left: "7.9em" }}
              >
                <Form.List name="invitees">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                              justifyContent: "space-between",
                            }}
                            align="start"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "last"]}
                              fieldKey={[field.fieldKey, "last"]}
                              rules={[
                                {
                                  required: false,
                                  message: this.props.t(
                                    "reception-guests-lastname-title-text"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={this.props.t(
                                  "reception-guests-lastname-title-label"
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "first"]}
                              fieldKey={[field.fieldKey, "first"]}
                              rules={[
                                {
                                  required: false,
                                  message: this.props.t(
                                    "reception-guests-firstname-title-text"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={this.props.t(
                                  "reception-guests-firstname-title-label"
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "email"]}
                              fieldKey={[field.fieldKey, "email"]}
                              rules={[
                                {
                                  required: true,
                                  message: this.props.t(
                                    "reception-guests-email-title-text"
                                  ),
                                },
                                {
                                  type: "email",
                                  message: this.props.t(
                                    "reception-guests-email-title-text-format"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={this.props.t(
                                  "reception-guests-email-title-label"
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "company"]}
                              fieldKey={[field.fieldKey, "company"]}
                              rules={[
                                {
                                  required: false,
                                  message: this.props.t(
                                    "reception-guests-company-title-text"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={this.props.t(
                                  "reception-guests-company-title-label"
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "language"]}
                              fieldKey={[field.fieldKey, "language"]}
                              initialValue={
                                this.props.languages.find(
                                  (language) =>
                                    language.id ===
                                    (this.props.user.company === null
                                      ? null
                                      : this.props.user.company
                                        .defaultLanguageId)
                                )?.code
                              }
                            >
                              <Select name="language">
                                {this.props.languages.map((language) => {
                                  return (
                                    <Option
                                      key={language.id}
                                      value={language.code}
                                    >
                                      {language.code}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>

                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                                this.setState({
                                  guestCounter: this.state.guestCounter - 1,
                                });
                              }}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            style={{
                              width: "50em",
                              position: "relative",
                              left: "0",
                            }}
                            type="dashed"
                            onClick={() => {
                              add();
                              this.setState({
                                guestCounter: this.state.guestCounter + 1,
                              });
                            }}
                            block
                          >
                            <PlusOutlined />{" "}
                            {this.props.t(
                              "reception-guests-addguest-button-label"
                            )}
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          visible={this.state.detailsVisible}
          onCancel={this.closeDetails}
          title={this.props.t("details-button-label")}
          maskClosable={false}
          centered={true}
          width={1200}
          forceRender={true}
          bodyStyle={{ overflowY: "auto", maxHeight: "700px" }}
          footer={null}
        >
          <Row style={{ padding: "1em" }}>
            <Col span={24}>
              <div style={{ display: "flex" }}>
                <div>
                  <strong>
                    {this.props.t("reception-guests-invite-title-label")}:
                  </strong>
                  &nbsp;
                </div>
                <div>{this.state.selectedInvite.title}</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <strong>{this.props.t("guest-register-host-label")}:</strong>
                  &nbsp;
                </div>
                <div>{`${this.state.selectedInvite.host?.lastName} ${this.state.selectedInvite.host?.firstName}`}</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <strong>
                    {this.props.t("reception-events-table-header-time")}:
                  </strong>
                  &nbsp;
                </div>
                <div>{`${new Date(
                  this.state.selectedInvite.date
                ).toLocaleString()}`}</div>
              </div>
            </Col>
          </Row>
          <Row style={{ padding: "1em" }}>
            <Col span={24}>
              <strong>{this.props.t("reception-main-guests-label")}:</strong>
              <Table
                dataSource={this.state.selectedInvite.guests}
                rowKey="id"
                columns={guestColumns}
                pagination={{
                  position: ["bottomCenter"],
                  pageSize: 10,
                  showSizeChanger: false,
                }}
                locale={{ emptyText: this.props.t("empty-text") }}
                size="small"
              />
            </Col>
          </Row>
          <Row style={{ padding: "1em" }}>
            <Col span={24}>
              <strong>
                {this.props.t("reception-guests-table-header-colleagues")}:
              </strong>
              <Table
                dataSource={this.state.selectedInvite.colleagues}
                rowKey="id"
                columns={colleagueColumns}
                locale={{ emptyText: this.props.t("empty-text") }}
                pagination={{
                  position: ["bottomCenter"],
                  pageSize: 10,
                  showSizeChanger: false,
                }}
                size="small"
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          visible={this.state.exportVisible}
          onCancel={this.handleCloseExport}
          title={<strong>{this.props.t("export-button-label")}</strong>}
          maskClosable={false}
          centered={true}
          width={600}
          forceRender={true}
          footer={null}
        >
          <div>
            <label>
              <strong>{this.props.t("reception-export-start-date")}</strong>
            </label>
            <DatePicker
              mode="date"
              showTime={false}
              onChange={this.handleChangeStartDate}
              name="start"
              showNow={false}
              showSecond={false}
              locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
              format="YYYY-MM-DD"
              value={moment(this.state.start)}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>{this.props.t("reception-export-end-date")}</strong>
            </label>
            <DatePicker
              mode="date"
              showTime={false}
              onChange={this.handleChangeEndDate}
              name="end"
              showNow={false}
              showSecond={false}
              locale={{ dateTimeFormat: "YYYY-MM-DD HH:mm" }}
              format="YYYY-MM-DD"
              value={moment(this.state.end)}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>
                {this.props.t("reception-guests-table-header-host")}:
              </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              value={this.state.selectedHostsForExport}
              onChange={this.handleChangeSelectedHostForExport}
            >
              {this.props.hosts.map((host) => {
                return (
                  <Select.Option key={host.id} value={host.id}>
                    {host.lastName + " " + host.firstName}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>{this.props.t("company-admin-sites-panel")}:</strong>
            </label>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              value={this.state.selectedAddresses}
              onChange={this.changeAddresses}
            >
              {this.props.addresses.map((address) => {
                return (
                  <Select.Option key={address.id} value={address.id}>
                    {address.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>
                {this.props.t("reception-guests-table-header-colleagues")}:
              </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              value={this.state.selectedColleaguesForExport}
              onChange={this.handleChangeSelectedColleaguesForExport}
            >
              {this.props.users.map((user) => {
                return (
                  <Select.Option key={user.id} value={user.id}>
                    {user.lastName + " " + user.firstName}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>
                {this.props.t("reception-guests-table-header-guest") +
                  " " +
                  this.props.t("guest-register-lastname-label")}
                :
              </strong>
            </label>
            <Input
              name="guestLastNameExport"
              value={this.state.guestLastNameExport}
              onChange={this.handleChange}
              allowClear
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <label>
              <strong>
                {this.props.t("reception-guests-table-header-guest") +
                  " " +
                  this.props.t("guest-register-firstname-label")}
                :
              </strong>
            </label>
            <Input
              name="guestFirstNameExport"
              value={this.state.guestFirstNameExport}
              onChange={this.handleChange}
              allowClear
            />
          </div>
          <div style={{ textAlign: "right", marginTop: "2em" }}>
            <Button
              onClick={this.handleCloseExport}
              style={{ marginRight: "1em" }}
            >
              {this.props.t("back-button-label")}
            </Button>
            <Button onClick={this.handleDownloadExport} type="primary">
              {this.props.t("export-button-label")}
            </Button>
          </div>
        </Modal>
        <Modal
          visible={this.state.openReservationModal}
          onCancel={this.closeReservations}
          title={
            <>
              {this.props.t("parking-wanted-label")}{" "}
              <Tooltip
                title={this.props.t("guest-parking-reservation-explanation")}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }
          maskClosable={false}
          centered={true}
          width={1300}
          forceRender={true}
          footer={
            <Space size="middle">
              <Button onClick={this.closeReservations}>
                {this.props.t("cancel-button-label")}
              </Button>
              <Button type="primary" onClick={this.saveReservations}>
                {this.props.t("save-button-label")}
              </Button>
            </Space>
          }
        >
          <Row>
            <Table
              dataSource={this.state.selectedInvite.guests}
              rowKey="id"
              columns={reservationColumns}
              pagination={false}
              locale={{ emptyText: this.props.t("empty-text") }}
              size="small"
            />
            <Table
              dataSource={
                this.state.multipleFreePlaces.length > 0
                  ? [this.state.multipleFreePlaces.freeParkingCount]
                  : [this.state.freePlace]
              }
              rowKey="id"
              columns={freePlacesColumns}
              pagination={false}
              locale={{ emptyText: " " }}
              size="small"
            />
          </Row>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  invites: state.receptionReducer.invites,
  status: state.receptionReducer.status,
  saveStatus: state.receptionReducer.saveStatus,
  users: state.usersReducer.users,
  languages: state.languageReducer.languages,
  addresses: state.companyReducer.addresses,
  company: state.companyReducer.company,
  hosts: state.usersReducer.hosts,
  office: state.officeReducer.office,
  vehicle: state.parkingReducer.vehicle,
  places: state.parkingReducer.places,
  companies: state.companyReducer.companies,
});
const ReceptionGuests = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedReceptionGuests)
);
export default ReceptionGuests;
