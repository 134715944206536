import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
  Transfer,
  InputNumber,
  Select,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addUsersToAddress,
  deleteCompanyAddress,
  getAddressById,
  getAllCompaniesByOfficeId,
  modifyCompanyAddress,
  saveCompanyAddress,
} from "../../actions/company";
import { withTranslation } from "react-i18next";
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { getAllUsers, getAllUsersByOffice } from "../../actions/users";
import { getAllOfficeAddresses } from "../../actions/office";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOfficeAddresses: (officeId) =>
      dispatch(getAllOfficeAddresses(officeId)),
    saveCompanyAddress: (params) => dispatch(saveCompanyAddress(params)),
    modifyCompanyAddress: (params) => dispatch(modifyCompanyAddress(params)),
    deleteCompanyAddress: (id) => dispatch(deleteCompanyAddress(id)),
    getAddressById: (addressId) => dispatch(getAddressById(addressId)),
    getAllUsers: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    getAllUsersByOffice: (officeId) => dispatch(getAllUsersByOffice(officeId)),
    addUsersToAddress: (params) => dispatch(addUsersToAddress(params)),
    getAllCompaniesByOfficeId: (officeId) =>
      dispatch(getAllCompaniesByOfficeId(officeId)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
class ConnectedOfficeAddresses extends Component {
  formRef = React.createRef();

  state = {
    country: "",
    zip: "",
    city: "",
    address: "",
    modify: false,
    selectedId: null,
    name: "",
    isPublic: false,
    isOutsideGate: false,
    isAbleToInvite: false,
    isParkingAvailable: false,
    userToAddressVisible: false,
    maxStartTolerance: 15,
    maxEndTolerance: 0,
    targetKeys: [],
    selectedKeys: [],
    companyName: "",
    baseTime: null,
    addressBaseTime: null,
    anonymizationTime: 1,
    officeAnonymizationTimeLimit: 1,
    companies: [],
    companyId: null,
    addCompany: false,
  };

  componentDidMount = async () => {
    let officeId = this.props.officeId
      ? this.props.officeId
      : this.props.user.officeId;
    await this.props.getAllOfficeAddresses(officeId);
    await this.props.getAllCompaniesByOfficeId(officeId);
    this.setState({});
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeCheckbox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  openUserToAddress = async (record) => {
    if (record.companyId) {
      await this.props.getAllUsers([5], record.companyId);
    } else {
      await this.props.getAllUsersByOffice(record.officeId);
    }
    await this.props.getAddressById(record.id);
    if (this.props.status) {
      const target = this.props.address.users.map((user) => user.id);
      this.setState({ targetKeys: target });
    }
    this.setState({ userToAddressVisible: true });
  };

  closeUserAddToAddress = () => {
    this.setState({
      userToAddressVisible: false,
      targetKeys: [],
      selectedKeys: [],
    });
  };

  handleSaveSelectedUsers = async () => {
    const params = {
      userIds: this.state.targetKeys,
      addressId: this.props.address.id,
    };
    await this.props.addUsersToAddress(params);
    this.closeUserAddToAddress();
  };

  submitForm = async () => {
    const toSave = {
      country: this.state.country,
      zip: this.state.zip,
      city: this.state.city,
      address: this.state.address,
      companyId:
        this.state.companyId && this.state.addCompany
          ? this.state.companyId
          : undefined,
      officeId: this.props.user.officeId,
      name: this.state.name,
      isPublic: this.state.isPublic,
      isOutsideGate: this.state.isOutsideGate,
      isAbleToInvite: this.state.isAbleToInvite,
      isParkingAvailable: this.state.isParkingAvailable,
      maxStartTolerance: this.state.maxStartTolerance
        ? parseInt(this.state.maxStartTolerance)
        : undefined,
      maxEndTolerance: parseInt(this.state.maxEndTolerance),
      anonymizationTime:
        this.state.anonymizationTime === null
          ? this.props.office[0].anonymizationTime
          : this.state.anonymizationTime,
    };
    if (this.state.modify === true) {
      toSave.id = this.state.selectedId;
      await this.props.modifyCompanyAddress(toSave);
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);
        this.handleCancelModify();
        if (this.props.officeId) {
          await this.props.getAllOfficeAddresses(this.props.companyId);
        } else if (this.props.user.officeId) {
          await this.props.getAllOfficeAddresses(this.props.user.officeId);
        }
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    } else {
      await this.props.saveCompanyAddress(toSave);
      if (this.props.saveStatus) {
        message.success(this.props.t("save-success"), 5);
        this.handleCancelModify();
        await this.props.getAllOfficeAddresses(
          this.props.officeId
            ? this.props.office[0].id
            : this.props.user.officeId
        );
      } else {
        message.error(this.props.t("save-fail"), 5);
      }
    }
  };

  handleDelete = async (id) => {
    await this.props.deleteCompanyAddress(id);
    if (this.props.saveStatus) {
      await this.props.getAllOfficeAddresses(
        this.props.user.officeId
          ? this.props.user.officeId
          : this.props.officeId
      );
    }
  };

  handleModify = (data) => {
    this.setState({
      modify: true,
      selectedId: data.id,
      country: data.country,
      zip: data.zip,
      city: data.city,
      address: data.address,
      name: data.name,
      isPublic: data.isPublic,
      isOutsideGate: data.isOutsideGate,
      isAbleToInvite: data.isAbleToInvite,
      isParkingAvailable: data.isParkingAvailable,
      maxStartTolerance: data.beforeStartToleranceMins,
      maxEndTolerance: data.beforeEndToleranceMins,
      baseTime: data.baseTime,
      addCompany: data.companyId ? true : false,
      companyId: data.companyId ? data.companyId : undefined,
    });
    this.formRef.current.setFieldsValue({
      country: data.country,
      zip: data.zip,
      city: data.city,
      address: data.address,
      name: data.name,
      isPublic: data.isPublic,
      isOutsideGate: data.isOutsideGate,
      isAbleToInvite: data.isAbleToInvite,
      isParkingAvailable: data.isParkingAvailable,
      maxStartTolerance: data.beforeStartToleranceMins,
      maxEndTolerance: data.beforeEndToleranceMins,
      baseTime: data.baseTime,
      addCompany: data.companyId ? true : false,
      companyId: data.companyId ? data.companyId : undefined,
    });
  };

  handleCancelModify = () => {
    this.setState({
      name: "",
      country: "",
      zip: "",
      city: "",
      address: "",
      selectedId: null,
      modify: false,
      isPublic: false,
      isOutsideGate: false,
      isAbleToInvite: false,
      isParkingAvailable: false,
      addCompany: false,
      companyId: null,
      maxStartTolerance: 15,
      maxEndTolerance: 0,
      addressBaseTime: null,
    });
    this.formRef.current.setFieldsValue({
      name: "",
      country: "",
      zip: "",
      city: "",
      address: "",
      isPublic: false,
      isOutsideGate: false,
      isAbleToInvite: false,
      isParkingAvailable: false,
      maxStartTolerance: 15,
      maxEndTolerance: 0,
      addressBaseTime: null,
    });
  };

  onAnonymizationTimeChange = (value) => {
    this.setState({
      anonymizationTime: value,
    });
  };

  onSelectedCompanyChange = async (event) => {
    this.setState({
      companyId: event,
    });
  };

  handleChangeTarget = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  onOk = (value) => {
    this.setState({
      baseTime: value,
    });
  };

  render() {
    const columns = [
      {
        title: this.props.t("companyadmin-address-name-label"),
        key: "name",
        dataIndex: "name",
      },
      {
        title: this.props.t("companyadmin-address-table-header-country"),
        key: "country",
        dataIndex: "country",
      },
      {
        title: this.props.t("companyadmin-address-table-header-zip"),
        key: "zip",
        dataIndex: "zip",
      },
      {
        title: this.props.t("companyadmin-address-table-header-city"),
        key: "city",
        dataIndex: "city",
      },
      {
        title: this.props.t("companyadmin-address-table-header-address"),
        key: "address",
        dataIndex: "address",
      },
      {
        title: <UserAddOutlined />,
        key: "addUser",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.openUserToAddress(data)}
                disabled={data.isPublic}
              >
                <UserAddOutlined />
              </Button>
            </Space>
          );
        },
      },

      {
        title: <EditOutlined />,
        key: "modify",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button onClick={() => this.handleModify(data)}>
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: <DeleteOutlined />,
        key: "delete",
        render: (text, data) => {
          return (
            <Space size="middle">
              <Button
                danger
                onClick={() => this.handleDelete(data.id)}
                disabled={this.props.addresses.length <= 1}
                type="primary"
              >
                <DeleteOutlined />
              </Button>
            </Space>
          );
        },
      },
    ];
    return (
      <div style={{ overflowY: "auto" }}>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="company"
              onFinish={this.submitForm}
              scrollToFirstError
              layout="vertical"
              wrapperCol={{ style: { padding: "0", margin: "0 0 0 0" } }}
            >
              <Row>
                <Col span={12} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-name-label")}:
                      </strong>
                    }
                    name="name"
                    initialValue={this.state.name}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("companyadmin-address-name-text"),
                      },
                    ]}
                  >
                    <Input
                      name="name"
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-country-label")}
                      </strong>
                    }
                    name="country"
                    initialValue={this.state.country}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-country-text"
                        ),
                      },
                    ]}
                  >
                    <Input
                      name="country"
                      onChange={this.handleChange}
                      value={this.state.country}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-zip-label")}
                      </strong>
                    }
                    name="zip"
                    initialValue={this.state.zip}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("companyadmin-address-zip-text"),
                      },
                    ]}
                  >
                    <Input
                      name="zip"
                      onChange={this.handleChange}
                      value={this.state.zip}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-city-label")}
                      </strong>
                    }
                    name="city"
                    initialValue={this.state.city}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("companyadmin-address-city-text"),
                      },
                    ]}
                  >
                    <Input
                      name="city"
                      onChange={this.handleChange}
                      value={this.state.city}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-address-address-label")}
                      </strong>
                    }
                    name="address"
                    initialValue={this.state.address}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-address-text"
                        ),
                      },
                    ]}
                  >
                    <Input
                      name="address"
                      onChange={this.handleChange}
                      value={this.state.address}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t(
                          "companyadmin-address-starttolerance-label"
                        )}
                      </strong>
                    }
                    name="maxStartTolerance"
                    initialValue={this.state.maxStartTolerance}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-starttolerance-text"
                        ),
                      },
                    ]}
                  >
                    <Input
                      name="maxStartTolerance"
                      onChange={this.handleChange}
                      value={this.state.maxStartTolerance}
                      type="number"
                      step={1}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t(
                          "companyadmin-address-endtolerance-label"
                        )}
                      </strong>
                    }
                    name="maxEndTolerance"
                    initialValue={this.state.maxEndTolerance}
                    rules={[
                      {
                        required: true,
                        message: this.props.t(
                          "companyadmin-address-endtolerance-text"
                        ),
                      },
                    ]}
                  >
                    <Input
                      name="maxEndTolerance"
                      onChange={this.handleChange}
                      value={this.state.maxEndTolerance}
                      type="number"
                      step={1}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={
                      <strong>
                        {this.props.t("companyadmin-anonymizationTime")}
                      </strong>
                    }
                    name="anonymizationTime"
                    initialValue={this.state.anonymizationTime}
                    rules={[
                      {
                        required: true,
                        message: this.props.t("companyadmin-anonymizationTime"),
                      },
                    ]}
                  >
                    <InputNumber
                      min={this.state.officeAnonymizationTimeLimit}
                      value={this.state.anonymizationTime}
                      onChange={this.onAnonymizationTimeChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item name="isPublic" initialValue={this.state.isPublic}>
                    <Checkbox
                      id="addCompany"
                      name="addCompany"
                      onChange={this.handleChangeCheckbox}
                      checked={this.state.addCompany}
                    />
                    <label
                      htmlFor="addCompany"
                      style={{ marginLeft: "1em", color: "#262626" }}
                    >
                      <strong>{this.props.t("addresses-add-company")}</strong>
                    </label>
                  </Form.Item>
                </Col>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item
                    label={<strong>{this.props.t("companies-title")}</strong>}
                    name="companyId"
                  >
                    <Select
                      style={{ width: "15em" }}
                      onChange={(event) => this.onSelectedCompanyChange(event)}
                      disabled={!this.state.addCompany}
                    >
                      {this.props.companies.map((company) => {
                        return (
                          <Select.Option key={company.id} value={company.id}>
                            {company.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item name="isPublic" initialValue={this.state.isPublic}>
                    <Checkbox
                      id="isPublic"
                      name="isPublic"
                      onChange={this.handleChangeCheckbox}
                      checked={this.state.isPublic}
                    />
                    <label
                      htmlFor="isPublic"
                      style={{ marginLeft: "1em", color: "#262626" }}
                    >
                      <strong>
                        {this.props.t("companyadmin-address-public-label")}
                      </strong>
                    </label>
                  </Form.Item>
                </Col>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item
                    name="isAbleToInvite"
                    initialValue={this.state.isAbleToInvite}
                  >
                    <Checkbox
                      id="isAbleToInvite"
                      name="isAbleToInvite"
                      onChange={this.handleChangeCheckbox}
                      checked={this.state.isAbleToInvite}
                    />
                    <label
                      htmlFor="isAbleToInvite"
                      style={{ marginLeft: "1em", color: "#262626" }}
                    >
                      <strong>
                        {this.props.t(
                          "companyadmin-address-abletoinvite-label"
                        )}
                      </strong>
                    </label>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item
                    name="isOutsideGate"
                    initialValue={this.state.isOutsideGate}
                  >
                    <Checkbox
                      id="isOutsideGate"
                      name="isOutsideGate"
                      onChange={this.handleChangeCheckbox}
                      checked={this.state.isOutsideGate}
                    />
                    <label
                      style={{ marginLeft: "1em", color: "#262626" }}
                      htmlFor="isOutsideGate"
                    >
                      <strong>
                        {this.props.t("companyadmin-address-outsidegate-label")}
                      </strong>
                    </label>
                  </Form.Item>
                </Col>
                <Col span={6} style={{ padding: "0.5em" }}>
                  <Form.Item
                    name="isParkingAvailable"
                    initialValue={this.state.isParkingAvailable}
                  >
                    <Checkbox
                      id="isParkingAvailable"
                      name="isParkingAvailable"
                      onChange={this.handleChangeCheckbox}
                      checked={this.state.isParkingAvailable}
                    />
                    <label
                      style={{ marginLeft: "1em", color: "#262626" }}
                      htmlFor="isParkingAvailable"
                    >
                      <strong>
                        {this.props.t(
                          "companyadmin-address-parkingavailable-label"
                        )}
                      </strong>
                    </label>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "right" }}>
                {this.state.modify && (
                  <Button
                    style={{ marginRight: "10px" }}
                    onClick={this.handleCancelModify}
                  >
                    {this.props.t("cancel-button-label")}
                  </Button>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={this.props.company[0]?.officeId}
                >
                  <SaveOutlined /> {this.props.t("save-button-label")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <Table
              columns={columns}
              dataSource={this.props.addresses}
              rowKey="id"
              locale={{ emptyText: "Nincs adat" }}
              pagination={
                this.props.addresses.length > 5
                  ? {
                      position: ["bottomCenter"],
                      pageSize: 5,
                      showSizeChanger: false,
                    }
                  : false
              }
              scroll={{ x: 500 }}
            />
          </Col>
        </Row>
        <Modal
          maskClosable={false}
          closable={true}
          footer={[
            <Button onClick={this.closeUserAddToAddress}>
              {this.props.t("cancel-button-label")}
            </Button>,
            <Button onClick={this.handleSaveSelectedUsers} type="primary">
              <SaveOutlined /> {this.props.t("save-button-label")}
            </Button>,
          ]}
          visible={this.state.userToAddressVisible}
          centered
          bodyStyle={{
            textAlign: "center",
            margin: "0 auto",
          }}
          width={1100}
          title={
            <h3 style={{ padding: 0, margin: 0 }}>
              <strong>{this.props.t("add-user-to-address-title")}</strong>
            </h3>
          }
          onCancel={this.closeUserAddToAddress}
          destroyOnClose
        >
          <div>
            <Transfer
              style={{ textAlign: "left" }}
              dataSource={this.props.users}
              render={(item) =>
                `${item.lastName} ${item.firstName} (${item.email}) - ${item.company.name}`
              }
              targetKeys={this.state.targetKeys}
              selectedKeys={this.state.selectedKeys}
              showSearch
              onChange={this.handleChangeTarget}
              onSelectChange={this.handleSelectChange}
              rowKey={(record) => record.id}
              selectAllLabels={[<div></div>, <div></div>]}
              locale={{
                notFoundContent: this.props.t("empty-text"),
                selectAll: this.props.t("select-all-label"),
                selectInvert: this.props.t("select-invert-label"),
                searchPlaceholder: this.props.t("search-button-label"),
              }}
              listStyle={{
                width: 500,
                height: 300,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
  addresses: state.companyReducer.addresses,
  address: state.companyReducer.address,
  saveStatus: state.companyReducer.saveStatus,
  users: state.usersReducer.users,
  officeStatus: state.officeReducer.status,
  office: state.officeReducer.office,
  companies: state.companyReducer.companies,
});
const OfficeAddresses = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedOfficeAddresses)
);
export default OfficeAddresses;
