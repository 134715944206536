import axios from "axios";
import { PARKING_ACTIONS, USERS_ACTIONS } from "../constants/action-types";

export const getParkings = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_LOADING });
    await axios
      .get("/2.0.0/parking", {
        //headers: { Authorization: token },
        params: { parkingGroupId: params.parkingGroupId },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getParkingStatuses = (companyId, officeId) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_LOADING });
    await axios
      .get("/2.0.0/parking/parking-status", {
        //headers: { Authorization: token },
        params: { companyId: companyId, officeId: officeId },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createVehicleFromMessage = (code, licensePlateNumber) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_LOADING });
    await axios
      .get("/2.0.0/vehicle/create-vehicle-from-message", {
        //headers: { Authorization: token },
        params: { code: code, licensePlateNumber: licensePlateNumber },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const changeParkingPlace = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_SAVE_START });
    await axios
      .put("/2.0.0/parking/change-parking-place", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getReservationForParking = (id) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_RESERVATION_LOADING });
    await axios
      .get(`/2.0.0/parking/${id}/all-reservations`, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_RESERVATION_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_RESERVATION_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getReservationForGuest = (inviteId) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_RESERVATION_LOADING });
    await axios
      .get(
        `/2.0.0/parking/parking-reservations/invite/${inviteId}/guests`,
        {
          //headers: { Authorization: token },
        },
        console.log(inviteId)
      )
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_RESERVATION_LOADED,
          payload: response.data,
        });
        console.log(response.data);
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_RESERVATION_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createReservationForGuest = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_SAVE_START });
    await axios
      .post("/2.0.0/parking/reserve-parking/guests", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const reserveParking = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_RESERVATION_SAVE_START });
    await axios
      .post("/2.0.0/parking/reserve-parking/guests/finalize-parking", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_RESERVATION_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_RESERVATION_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const cancelReservation = (id, from) => {
  return async () => {
    await axios
      .delete("/2.0.0/parking/parking-reservations/cancel-with-params/" + id, {
        params: { from: from },
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const cancelParkingReservationByCode = (code) => {
  return async () => {
    await axios
      .delete("/2.0.0/parking/parking-reservations/cancel-with-code/" + code)
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const getFreePlacesForGuest = (date, companyId) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_PLACES_LOADING });
    await axios
      .get("/2.0.0/parking/parking-reservations/free-places-count/guests", {
        params: { date: date, companyId: companyId },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_PLACES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_PLACES_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getParking = (id) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_LOADING });
    await axios
      .get("/2.0.0/parking", id, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createParking = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_SAVE_START });
    await axios
      .post("/2.0.0/parking", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const updateParking = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_SAVE_START });
    await axios
      .put("/2.0.0/parking/" + id, params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteParking = (id) => {
  return async () => {
    await axios.delete("/2.0.0/parking/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

export const deleteReservation = (id) => {
  return async () => {
    await axios
      .delete("/2.0.0/parking/parking-reservations/" + id)
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const getParkingGroups = (companyId, officeId) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_GROUP_LOADING });
    await axios
      .get("/2.0.0/parking-group", {
        //headers: { Authorization: token },
        params: { companyId: companyId, officeId: officeId },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getParkingGroup = (id) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_GROUP_LOADING });
    await axios
      .get("/2.0.0/parking-group/" + id, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createParkingGroup = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_GROUP_SAVE_START });
    await axios
      .post("/2.0.0/parking-group", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const updateParkingGroup = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_GROUP_SAVE_START });
    await axios
      .put("/2.0.0/parking-group/" + id, params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_GROUP_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteParkingGroup = (id) => {
  return async () => {
    await axios.delete("/2.0.0/parking-group/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

export const getVehicles = (userId, guestId) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_VEHICLE_LOADING });
    await axios
      .get(
        "/2.0.0/vehicle",
        { params: { userId: userId, guestId: guestId } },
        {
          //headers: { Authorization: token },
        }
      )
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getUsersWithVehicles = (companyId, officeId) => {
  return async (dispatch) => {
    dispatch({ type: USERS_ACTIONS.USERS_VEHICLE_LOADING });
    await axios
      .get(
        "/2.0.0/users/users-with-vehicle",
        { params: { companyId: companyId, officeId: officeId } },
        {
          //headers: { Authorization: token },
        }
      )
      .then((response) => {
        dispatch({
          type: USERS_ACTIONS.USERS_VEHICLE_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: USERS_ACTIONS.USERS_VEHICLE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getVehicle = (id) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_VEHICLE_LOADING });
    await axios
      .get("2.0.0/vehicle/" + id, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createVehicleForGuest = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_START });
    await axios
      .post("/2.0.0/vehicle/for-guest/", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createVehicleWithGuest = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_START });
    await axios
      .post("/2.0.0/vehicle/guest/", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const updateVehicle = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_START });
    await axios
      .put("/2.0.0/vehicle/" + id, params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_VEHICLE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteVehicle = (id) => {
  return async () => {
    await axios.delete("2.0.0/vehicle/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

export const getEntryGates = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_ENTRY_GATE_LOADING });
    await axios
      .get("/2.0.0/entry-gate", {
        params: { officeId },
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const getEntryGate = (id) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_ENTRY_GATE_LOADING });
    await axios
      .get("/2.0.0/entry-gate/" + id, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const createEntryGate = (params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_START });
    await axios
      .post("/2.0.0/entry-gate", params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const updateEntryGate = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_START });
    await axios
      .put("/2.0.0/entry-gate/" + id, params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const deleteEntryGate = (id) => {
  return async () => {
    await axios.delete("2.0.0/entry-gate/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

export const addUserToParkingGroup = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.USER_SAVE_START });
    await axios
      .put(`/2.0.0/parking-group/${id}/update-users`, params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.USER_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.USER_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};

export const addParkingGroupToEntryGate = (id, params) => {
  return async (dispatch) => {
    dispatch({ type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_START });
    await axios
      .put(`/2.0.0/entry-gate/${id}/update-parking-groups`, params, {
        //headers: { Authorization: token },
      })
      .then((response) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PARKING_ACTIONS.PARKING_ENTRY_GATE_SAVE_ERROR,
          payload: err.response.data,
        });
        console.log(err.message);
      });
  };
};
