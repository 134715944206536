import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Form,
  Input,
  message,
  Select,
  Popconfirm,
  Switch,
} from "antd";
import {
  getAllCompanies,
  saveCompany,
  getAllSubscriptions,
  deleteCompany,
  modifyCompany,
  getAllAddresses,
  saveCompanyAddress,
} from "../../actions/company";
import { SaveFilled, PlusOutlined } from "@ant-design/icons";
import { getAllLanguages } from "../../actions/language";
import Modal from "antd/lib/modal/Modal";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { getAllOffices } from "../../actions/office";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompanies: () => dispatch(getAllCompanies()),
    getAllLanguages: () => dispatch(getAllLanguages()),
    getAllSubscriptions: () => dispatch(getAllSubscriptions()),
    saveCompany: (params) => dispatch(saveCompany(params)),
    deleteCompany: (companyId) => dispatch(deleteCompany(companyId)),
    modifyCompany: (companyId, params) =>
      dispatch(modifyCompany(companyId, params)),
    getAllOffices: () => dispatch(getAllOffices()),
    getAllAddresses: (companyId, officeId) =>
      dispatch(getAllAddresses(companyId, officeId)),
    saveCompanyAddress: (params) => dispatch(saveCompanyAddress(params)),
  };
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 11,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedCompanies extends Component {
  formRef = React.createRef();

  state = {
    newModalVisible: false,
    modalMode: "create",
    name: "",
    email: "",
    defaultLanguage: "",
    subscription: null,
    companyId: null,
    officeId: null,
    file: [],
  };

  componentDidMount = async () => {
    await this.props.getAllOffices();
    await this.props.getAllCompanies();
    await this.props.getAllLanguages();
    await this.props.getAllSubscriptions();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    this.setState({ file: [file] });
    return false;
  };

  submitForm = async () => {
    if (this.state.modalMode === "modify") {
      await this.modifyCompany();
    } else if (this.state.modalMode === "create") {
      const saveObj = {
        name: this.state.name,
        lang: this.state.defaultLanguage,
        email: this.state.email,
        subscription: parseInt(this.state.subscription),
        officeId:
          this.props.user.officeId !== null &&
          this.props.user.officeId !== undefined
            ? this.props.user.officeId
            : this.state.officeId,
      };
      await this.props.saveCompany(saveObj);
      if (this.props.saveStatus) {
        this.setState({
          name: "",
          email: "",
          defaultLanguage: "",
          officeId: null,
        });
        this.formRef.current.resetFields();
        message.success(this.props.t("save-success"), 5);
        this.closeNewModal();
        this.setState({ newModalVisible: false });
        this.forceUpdate();
        await this.props.getAllCompanies();
      } else {
        if (this.props.message.code === 1003) {
          message.error(
            this.props.t("save-fail-because-email-already-exists"),
            5
          );
        } else {
          message.error(this.props.t("save-fail"), 5);
        }
      }
    }
  };

  openNewModal = () => {
    this.setState({ modalMode: "create", newModalVisible: true });
  };

  closeNewModal = () => {
    this.setState({
      newModalVisible: false,
      name: "",
      email: "",
      defaultLanguage: null,
      subscription: null,
      officeId: null,
    });
    this.formRef.current.resetFields();
  };

  openEditModal = (id) => {
    const company = this.props.companies.find((o) => o.id === id);
    if (company === undefined) {
      return;
    }

    const language = this.props.languages.find(
      (l) => l.id === company.defaultLanguageId
    );
    if (language === undefined) {
      return;
    }

    this.setState({
      modalMode: "modify",
      newModalVisible: true,
      companyId: id,
      name: company.name,
      defaultLanguage: language.code,
      subscription: company.subscriptionId,
      officeId: company.officeId,
    });
    this.formRef.current.setFieldsValue({
      name: company.name,
      lang: language.code,
      subscription: company.subscriptionId,
      office: company.officeId,
    });
  };

  modifyCompany = async () => {
    const toSave = {
      name: this.state.name,
      langCode: this.state.defaultLanguage,
      subscriptionId: parseInt(this.state.subscription),
      officeId: this.state.officeId,
    };
    if (this.state.officeId !== null) {
      await this.props.getAllAddresses(null, this.state.officeId);
      if (this.props.addresses !== undefined) {
        const toSave = {
          officeId: this.state.officeId,
          country: this.props.addresses[0].country,
          zip: this.props.addresses[0].zip,
          city: this.props.addresses[0].city,
          address: this.props.addresses[0].address,
          companyId: this.props.companyId
            ? this.props.companyId
            : this.props.user.companyId,
          name: this.props.addresses[0].name,
          isPublic: this.props.addresses[0].isPublic,
          isOutsideGate: this.props.addresses[0].isOutsideGate,
          isAbleToInvite: this.props.addresses[0].isAbleToInvite,
          isParkingAvailable: this.props.addresses[0].isParkingAvailable,
          maxStartTolerance: this.props.addresses[0].maxStartTolerance,
          maxEndTolerance: this.props.addresses[0].maxEndTolerance,
          anonymizationTime: this.props.addresses[0].anonymizationTime,
          worklogModuleOn: this.props.addresses[0].worklogModuleOn,
        };
        await this.props.saveCompanyAddress(toSave);
      }
    }
    console.log(toSave);
    await this.props.modifyCompany(this.state.companyId, toSave);

    if (this.props.saveStatus) {
      message.success(this.props.t("save-success"), 5);
      this.setState({ newModalVisible: false });
      await this.props.getAllCompanies();
    } else {
      message.error(this.props.t("save-fail"), 5);
      await this.props.getAllCompanies();
    }
  };

  handleDelete = async (record) => {
    await this.props.deleteCompany(record.id);
    if (this.props.saveStatus) {
      await this.props.getAllCompanies();
    }
  };

  changeLanguage = (event) => {
    this.setState({ defaultLanguage: event });
  };
  changeSubscription = (event) => {
    this.setState({ subscription: event });
  };

  handleChangeSmartlock = async (companyId) => {
    await axios
      .get("/2.0.0/company/smartlock", {
        params: {
          companyId: companyId,
        },
      })
      .then(async () => {
        await this.props.getAllCompanies();
      })
      .catch(() => {
        message.error("Hiba!");
      });
  };

  handleChangeOffice = (event) => {
    this.setState({ officeId: event });
  };

  render() {
    const columns = [
      // {
      //   title: this.props.t("companies-label-id"),
      //   dataIndex: "id",
      //   key: "id",
      //   width: 50,
      // },
      {
        title: this.props.t("companies-label-companyname"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this.props.t("companies-table-header-date"),
        key: "date",
        render: (text, record) => (
          <Space size="middle">
            {new Date(parseInt(record.created)).toLocaleDateString()}
          </Space>
        ),
      },
      {
        title: this.props.t("companies-table-header-logo"),
        key: "logo",
        render: (text, record) => (
          <Space size="middle">
            {record.logoName !== null ? (
              <img
                alt="logo"
                src={`${
                  process.env.REACT_APP_BASE_URL
                }/api/1.0.0/company/logo/${record.logoName}?token=${
                  this.props.token.split(" ")[1]
                }`}
                width={50}
              />
            ) : (
              this.props.t("companies-table-no-logo")
            )}
          </Space>
        ),
      },
      {
        title: this.props.t("companies-table-header-smartlock"),
        key: "smartLock",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Switch
                checked={record.smartLock}
                onChange={() => this.handleChangeSmartlock(record.id)}
              />
            </Space>
          );
        },
      },
      {
        title: this.props.t("companies-table-header-modify"),
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.openEditModal(record.id)}>
              {this.props.t("companies-table-header-modify")}
            </Button>
          </Space>
        ),
      },
      {
        title: this.props.t("companies-table-header-delete"),
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              onConfirm={() => this.handleDelete(record)}
              title={this.props.t("companies-table-delete-confirm")}
              cancelText={this.props.t("no-button-label")}
              okText={this.props.t("yes-button-label")}
            >
              <Button type="primary" danger>
                {this.props.t("companies-table-header-delete")}
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={12} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("companies-title")}</strong>
            </h1>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col style={{ padding: "1em" }}>
            <Button type="primary" onClick={this.openNewModal}>
              <PlusOutlined />
              {this.props.t("companies-newcompany-title")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            columns={columns}
            rowKey="id"
            dataSource={this.props.companies}
            locale={{ emptyText: this.props.t("empty-text") }}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
              showSizeChanger: false,
            }}
          />
        </Row>
        <Modal
          visible={this.state.newModalVisible}
          centered
          title={
            <strong>
              {this.props.t(
                this.state.modalMode === "create"
                  ? "companies-newcompany-title"
                  : "companies-modifycompany-title"
              )}
            </strong>
          }
          footer={null}
          width={"37em"}
          onCancel={this.closeNewModal}
          forceRender
        >
          <Form
            style={{
              position: "relative",
              right: "1em",
              margin: "auto",
            }}
            ref={this.formRef}
            {...formItemLayout}
            name="company"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label={this.props.t("companies-label-companyname")}
              rules={[
                {
                  required: true,
                  message: this.props.t("companies-companyname-text"),
                  whitespace: true,
                },
              ]}
            >
              <Input
                style={{ width: "18em" }}
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Item>
            {this.state.modalMode === "create" && (
              <Form.Item
                name="email"
                label={this.props.t("companies-companyadmin-email-label")}
                rules={[
                  {
                    required: true,
                    message: this.props.t("email-empty"),
                    whitespace: false,
                  },
                  {
                    type: "email",
                    message: this.props.t("email-wrong-format"),
                  },
                ]}
              >
                <Input
                  style={{ width: "18em" }}
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
            )}
            <Form.Item
              name="lang"
              label={this.props.t("companies-companyadmin-language-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("companies-companyadmin-language-text"),
                },
              ]}
            >
              <Select
                style={{ width: "18em" }}
                onChange={this.changeLanguage}
                value={this.state.defaultLanguage}
              >
                {this.props.languages.map((language) => {
                  return (
                    <Select.Option
                      key={language.code}
                      value={language.code}
                    >{`${language.name} - ${language.code}`}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="subscription"
              label={this.props.t("companies-companyadmin-subscribe-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t(
                    "companies-companyadmin-subscribe-text"
                  ),
                },
              ]}
            >
              <Select
                style={{ width: "18em" }}
                onChange={this.changeSubscription}
                value={this.state.subscription}
              >
                {this.props.subscriptions.map((subscription) => {
                  return (
                    <Option key={subscription.id} value={subscription.id}>
                      {subscription.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {(this.props.user.officeId === null ||
              this.props.user.officeId === undefined) && (
              <Form.Item name="office" label={this.props.t("office")}>
                <Select
                  style={{ width: "18em" }}
                  name="office"
                  value={this.state.officeId}
                  onChange={this.handleChangeOffice}
                >
                  {this.props.offices.map((office) => {
                    return (
                      <Option key={office.id} value={office.id}>
                        {office.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{ margin: "0.5em", position: "relative", left: "16em" }}
              >
                <SaveFilled />
                {this.props.t("save-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  companies: state.companyReducer.companies,
  subscriptions: state.companyReducer.subscriptions,
  saveStatus: state.companyReducer.saveStatus,
  languages: state.languageReducer.languages,
  offices: state.officeReducer.offices,
  message: state.companyReducer.message,
  addresses: state.companyReducer.addresses,
  companyId: state.companyReducer.companyId,
});

const Companies = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanies)
);
export default Companies;
