import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, message, Select, Space, Table } from "antd";
import {
  addGroupToUser,
  getAllUsers,
  removeGroupFromUser,
} from "../../actions/users";
import { withTranslation } from "react-i18next";
import { getOneCompanyById } from "../../actions/company";
import HttpService from "../../services/http";
import { ReactComponent as NoPicture } from "../../static/Artboard.svg";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const mapDispatchToProps = (dispatch) => {
  return {
    addGroupToUser: (userId, groupId, callback) =>
      dispatch(addGroupToUser(userId, groupId, callback)),
    removeGroupFromUser: (userId, groupId, callback) =>
      dispatch(removeGroupFromUser(userId, groupId, callback)),
    getAllUser: (groupIds, companyId) =>
      dispatch(getAllUsers(groupIds, companyId)),
    getOneCompanyById: (companyId) => dispatch(getOneCompanyById(companyId)),
  };
};
class ConnectedCompanyHrColleagues extends Component {
  state = {
    hr: [],
    selectedUser: null,
    users: [],
  };

  componentDidMount = async () => {
    HttpService.setAxiosDefaults(this.props.token);
    if (this.props.companyId) {
      await this.props.getOneCompanyById(this.props.companyId);
      await this.props.getAllUser([4], this.props.companyId);
      if (this.props.usersStatus) {
        this.setState({ hr: this.props.users });
      }
      await this.props.getAllUser([2, 3, 4, 5], this.props.companyId);
      if (this.props.usersStatus) {
        this.setState({ users: this.props.users });
      }
    } else {
      await this.props.getOneCompanyById(this.props.user.companyId);
      await this.props.getAllUser([4], this.props.user.companyId);
      if (this.props.usersStatus) {
        this.setState({ hr: this.props.users });
      }
      await this.props.getAllUser([2, 3, 4, 5], this.props.user.companyId);
      if (this.props.usersStatus) {
        this.setState({ users: this.props.users });
      }
    }
  };

  handleChangeHr = async (event) => {
    let hr = this.state.hr;
    let selectedUser = this.state.users.find(
      (user) => user.id === this.state.selectedUser
    );
    hr = [...hr, selectedUser];
    const changeState = () => {
      this.setState({ hr: hr });
    };
    if (
      (this.props.company[0].subscription.maxHRadmin === null
        ? Number.MAX_SAFE_INTEGER
        : this.props.company[0].subscription.maxHRadmin) >=
      this.state.hr.length + 1
    ) {
      await this.props.addGroupToUser(this.state.selectedUser, 4, changeState);
    } else {
      message.error(this.props.t("companyadmin-hr-max-error"), 5);
    }
  };

  handleDeselectHr = async (event) => {
    const hr = this.state.hr.filter((user) => user.id !== event);
    const changeState = () => {
      this.setState({ hr: hr });
    };
    await this.props.removeGroupFromUser(event, 4, changeState);
  };

  handleChangeUser = (event) => {
    this.setState({ selectedUser: event });
  };

  render() {
    const columns = [
      {
        title: "Picture",
        key: "picture",
        width: "55px",
        onCell: () => {
          return {
            style: { textAlign: "center", verticalAlign: "middle" },
          };
        },
        render: (text, record) => {
          return (
            <div>
              {record.filename !== null ? (
                <img
                  src={`${
                    process.env.REACT_APP_BASE_URL
                  }/api/1.0.0/users/profile/${record.filename}?token=${
                    this.props.token.split(" ")[1]
                  }`}
                  alt="profilepic"
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                  }}
                />
              ) : (
                <NoPicture
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                    backgroundColor: "#CECECE",
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        title: "Adatok",
        key: "data",
        render: (text, record) => {
          return (
            <div>
              <div>
                <strong>{`${
                  record.lastName
                    ? record.lastName.charAt(0).toUpperCase() +
                      record.lastName.slice(1)
                    : ""
                } ${
                  record.firstName
                    ? record.firstName.charAt(0).toUpperCase() +
                      record.firstName.slice(1)
                    : ""
                }`}</strong>
              </div>
              <div>
                {record.position
                  ? record.position.charAt(0).toUpperCase() +
                    record.position.slice(1)
                  : ""}
              </div>
            </div>
          );
        },
      },

      {
        title: "Elérhetőségek",
        key: "contact",
        render: (text, record) => {
          return (
            <div>
              <div>{record.phoneNumber}</div>
              <div>{record.email}</div>
            </div>
          );
        },
      },
      {
        title: "Visszavonás",
        key: "revoke",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button danger onClick={() => this.handleDeselectHr(record.id)}>
                {this.props.t("revoke-button-label")}
              </Button>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <div>
          <Table
            showHeader={false}
            columns={columns}
            dataSource={this.state.hr}
            locale={{ emptyText: this.props.t("empty-text") }}
            rowKey="id"
            pagination={
              this.state.hr.length > 10
                ? {
                    position: ["bottomCenter"],
                    pageSize: 5,
                    showSizeChanger: false,
                  }
                : false
            }
            scroll={{ x: 800 }}
          />
        </div>
        <div style={{ marginTop: 24, display: "flex" }}>
          <div style={{ marginRight: 24 }}>
            <Select
              placeholder={this.props.t("companyadmin-hr-select-user")}
              style={{ width: "250px" }}
              value={this.state.selectedUser}
              onChange={this.handleChangeUser}
              disabled={
                (this.props.company[0]?.subscription.maxHRadmin === null
                  ? Number.MAX_SAFE_INTEGER
                  : this.props.company[0]?.subscription.maxHRadmin) ===
                this.state.hr.length
              }
            >
              {this.state.users
                .filter((user) => {
                  return !this.state.hr.some(
                    (hradmin) => hradmin.id === user.id
                  );
                })
                .map((user) => {
                  return (
                    <Option
                      key={user.id}
                      value={user.id}
                    >{`${user.lastName} ${user.firstName}`}</Option>
                  );
                })}
            </Select>
          </div>
          <div>
            <Button
              type="primary"
              onClick={this.handleChangeHr}
              disabled={
                (this.props.company[0]?.subscription.maxHRadmin === null
                  ? Number.MAX_SAFE_INTEGER
                  : this.props.company[0]?.subscription.maxHRadmin) ===
                this.state.hr.length
              }
            >
              <PlusOutlined />
              {this.props.t("add-button-label")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  usersStatus: state.usersReducer.status,
  company: state.companyReducer.company,
  status: state.companyReducer.status,
});
const CompanyHrColleagues = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanyHrColleagues)
);
export default CompanyHrColleagues;
